@import 'assets/scss/mixins.scss';

.container {
	display: flex;
	flex-direction: row;
	flex: 1;
	margin-bottom: 20px;

	.filterSection {
		position: relative;
		min-height: 70px;
		width: 100%;
	}

	.listItemsContainer {
		display: flex;
		flex-direction: row;
		flex: 3;
	}

	.flexColumn {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	.flexRow {
		display: flex;
		flex-direction: row;

	}

	.flexRowMobile {
		display: flex;
		flex-direction: row;

		@include sm {
			display: flex;
			flex-direction: column;
			padding: 10px;
		}

	}

	.whiteAndRoundedBackground {
		background: #fff;
		padding-left: 10px;
		border-radius: 8px;
		margin-bottom: 20px;
	}
}

.btnFullWidthMobile {
	@include sm {
		width: 100%;
		margin-right: 0px !important;

		button {
			width: 100%;
			justify-content: center;
		}
	}
}

.btnFullWidth {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	// margin-bottom: 26px;
	align-self: center;
	margin-right: 16px;

	button {
		position: relative;
		background: var(--primaryColor);
		border-radius: 15px;
		height: 30px;
		line-height: 30px;
		display: flex;
		align-items: center;
		color: #fff;
		padding: 0 15px;
		font-size: 14px;
		font-weight: 700;

		&:hover {
			background: transparent;
			color: #fff;
			background-color: rgba($color: var(--primaryColorRgb), $alpha: 0.7);
			border-color: var(--primaryColor);
		}

		&:focus {
			background: transparent !important;
			border-color: var(--primaryColor) !important;
			color: var(--primaryColor) !important;
		}
	}
}