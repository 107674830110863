.container {
	display: flex;
	flex-direction: row;
	flex: 1;
	justify-content: space-between;
	position: relative;
	.cardContainer {
		max-width: 400ppx;
	}
	.absoluteBtn {
		position: absolute;
		right: 0px;
		top: -8px;
	}
	.mainBtn {
		background: var(--primaryColor);
		margin-right: 4px;
		border: 1 px solid;
		border-radius: 20px;
		font-weight: bold;
		cursor: pointer;
		color: #ffffff;
		border-color: var(--primaryColor);
		&:hover {
			background-color: rgba($color: var(--primaryColorRgb), $alpha: 0.8);
			color: #ffffff;
			border-color: transparent;
		}
		&:global(.ant-btn) {
			&:focus {
				background-color: var(--primaryColor) !important;
				color: #ffffff !important;
				border-color: var(--primaryColor) !important;
			}
		}
	}
	.secondaryBtn {
		background: #ffffff;
		color: var(--primaryColor);
		&:hover {
			background-color: rgba($color: #ffffff, $alpha: 0.7);
			color: var(--primaryColor);
			border-color: var(--primaryColor);
		}
	}
	.errorText {
		font-size: 12px;
		color: red;
	}
}
.stripeElement {
	width: 400px;
	padding: 11px 15px 11px 0;
	.InputElement.is-empty.Input.Input--empty::placeholder {
		color: red; // #009bb9;
	}
}
