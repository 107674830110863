.screenNameBar {
	padding: 30px 0 20px 0;
	display: flex;
	align-items: center;

	img {
		margin: -4px 13px 0 0;
	}
	h1 {
		font-size: 32px;
		font-weight: 900;
		margin: 0;
		color: var(--announcementHeaderBackground);
	}

	:global {
		.ant-btn {
			margin-left: auto;
			border-color: var(--primaryColor) !important;
			color: var(--primaryColor) !important;
		}
	}
	.backButtom {
		margin-left: 15px;
		align-self: center;
		display: flex;
		flex-direction: row;
		flex: 1;
		button {
			background: transparent;
			border-color: var(--primaryColor);
			border-radius: 10px;
			font-weight: bold;
			color: var(--primaryColor);
		}
	}
}
