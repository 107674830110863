.tag {

  :global(.ant-tag) {
    border-radius: 24px;
    margin-left: 8px;
    border: none;
    padding: 2px 10px;
    background: #F4F5F7;
    font-family: "Nunito-Regular";
    font-weight: 400;
    font-size: 12px;
    color: #9CA1BA;
  }

  :global(.ant-tag-processing) {
    color: #0091B6 !important;
    background: #E6F4F8;
  }
}