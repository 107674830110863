.fileDownloadWrapper {
	padding: 30px;
	border-bottom: 1px solid #ececec;

	.fileDownloadBox {
		padding: 20px;
		display: flex;
		align-items: center;
		background-color: var(--primaryColor);
		border-radius: 10px;

		.imageHolder {
			// width: 64px;
			// height: 64px;
			// min-width: 64px;
			// min-height: 64px;
			overflow: hidden;
			margin-right: 15px;

			img {
				width: 100%;
			}
		}

		.fileInfo {
			flex-grow: 1;
			p {
				margin: 0;
				font-size: 18px;
				line-height: 27px;
				font-weight: 700;
				color: #fff;
			}

			span {
				font-size: 14px;
				line-height: 21px;
				font-weight: 700;
				color: rgba(299, 299, 299, 0.7);
			}
		}

		.date,
		.size,
		.download {
			text-align: center;
			padding: 0 15px;
			p {
				margin: 0;
				font-size: 26px;
				line-height: 30px;
				font-weight: 700;
				color: #fff;
			}

			span {
				font-size: 14px;
				line-height: 21px;
				font-weight: 700;
				color: rgba(299, 299, 299, 0.7);
				display: block;
				text-transform: uppercase;
			}
		}

		.download {
			img {
				position: relative;
				top: -5px;
			}
			span {
				-webkit-transition: all 0.3s ease;
				-moz-transition: all 0.3s ease;
				-o-transition: all 0.3s ease;
				-ms-transition: all 0.3s ease;
				transition: all 0.3s ease;
			}
			&:hover {
				span {
					color: rgba(299, 299, 299, 1);
				}
			}
		}

		&.active {
			background-color: #1fbed7;
			.fileInfo,
			.date {
				p {
					color: #fff;
				}
				span {
					color: rgba(299, 299, 299, 0.7);
				}
			}
		}
	}
}
