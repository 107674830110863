@import "assets/scss/index.scss";

.container {
	display: flex;
	flex-direction: column;

	.horizontalSection {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 0px 15%;
		align-items: center;
		background-color: white;
		top: 0;
		position: sticky;
		z-index: 2;
		min-height: 64px;

		@include sm {
			padding: 0px 5%;
		}

		.leftPositionedDiv {
			left: 20px;
			top: 0;
			bottom: 0;
			margin: 0;
			height: 100%;
			display: flex;
			align-items: center;

			.roundLogo {
				position: relative;
				width: 72px;
				height: 100%;
				border-radius: 50%;

				:global(.cls-1) {
					fill: #009cb9;
				}

				:global(.cls-2) {
					fill: #009cb9;
				}
			}

			@media screen and (max-width: 900px) {
				display: none;
			}

			@include sm {
				display: none;

				.buttonText {
					display: none;
				}

				.logoutButton {
					display: contents;
				}
			}

			span {
				@include sm {
					display: none;
				}
			}

			.noMarginBtn {
				width: unset;
				margin: unset;

				span {
					margin-right: 8px;
				}
			}
		}

		.centerPositionedDiv {
			flex-direction: row;
			text-align: center;
			justify-content: center;
			height: 100%;
			display: flex;
			margin-left: auto;
			margin-right: auto;

			:global(.ant-tabs-tab) {
				@include sm {
					margin-left: 10px;
				}
			}

			:global(.ant-tabs-top > .ant-tabs-nav) {
				margin: 0px;
				height: 100%;
			}

			:global(.ant-tabs-tab-btn) {
				font-family: "Nunito-Regular";
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 20px;
				color: #4d5a81;
			}

			:global(.ant-tabs-ink-bar) {
				background: #009cb6;
			}

			:global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
				color: #009cb6;
				font-family: "Nunito-Bold";
				font-style: normal;
				font-weight: 700;
				font-size: 14px;
			}

			:global(.ant-tabs-tab:hover) {
				color: #009cb6;
			}

			.roundLogo {
				position: relative;
				width: 107px;
				height: 80px;
				border-radius: 50%;

				:global(.cls-1) {
					fill: #009cb9;
				}

				:global(.cls-2) {
					fill: #009cb9;
				}
			}
		}

		.rightPositionedDiv {
			right: 20px;
			top: 0;
			bottom: 0;
			margin: 0;
			height: 100%;
			display: flex;
			align-items: center;

			a {
				color: #fff;
				font-family: "Avenir Heavy", sans-serif;
			}

			.logoutButton {
				visibility: hidden;
				width: 0px;
			}

			.avatarIcon {
				height: 32px;
				width: 32px;

				@include sm {
					height: 24px;
					width: 24px;
				}
			}

			.avaterText {
				font-family: Nunito-Regular;
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
				line-height: 16px;
				color: #4d5a81;
				max-width: 100px;
				overflow: hidden;
				text-overflow: ellipsis;
				word-break: break-all;
				text-wrap: nowrap;
			}

			@include sm {
				top: 20px;
				height: 24px;

				.buttonText {
					display: none;
				}

				.logoutButton {
					width: 16px;
					visibility: visible;
					justify-content: end;
				}

				.avaterText {
					display: none;
					font-family: "Nunito-Regular";
					font-style: normal;
					font-weight: 400;
					font-size: 12px;
					line-height: 16px;
				}
			}

			@media screen and (max-width: 1000px) {
				.avaterText {
					display: none;
				}
			}
		}
	}

	.childrenContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;

		@include sm {
			display: block;
		}
	}
}
