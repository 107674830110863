@import "assets/scss/mixins.scss";

.container {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	margin-bottom: 16px;

	.titleContainer {
		padding: 20px 10px;
		margin: 0px 10px;
		border-bottom: 1px solid #cdcdcd;
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;

		.backBackButtonContainer {
			font-family: "Avenir Heavy", sans-serif;

			> :first-child {
				cursor: pointer;
			}

			position: absolute;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;

			span {
				margin-left: 10px;
				font-size: 22px;

				@include sm {
					display: none;
				}
			}
		}

		.centeredText {
			display: flex;
			flex-direction: row;
			justify-content: center;
			flex: 1;

			.title {
				color: grey;
				margin-right: 5px;
			}

			.name {
				color: var(--headerBackground);
				font-size: 16px;
				line-height: 21px;
			}
		}
	}

	:global(.ant-form.ant-form-vertical) {
		padding: 10px 120px;

		@include sm {
			padding: 16px;
		}
	}

	.input {
		margin: 10px 0px 0px 0px;

		input,
		textarea {
			margin: 5px 0px 0px 0px;
			border-radius: 8px;
		}
	}

	.link {
		margin: 5px 0px;
	}

	.tagsGroup {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
	}

	.primaryButton {
		display: flex;
		flex-direction: row;
		justify-content: center;

		button {
			padding: 0px 36px;
			font-size: 22px;
			line-height: 16px;
			background-color: var(--primaryColor);
			border-color: var(--primaryColor);
			font-weight: bold;
			height: 36px;
			border-radius: 16px;
			min-width: 200px;

			@include sm {
				width: 100%;
				border-radius: 14px;
			}

			&:hover,
			&:focus,
			&:active {
				border-color: #1d9abe;
				background-color: #1d9abe;
				color: #fff;
			}
		}
	}

	.datepicker {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;

		label {
			color: var(--announcementHeaderBackground);
		}

		:global(.ant-form-item) {
			width: 100%;
		}

		@include sm {
			:global(.ant-picker) {
				width: 100%;
			}

			:global(.ant-picker-input) {
				width: 100% !important;
			}
		}

		:global(.ant-picker-input) {
			width: 200px;
		}
	}

	.noOfCharactersRemaining {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		color: #80808082;
		margin-top: -10px;
	}
}

.detailsInput {
	padding: 5px;
	min-height: 200px;

	div {
		> textarea {
			border-radius: 8px;
			border: 1px solid #d9d9d9 !important;
		}
	}

	div:first-of-type {
		font-size: 14px;
		min-height: 63px;
		font-weight: normal;
		background-color: rgb(255, 255, 255);

		div:first-of-type {
			overflow: hidden;
			position: relative;
			overflow-wrap: break-word;
			box-sizing: border-box;
			text-align: start;
			white-space: pre-wrap;
			border: none !important;
			border-radius: 8px;
		}
	}

	.listItem {
		border: 1px solid var(--announcementHeaderBackground);
		color: var(--announcementHeaderBackground) !important;
		padding: 4px 20px 4px !important;

		&.listItemFocused {
			background: var(--primaryColor) !important;
			color: #ffffff !important;
		}
	}

	textarea {
		border: none !important;
		line-height: inherit;
		height: 100%;
		padding: 5px;
		background-color: #fff;

		&:hover,
		&:focus,
		&:active {
			border: 1px solid var(--primaryColor) !important;
			border-right-width: 1px !important;
			-webkit-box-shadow: 0 0 0 2px var(--primaryColor) !important;
			box-shadow: var(--primaryColor) !important;

			outline: 0;
		}
	}

	::placeholder {
		color: #cacaca !important;
	}
}

.divPositionRelativeWrapper {
	> div {
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
		border-radius: 8px !important;
		background-color: white !important;
		min-width: 100px !important;

		@include extra-small {
			top: 40px !important;
			left: 0 !important;
			width: 100% !important;
		}
	}

	.listItem {
		width: 300px;
		color: var(--announcementHeaderBackground) !important;
		padding: 4px 20px 4px !important;

		&.listItemFocused {
			background: var(--primaryColor) !important;
			color: #ffffff !important;
		}

		@include extra-small {
			width: 100%;
		}
	}

	ul {
		max-height: 290px;
		overflow: auto;

		li {
			div {
				border-radius: 4px;
				height: 45px;
				display: flex;
				gap: 15px;
				align-items: center;

				span {
					font-family: "Avenir Heavy", sans-serif;
					font-size: 14px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: break-all;
				}
			}
		}
	}
}

:global(.ant-picker) {
	height: 40px;
}
