@import '../../../../assets/scss/mixins.scss';
.container {
	display: flex;
	flex-direction: row;
	min-height: 80px;
	align-items: center;
	padding: 30px 30px 30px 15px;
	border-top: 1px #80808054 solid;
	margin-top: 5px;
	@include sm {
		padding: 10px;
	}
	.avatar {
		// max-width: 40px;
		margin-right: 10px;
		max-width: 50px;
		height: 50px;
		border-radius: 100%;
		border: 4px solid #fff;

		img {
			width: 100%;
		}
	}
	.textContainer {
		padding: 4px;
		border: 1px #d9d9d9 solid;
		display: flex;
		flex-direction: row;
		flex: 1;
		position: relative;
		border-radius: 10px;
		&.flexColumnDirection {
			flex-direction: column;
		}
		textarea {
			resize: none;
			border: none;
			padding: 4px 4px;
			&:focus {
				box-shadow: none !important;
			}
		}
		.sendButtonContainer {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			&.flexRowDirection {
				flex-direction: row;
			}
			button {
				color: var(--primaryColor) !important;
				border: none;
				background: none !important;
				&:disabled {
					color: #b4b4b454 !important;
				}
			}
		}
	}
}
