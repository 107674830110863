.wrapper {
	position: relative;
	display: flex;
	flex: 1;
	margin-bottom: 24px;
	.reactPlayerContainer {
		position: relative;
		padding-top: 56.25%;
		display: flex;
		flex: 1;
	}
}
.loaderContainer {
	position: "absolute";
	display: flex;
	flex: 1;
}

.player {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
