@import "../../assets/scss/index.scss";

.onboardingLayout {
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	.container {
		display: flex;
		flex-direction: column;
		flex: 1;
		max-width: 700px;
		align-items: center;
		@include for-phone-only {
			padding: 0px 12px;
			overflow-x: hidden;
		}
		.header {
			margin-top: 80px;
			display: flex;
			flex-direction: column;
			text-align: center;

			.title {
				color: var(
					--selectedCommunityToJoinHeaderBackground,
					var(--headerBackground)
				);
				font-weight: 900;
				font-family: "Avenir-Heavy", sans-serif;
				font-size: 24px;
			}
			.subtitle {
				font-family: "Avenir-Heavy", sans-serif;
				font-size: 16px;
			}
		}
	}
}
