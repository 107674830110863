@import "assets/scss/index.scss";

.container {
	background-color: #fff;
	border-radius: 8px;
	display: flex;
	flex-direction: row;
	padding: 32px;
	gap: 20px;

	@include extra-small {
		padding: 20px;
	}

	.dateBox {
		border: 1px solid var(--primaryColor);
		border-bottom: 0;
		align-self: flex-start;
		font-size: 11px;
		line-height: 17px;
		font-weight: 700;
		min-width: 50px;
		max-width: 60px;
		border-radius: 8px;
		position: relative;
		cursor: pointer;

		.placeholderSvg {
			position: absolute;
			top: -5px;

			rect {
				fill: var(--primaryColor);
			}
		}

		@include extra-small {
			display: none;
		}

		.day {
			min-height: 27px;
			font-family: "Nunito-Bold";
			font-weight: 700;
			line-height: 22px;
			font-size: 16px;
			width: 100%;
			color: var(--primaryColor);
			background-color: #fff;
			text-align: center;
			border-radius: 8px;
			display: inline-grid;
			align-items: center;
			text-transform: uppercase;
		}

		.date {
			width: 100%;
			color: #fff;
			font-family: "Nunito-Regular";
			font-weight: 400;
			line-height: 22px;
			font-size: 16px;
			background-color: var(--primaryColor);
			text-align: center;
			min-height: 27px;
			border-bottom-right-radius: 7px;
			border-bottom-left-radius: 7px;
			display: inline-grid;
			align-items: center;
		}
	}

	.featuredEventBox {
		.topLine {
			display: flex;
			flex-direction: row;

			.calendar {
				display: flex;
				flex-direction: row;
				flex-grow: 1;
				word-break: break-all;

				p {
					font-family: "Nunito-Bold";
					font-size: 18px;
					line-height: 20px;
					color: #000;
					word-break: break-word;

					@include extra-small {
						margin-bottom: 9px !important;
					}
				}
			}
		}

		display: flex;
		flex-direction: column;
		position: relative;
		// background-color: #f7f6f3;
		border-radius: 10px;
		// padding: 20px;
		margin-bottom: 10px;
		justify-content: space-between;

		cursor: pointer;

		.infoBox {
			.timePlace {
				font-size: 14px;
				font-family: "Nunito-Regular";
				line-height: 20px;
				color: #4d5a81;

				p {
					color: var(--primaryColor);
					font-size: 14px;
					font-family: "Nunito-Regular";
				}
			}

			p {
				margin-right: 5px;
				font-size: 18px;
				line-height: 27px;
				font-weight: 700;
				color: #000;
			}

			.tagSection {
				display: flex;
				align-items: center;
				margin-top: 8px;
				flex-wrap: wrap;

				@include extra-small {
					margin-top: 9px !important;
				}

				.avatar {
					width: 20px;
					height: 20px;
					border-radius: 100%;
					overflow: hidden;
					margin-right: 8px;

					img {
						max-width: 100%;
						display: block;
					}
				}

				span {
					display: inline-block;
					// text-transform: uppercase;
					padding: 0 10px;
					height: 20px;
					line-height: 20px;
					border-radius: 10px;
					font-size: 12px;
					font-family: "Nunito-Regular";
					margin: 2px;
					background: rgba(
						var(
							--selectedCommunityToJoinPrimaryColorRgb,
							var(--primaryColorRgb)
						),
						$alpha: 0.2
					);
					color: var(--primaryColor);
				}
			}
		}

		.addToCalendarBtn {
			display: flex;
			flex-direction: row;
			font-family: "Avenir Heavy", sans-serif;
			border: 1px solid var(--primaryColor);
			border-radius: 8px;
			height: 40px;
			align-items: center;
			margin-top: 16px;
			color: var(--primaryColor);
			width: 160px;

			:global(.ant-menu-horizontal) {
				border-bottom: none !important;
			}

			:global(.ant-menu-submenu-title) {
				color: var(--primaryColor) !important;
				font-family: "Nunito-Regular";
			}

			:global(.ant-menu-submenu-title .ant-menu-item-icon + span) {
				margin-left: 4px;
			}

			:global(.ant-menu-submenu::after) {
				border-bottom: 0px;
			}

			:global(.react-add-to-calendar__button) {
				background-color: var(--primaryColor) !important;
				padding: 8px;
				color: #ffffff;
				font-weight: bold;
				white-space: nowrap;
			}

			:global(.ant-menu-horizontal:not(.ant-menu-dark)
					> .ant-menu-submenu:hover::after) {
				border-bottom: none !important;
				display: none;
			}

			:global(.react-add-to-calendar__dropdown) {
				-webkit-box-shadow: 7px 6px 19px -9px rgba(0, 0, 0, 0.75);
				-moz-box-shadow: 7px 6px 19px -9px rgba(0, 0, 0, 0.75);
				box-shadow: 7px 6px 19px -9px rgba(0, 0, 0, 0.75);
				font-family: "Avenir Heavy", sans-serif;
				z-index: 1;
				width: 100%;

				ul {
					padding: 10px;
				}

				ul li a {
					color: var(--announcementHeaderBackground);
					font-weight: bold;
					display: inline-block;
					margin: 4px 0px;
					width: 100%;

					&:hover {
						border-radius: 4px;
						color: white;
						background: var(--announcementHeaderBackground);
					}
				}

				background-color: #ffffff;
				border-radius: 10px;
			}
		}
	}
}

:global(.ant-menu.ant-menu-sub.ant-menu-vertical) {
	font-family: "Avenir Heavy", sans-serif;
}
