@import "assets/scss/index.scss";

.groupsWrapper {
	display: flex;
	align-items: center;
	padding-bottom: 30px;
	width: 100%;
	border-radius: 8px;
	margin: auto;
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;

	@include for-tablet-portait-only {
		width: 550px;
		padding: 0px 8px;
		align-self: stretch;
	}

	@include for-phone-only {
		width: unset;
		align-self: stretch;
		margin: 0px
	}

	.emptyDataGreyText {
		display: flex;
		flex-direction: column;
		flex: 1;
		justify-content: center;
		align-items: center;
		color: grey;
		font-style: italic;
	}

	.antBtn {
		font-weight: 700;
		font-size: 14px;
		border: none;
		color: #fff;
		background-color: #000;
		border-radius: 25px;
		padding: 13px 20px;
		line-height: 0px;
		height: 50px;
		width: 200px;
		text-align: center;
		margin: 0px 10px;

		@include sm {
			max-width: 140px;
			max-height: 35px;
			line-height: 5px;
		}

		&:disabled {
			opacity: 0.6;
			pointer-events: none;
		}

		&:hover,
		&:focus,
		&:active {
			background-color: var(--selectedCommunityToJoinHeaderBackground);
			color: #fff;
		}
	}
}

.btnContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 12px 0px;
	width: 552px;
	background-color: #fff;
	position: sticky;
	bottom: 0px;
	// height: 100px;
}