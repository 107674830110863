.hay {
    :global {
        .ant-form-item {
            .ant-row.ant-form-item-row {
                display: unset;
            }

            .ant-form-item-control {
                width: 100%;
            }
        }

        .ant-form-item-label {
            line-height: 20px;
            color: #131313;
            font-weight: 700;
            font-size: 16px;
            width: 100%;
            text-align: left !important;
            font-family: "Nunito-Regular" !important;
        }

        .ant-input,
        span.ant-input-password.ant-input-affix-wrapper {
            font-size: 14px;
            border: none;
            background-color: transparent !important;
            border-radius: 8px;
            padding: 12px;
            line-height: 24px;
            height: 40px;
        }

        .ant-input,
        span.ant-input-password.ant-input-affix-wrapper {
            font-size: 14px;
            border: none;
            // background-color: #f6f6f9 !important;
            border-radius: 8px;
            padding: 12px;
            line-height: 24px;
            height: 40px;
        }

        span.ant-input-password.ant-input-affix-wrapper {
            padding: 0 8px 0 12px;
        }

        .has-error .ant-input,
        .has-error .ant-input:hover {
            background-color: transparent;
        }

        .ant-form-item-control-input-content {
            display: flex;
            flex-direction: row;

            border: 1px solid rgba($color: var(--selectedCommunityToJoinPrimaryColorRgb,
                        (145, 145, 150)),
                    $alpha: 0.3);


            border-radius: 4px;
        }
    }


    :global(.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after) {

        display: inline-block;
        margin-left: 1px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: "*";
    }

    :global(.ant-form-item-label > label::after) {
        content: "" !important;
    }

    :global(.ant-form-item .ant-form-item-label > label) {
        font-size: 14px !important;
        line-height: 20px;
        font-family: "Nunito-Regular" !important;
        font-weight: 400;
    }


    :global(.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before) {

        display: none;
        font-size: 14px;
        line-height: 20px;
        font-family: "Nunito-Regular";
    }

}