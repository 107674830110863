@import '../../assets/scss/mixins.scss';
.threadsSection {
	background: #fff;
	border-radius: 8px;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;

	:global(.ScrollbarsCustom-Content) {
		padding: 30px !important;
	}
	h3 {
		margin: 0;
		padding-bottom: 10px;
		font-size: 26px;
		line-height: 38px;
		font-weight: 700;
		color: #131313;
		padding: 20px 30px 0px;

		span {
			color: rgba(19, 19, 19, 0.7);
			display: inline-block;
			margin-left: 15px;
		}
	}
}


@include sm {
	.threatsWrapper {
		flex-direction: row;
		row-gap: 0px;
		column-gap: 10px;
	}
	.threatList {
		min-width: 100%;
	}
}

.chatSection {
	background: #fff;
	border-radius: 8px;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;

	> div:first-child {
		flex-grow: 1;
	}
}
.noActiveChats {
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: center;
	color: rgba(14, 83, 107, 0.5);
	font-style: italic;
	font-size: 12px;
}
