@import "assets/scss/mixins.scss";

.form {
	animation: fade 200ms ease-out;
	display: flex;
	flex-direction: column;
	flex: 1;
}

.formGroup {
	margin: 15px 0px;
	padding: 0;
	border-style: none;
	// background-color: #f7f7f7;
	will-change: opacity, transform;
	// box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
	// 	inset 0 1px 0 var(--selectedCommunityToJoinHeaderBackground);
	border-radius: 4px;
}

.formRow {
	display: flex;
	flex-direction: column;
	gap: 16px;

	.stripInput {
		padding: 10px 12px;
		border: 1px solid #DDE1E4;
		border-radius: 8px;
		// &:hover,
		// &:focus-within,
		// &:target,
		// &:active,
		// &:focus {
		// 	border-color: red;
		// }
		
	}
 :global(.StripeElement) {
	width: 100% !important;
	&:hover {
		border-color: red;
	}
 }
}
.formRow:first-child {
	border-top: none;
}

.stripeElementWebkitAutofill {
	background: transparent !important;
}

.stripeElement {
	width: 100%;
	padding: 11px 15px 11px 0;
	.InputElement.is-empty.Input.Input--empty::placeholder {
		color: red; // #009bb9;
	}
}

.submitButton {
	display: block;
	font-size: 16px;
	width: calc(100% - 30px);
	height: 40px;
	margin: 40px 15px 0;
	background-color: var(--selectedCommunityToJoinHeaderBackground);
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
		inset 0 1px 0 var(--selectedCommunityToJoinHeaderBackground);
	border-radius: 4px;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
	transition: all 100ms ease-in-out;
	will-change: transform, background-color, box-shadow;
}

.submitButton:active {
	background-color: var(--selectedCommunityToJoinHeaderBackground);
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
		inset 0 1px 0 var(--selectedCommunityToJoinHeaderBackground);
	transform: scale(0.99);
}

.submitButton.submitButton--error {
	transform: translateY(15px);
}
.submitButton.submitButton--error:active {
	transform: scale(0.99) translateY(15px);
}
.submitButton:disabled {
	opacity: 0.5;
	cursor: default;
	background-color: var(--selectedCommunityToJoinHeaderBackground);
	box-shadow: none;
}
.errorMessage {
	color: red;
	// position: absolute;
	display: flex;
	justify-content: center;
	padding: 0 15px;
	font-size: 13px;
	margin-top: 0px;
	width: 100%;
	transform: translateY(-15px);
	opacity: 0;
	animation: fade 150ms ease-out;
	animation-delay: 50ms;
	animation-fill-mode: forwards;
	will-change: opacity, transform;
}
.errorMessage svg {
	margin-right: 10px;
}

.checkbox {
	// border-top: 1px solid #009bb9;
	margin: 0px 0px 15px 0px;
	padding: 10px 0px;
	// background: beige;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
		inset 0 1px 0 var(--selectedCommunityToJoinHeaderBackground);
	border-radius: 4px;

	:global(.ant-checkbox-wrapper) {
		margin-left: 15px;
		color: var(--selectedCommunityToJoinHeaderBackground);
	}
	:global(.ant-checkbox-checked .ant-checkbox-inner) {
		background-color: var(--selectedCommunityToJoinHeaderBackground);
		border-color: var(--selectedCommunityToJoinHeaderBackground);
	}
}
