@import "assets/scss/mixins.scss";


.welcomeModal {
    .confirmationModalContainer {
        padding: 50px 0 42px 0;
        text-align: center;
        width: 100%;
        max-width: 319px;

        .textContainer {
            padding-bottom: 16px;
            padding-top: 20px;
            text-align: center;

            .heading {
                font-family: Avenir;
                font-size: 24px;
                font-weight: 800;
                line-height: 32px;

            }

            .subHeading {
                font-family: 'Nunito-Regular';
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
            }
        }

        button {
            width: 172px !important;
        }
    }
}