@import "assets/scss/index.scss";

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between !important;
	position: relative;
	align-items: center;
	width: 100%;
	background: var(--white);
	z-index: 2;
	padding: 0px 15%;
	height: 64px;

	.primaryColorTextBtn {
		font-family: Avenir-Heavy;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;

		@include extra-small {
			width: 48px !important;
			height: 40px !important;

			span {
				display: none;
			}
		}
	}

	@include sm {
		justify-content: space-between;
		gap: 10px;
		height: 70px;
		padding: 0px 5%;
	}

	.link {
		color: #fff;
		position: absolute;
		font-family: "Avenir Heavy", sans-serif;
	}

	.roundLogo {
		position: relative;
		width: 107px;
		border-radius: 50%;

		:global(.cls-1) {
			fill: #009cb9;
		}

		:global(.cls-2) {
			fill: #009cb9;
		}
	}

	.communityLogo {
		height: 48px !important;
		object-fit: contain;
		border-radius: 50%;

		@include sm {
			height: 50px !important;
			width: 50px !important;
		}
	}

	.leftPositionedButton {
		left: 20px;
		top: 0;
		bottom: 0;
		margin: 0;
		height: 100%;
		display: flex;
		align-items: center;
		cursor: pointer;

		.back {
			display: flex;
			gap: 12px;
			align-items: center;

			span {
				font-family: "Nunito-Regular";
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 20px;
				color: #4d5a81;
			}
		}

		span {
			@include sm {
				display: none;
			}
		}
	}

	.arrowLeftIcon {
		width: 12px;
		height: 10px;

		@include extra-small {
			display: block;
		}
	}

	.rightPositionedDiv {
		right: 20px;
		top: 0;
		bottom: 0;
		margin: 0;
		height: 100%;
		display: flex;
		align-items: center;

		a {
			color: #fff;
			font-family: "Avenir Heavy", sans-serif;
		}

		.logoutButton {
			visibility: hidden;
			width: 0px;
		}

		.avaterText {
			font-family: "Nunito-Regular";
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			color: #4d5a81;
		}

		@include sm {
			.buttonText {
				display: none;
			}

			.logoutButton {
				width: 16px;
				visibility: visible;
				justify-content: end;
			}

			.avaterText {
				display: none;
			}
		}
	}
}
