.relatedEventsBox {
	background: #fff;
	border-radius: 8px;
	padding: 23px 24px;
	display: inline-block;
	width: 100%;
	min-height: 140px;


	p {
		font-size: 18px;
		line-height: 20px;
		font-family: "Nunito-Bold";
		color: #000000;
		margin-bottom: 9px !important;
	}

	.container {
		display: flex;
		align-items: center;
		// margin-top: 8px;
		// margin-bottom: 20px;

		.avatar {
			width: 70px;
			height: 40px;
			border-radius: 100%;
			overflow: hidden;
			margin-right: 8px;

			img {
				max-width: 100%;
				display: block;
			}
		}

		.tagSection {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			span {
				margin-bottom: 4px;
			}
		}

		span {
			display: inline-block;
			text-transform: uppercase;
			padding: 0 10px;
			height: 20px;
			line-height: 20px;
			border-radius: 10px;
			font-size: 12px;
			font-weight: 700;
			background: rgba(var(--selectedCommunityToJoinPrimaryColorRgb,
						var(--primaryColorRgb)),
					$alpha: 0.2);
			color: var(--primaryColor);

			&.blue {
				background: #e3efff;
				color: #1975d0;
			}

			&.purple {
				background: #f5f0ff;
				color: #764ced;
			}
		}

		span:not(:first-child) {
			margin-left: 2px;
			/* Adjust the margin value as per your requirements */
		}
	}

	.relatedEventImg {
		border-radius: 10px;
		overflow: hidden;

		img {
			width: 100%;
			-webkit-transition: all 0.3s ease;
			-moz-transition: all 0.3s ease;
			-o-transition: all 0.3s ease;
			-ms-transition: all 0.3s ease;
			transition: all 0.3s ease;
		}
	}

	&:hover {
		.relatedEventImg {
			img {
				-webkit-transform: scale(1.1);
				-moz-transform: scale(1.1);
				-ms-transform: scale(1.1);
				-o-transform: scale(1.1);
				transform: scale(1.1);
			}
		}
	}
}