@import "assets/scss/index.scss";

.eventWrapper {
	border-radius: 8px;
	z-index: 0;
	display: flex;
	flex: 1;
	max-height: 100%;
	max-width: 100%;

	@media only screen and (max-width: 998px) {
		padding: 10px;
		display: none;
	}

	font-family: "Avenir Heavy", sans-serif;
	color: #fff;
	padding: 2px 10px;
	font-family: "Avenir Heavy", sans-serif;
	background: var(--primaryColor);
}

:global(.rbc-row-segment) {
	padding: 3px 10px;

	button {
		color: var(--primaryColor);
		padding: 0px;

		&:hover,
		&:focus {
			color: var(--primaryColor);
		}
	}

	@include extra-small {
		display: none;
	}
}

:global(.ant-popover-content) {
	left: -35px;
	top: -10px;

	@include extra-small {
		display: none;
	}
}

:global(.ant-popover-inner) {
	border-radius: 8px !important;
}

:global(.ant-popover-open) {
	border: none;
	box-sizing: border-box;
	box-shadow: none;
	margin: 0;
	padding: 2px 10px;
	font-family: "Avenir Heavy", sans-serif;
	background: rgba(
		var(--selectedCommunityToJoinPrimaryColorRgb, var(--primaryColorRgb)),
		$alpha: 0.8
	);

	border-radius: 8px;
	color: #fff;
	cursor: pointer;
	width: 100%;
	text-align: left;
}

.featuredEventBox {
	padding: 32px 24px;

	@include extra-small {
		display: none;
	}

	.topLine {
		display: flex;
		flex-direction: row;

		.calendar {
			display: flex;
			flex-direction: row;
			flex-grow: 1;
			word-break: break-all;

			p {
				margin: -5px 0 0;
				font-family: "Nunito-Bold";
				font-size: 18px;
				line-height: 20px;
				color: #000;

				@include extra-small {
					margin-bottom: 9px !important;
				}
			}
		}
	}

	display: flex;
	flex-direction: column;
	position: relative;
	border-radius: 10px;
	margin-bottom: 10px;
	justify-content: space-between;
	cursor: pointer;

	.infoBox {
		.timePlace {
			font-size: 14px;
			font-family: "Nunito-Regular";
			line-height: 20px;
			color: #4d5a81;

			p {
				color: var(--primaryColor);
				font-size: 14px;
				font-family: "Nunito-Regular";
			}
		}

		p {
			margin-right: 5px;
			font-size: 18px;
			line-height: 27px;
			font-weight: 700;
			color: #000;
		}
	}

	.readMore {
		color: var(--primaryColor);
		font-size: 14px;
		font-family: "Nunito-Regular";
		margin-top: 10px;
		text-decoration: underline;
	}

	.tagSection {
		display: flex;
		align-items: center;
		margin-top: 8px;
		flex-wrap: wrap;

		.avatar {
			width: 20px;
			height: 20px;
			border-radius: 100%;
			overflow: hidden;
			margin-right: 8px;

			img {
				max-width: 100%;
				display: block;
			}
		}

		span {
			display: inline-block;
			text-transform: uppercase;
			padding: 0 10px;
			height: 20px;
			line-height: 20px;
			border-radius: 10px;
			font-size: 12px;
			font-family: "Nunito-Regular";
			background: rgba(
				var(
					--selectedCommunityToJoinPrimaryColorRgb,
					var(--primaryColorRgb)
				),
				$alpha: 0.2
			);
			color: var(--primaryColor);
		}

		span:not(:first-child) {
			margin-left: 2px;
		}
	}

	.addToCalendarBtn {
		display: flex;
		flex-direction: row;
		font-family: "Avenir Heavy", sans-serif;
		border: 1px solid var(--primaryColor);
		border-radius: 8px;
		height: 40px;
		align-items: center;
		margin-top: 16px;
		color: var(--primaryColor);

		:global(.ant-menu-submenu-title) {
			color: var(--primaryColor) !important;
		}

		:global(.react-add-to-calendar__button) {
			background-color: var(--primaryColor) !important;
			padding: 8px;
			color: #ffffff;
			font-weight: bold;
			white-space: nowrap;
		}

		:global(.react-add-to-calendar__dropdown) {
			-webkit-box-shadow: 7px 6px 19px -9px rgba(0, 0, 0, 0.75);
			-moz-box-shadow: 7px 6px 19px -9px rgba(0, 0, 0, 0.75);
			box-shadow: 7px 6px 19px -9px rgba(0, 0, 0, 0.75);
			font-family: "Avenir Heavy", sans-serif;
			z-index: 1;
			width: 100%;

			ul {
				padding: 10px;
			}

			ul li a {
				color: var(--announcementHeaderBackground);
				font-weight: bold;
				display: inline-block;
				margin: 4px 0px;
				width: 100%;

				&:hover {
					border-radius: 4px;
					color: white;
					background: var(--announcementHeaderBackground);
				}
			}

			background-color: #ffffff;
			border-radius: 10px;
		}
	}
}

:global(.ant-menu.ant-menu-sub.ant-menu-vertical) {
	font-family: "Avenir Heavy", sans-serif;
}
