@import '../../../../../../assets/scss/mixins.scss';
.containerHeader {
	display: "flex";
	flex-direction: "row";
	justify-content: "space-between";
	align-items: "center";
	border-bottom: "1px solid rgb(212, 212, 212)";
	padding: 10;
}

.emptyListContainer {
	width: 380;
	height: 400;
	display: "flex";
	justify-content: "center";
	align-items: "center";
}

.rowContainer {
	display: flex;
	flex: 1;
	.colContainer {
		display: flex;
		flex-grow: 1;
		.notifCardContainer {
			display: flex;
			flex-direction: row;
			flex-grow: 1;
			justify-content: flex-start;
			padding: 8px 4px;
			padding-left: 10px;
			word-break: break-word;
			&:hover {
				background-color: #ececec;
				cursor: pointer;
			}
			.redBulletContainer {
				display: flex;
				flex-direction: column;
				justify-content: center;
				.redBullet {
					position: absolute;
					width: 10px;
					height: 10px;
					background-color: #dc0000;
					border-radius: 50%;
				}
			}
			.greenBulletContainer {
				display: flex;
				flex-direction: column;
				justify-content: center;
				.greenBullet {
					position: absolute;
					width: 10px;
					height: 10px;
					background-color: green;
					border-radius: 50%;
				}
			}
			.imgContainer {
				margin-right: 16px;
				margin-left: 16px;
				img {
					width: 42px;
				}
			}
			.bodyContainer {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				font-size: 12px;
				word-wrap: break-word;
				// overflow-wrap: normal;
				.smallTextHeaderBackgroundColor {
					font-size: 10px;
					font-weight: bold;
					color: var(--headerBackground);
				}
			}
		}
	}
}

.flexSpaceBetweenContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid rgb(212, 212, 212);
	padding: 10px;
	.flexRow {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
}

.notificationWrapper {
	max-height: 350px;
	overflow: auto;
	@include for-phone-only {
		padding: 10px;

	}
}
