.container {
	display: flex;
	margin-top: 10px;
	.tag {
		height: 25px;
		font-weight: bold;
		border-radius: 10px;
		line-height: 25px;
		:global(.anticon.anticon-close) {
			font-size: 12px;
			margin-left: 8px !important;
			vertical-align: middle;
			&:hover {
				color: red;
			}
		}
		font-family: "Avenir Heavy", sans-serif;
	}
}
