@import "/src/assets/scss/mixins.scss";
.container {
	display: flex;
	flex-direction: column;
	padding: 8px 0px 16px 16px;
	border-bottom: 1px #dedede solid;
	.header {
		margin-bottom: 4px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.arrowsIcons {
			cursor: pointer;
		}
		.title {
			font-size: 18px;
			font-weight: 800;
			color: #555;
		}

		.titleSlim {
			font-size: 18px;
			font-weight: 500;
			color: #555;
		}
	}
	.content {
		font-family: "Avenir Light", sans-serif;
		.pointerCursor {
			cursor: pointer;
			border-bottom: 1px solid #999;

			&:hover {
				color: #1890ff;
			}
		}
		.defaultCursor {
			cursor: default;
			color: #333;
			font-weight: 800;
			font-size: 14px;
		}
		.divider {
			font-size: 10px;
			color: #999;
			text-transform: uppercase;
		}

		.checkboxGroup {
			display: flex;
			flex-direction: column;
			font-size: 14px;
			:global(.ant-checkbox-group) {
				display: flex;
				flex-direction: column;
			}
			&.accents {
				font-family: "Avenir Light", sans-serif;
			}
		}
	}
}

@include sm {
	.cardWrapper {
		padding: 0px !important;
	}
}
