.container {
	position: absolute;
	bottom: 10px;
	right: 30px;
	.buttonBox {
		border-radius: 15px;
		font-weight: 900;
		display: inline;
		color: var(--headerBackground);
		border-color: var(--headerBackground);
		&:hover {
			background: var(--headerBackground);
			color: var(--white);
		}
	}
}
