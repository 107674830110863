.modalContainer {
	:global(.ant-modal-header) {
		border-top-left-radius: 8px !important;
		border-top-right-radius: 8px !important;
	}
	:global(.ant-modal-content) {
		border-radius: 8px !important;
	}
	:global(.ant-modal-footer) {
		padding-right: 26px;
	}
	:global(.ant-btn) {
		border-radius: 6px;
	}
}
