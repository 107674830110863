@import "../../assets/scss/mixins.scss";

.container {
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	margin-top: 16px;

	:global(.p-CardNumberInput) {
		// font-family: 'Nunito-Regular';
		// font-style: normal;
		// font-size: 14px;
		// line-height: 20px;
		// color: #292F45;
		height: 40px !important;
	}

	.submitBtn {
		flex-direction: row;

		justify-content: center;
		margin-top: 12px;

		&:hover {
			border-color: var(--selectedCommunityToJoinHeaderBackground);
			color: var(--selectedCommunityToJoinHeaderBackground);
		}

		&.activeButton {
			background: var(--selectedCommunityToJoinHeaderBackground);
			color: #fff;
		}
	}

	.errorMsg {
		color: red;
		text-align: center;
	}
}