.form {
	padding: 20px 10px;
	width: 100%;
	background-color: var(--white);

	.formTitle {
		font-size: 16px;
		font-weight: bold;
		color: var(--secondaryColor);
	}
	.radioButtonsBox {
		.radioButtons {
			font-family: "Avenir Heavy", sans-serif;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.radioButton {
				display: flex;
				justify-content: center;
				flex: 1;
				margin: 0px 4px;
				line-height: 30px;
				border: 1px solid var(--announcementHeaderBackground);
				border-radius: 5px;
				color: var(--announcementHeaderBackground);
				font-weight: bold;
			}
			.radioButton:first-child {
				margin-left: 0px;
			}
			.radioButton:last-child {
				margin-right: 0px;
			}
		}
		:global(.ant-radio-button-wrapper:not(:first-child))::before {
			display: none;
		}
		:global(.ant-radio-group-solid
				.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
			background: var(--announcementHeaderBackground);
			color: var(--white);
		}
	}
	textarea {
		resize: none;
	}
	:global(.ant-form-item) {
		margin-bottom: 10px;
	}
	:global(.ant-col .ant-form-item-label) {
		padding: 0px;
	}
	.submitButton {
		display: flex;
		flex-direction: row;
		margin-top: 15px;
		justify-content: center;
		button {
			background: var(--primaryColor);
			border: 1px solid;
			border-radius: 20px;
			width: 130px;
			font-weight: bold;
		}
	}
}
