@import "assets/scss/mixins.scss";

.confirmationModal {
	.confirmationModalContainer {
		width: 100%;
		max-width: 319px;
		padding: 50px 0 42px 0;
		text-align: center;

		.messageContainer {
			font-size: 24px;
			font-weight: 800;
			line-height: 32px;
			color: #292f45;
			padding-bottom: 16px;
			padding-top: 20px;

			span {
				color: #0091b6;
			}
		}
	}

	:global(.ant-modal-content) {
		max-width: 500px;
		max-height: 272px;
	}
}
