@import "../../../../assets/scss/mixins.scss";

.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 32px;
	.imageHolder {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 100%;
		.fullWidthImg {
			width: 100%;
		}
		.positionIcon {
			width: 72px;
			height: 113px;
			margin: 0 auto;
			left: 0;
			right: 0;
			top: 58px;
		}
		.headerColorIcon {
			color: var(
				--selectedCommunityToJoinPrimaryColor,
				var(--primaryColor)
			);
		}
	}
	.bigFontSizeText {
		font-style: normal;
		font-weight: 900;
		font-size: 24px;
		line-height: 33px;
		text-align: center;
		color: var(
			--selectedCommunityToJoinHeaderBackground,
			var(--headerBackground)
		);
	}
	.normalFontSizeText {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		text-align: center;

		color: #03142d;
		.underlinedText {
			text-decoration: underline;
			cursor: pointer;
			color: #000;
		}
	}
}
