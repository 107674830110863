@import "assets/scss/mixins.scss";

.container {
	display: flex;
	flex-direction: column;
	gap: 32px;
	flex: 1 1;
	position: relative;
	width: 70%;
	// margin: 24px 0px;
	margin-bottom: 24px;
	height: 100%;

	@media (max-width: 600px) {
		width: 100%;
	}

	.paymentPlanContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 16px;

		.monthlyPaymentLabel {
			font-family: 'Nunito-Bold';
			font-size: 14px;
			font-weight: 700;
			line-height: 20px;
			color: #292F45;
		}

		.yearlyPaymentContainer {
			position: relative;
			height: 60px;
			width: 200px;
			display: flex;
			align-items: flex-end;

			.yearlyPaymentLabel {
				font-family: 'Nunito-Bold';
				font-size: 14px;
				font-weight: 700;
				line-height: 20px;
				color: #0091B6;
			}

			.arrow {
				position: absolute;
				bottom: 25px;
				right: 60%;
			}

			.tag {
				height: 25px;
				border-radius: 32px;
				font-family: 'Nunito-Regular';
				font-size: 12px;
				font-weight: 400;
				line-height: 16px;
				padding: 2px 8px;
				background-color: #0091B6;
				color: white;
				align-self: self-end;

				position: absolute;
				bottom: 45px;
				right: 0;
			}
		}
	}

	.responsiveCardsContainer {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		row-gap: 22px;
		justify-content: center;
		padding-bottom: 16px;
	}

	.carousel {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex: 1 1;

		.slider {
			display: flex;
			flex-direction: row;
			flex: 1 1;
			padding: 8px;

			div:first-of-type {
				display: flex;
				flex-direction: column;
				flex: 1;

				ul:first-of-type {
					display: flex;
					flex: 1 1;
				}
			}

			.slide {
				height: inherit;
				padding-bottom: unset !important;

				:global(.slideInner___2mfX9.carousel__inner-slide) {
					display: flex;
					flex-direction: column;
					flex: 1;
				}

				display: flex;
				flex: 1 1;
				margin-right: 32px;
				overflow: hidden;
				box-shadow: 1px 2px 6px 0px #dedede;
				min-height: 630px;
			}
		}

		.button {
			position: absolute;
			background: transparent;
			border-radius: 16px;
			display: flex;
			height: 28px;
			flex-direction: column;
			justify-content: center;
			bottom: 50%;

			&.nextButton {
				right: -42px;
			}

			&.backButton {
				left: -42px;
			}

		}

	}

	.noDataAvailableTxt {
		display: flex;
		flex-direction: column;
		flex: 1;
		justify-content: center;
		align-items: center;
		color: grey;
	}
}