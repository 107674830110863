@import "assets/scss/index.scss";

.boldWeightLabel {
	font-size: 14px;
	font-family: "Nunito-Regular";
	// margin: 5px 0px;
}

.greySmallText {
	font-size: 14px;
	color: #4D5A81;
	margin: 10px 0px;
	font-family: 'Nunito-Regular';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;

	.underlineDecoration {
		text-decoration: underline;
		cursor: pointer;
		color: rgba(0, 0, 0, 0.6);
	}
}

.checkboxGroup {
	:global(.ant-checkbox-group) {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	:global(.ant-checkbox) {
		display: none;
	}

	.singleCheckbox {
		display: inline-flex;
		border: 1px solid rgba(var(--selectedCommunityToJoinHeaderBackgroundRgb,
					var(--headerBackgroundRgb)),
				0.4) !important;
		border-radius: 6px !important;
		padding: 2px 12px !important;
		font-size: 13px !important;
		// font-weight: bold;
		color: var(--selectedCommunityToJoinHeaderBackground,
				var(--headerBackground)) !important;
		line-height: 20px !important;
		margin: 3px 8px 3px 0px !important;
		white-space: nowrap;
		cursor: pointer;
		background-color: rgba(var(--selectedCommunityToJoinHeaderBackgroundRgb,
					--headerBackgroundRgb),
				0.06);

		&.selected {
			background: var(--selectedCommunityToJoinHeaderBackground,
					--headerBackground) !important;
			color: #ffffff !important;
		}

		&.disabled {
			color: #cdcdcd !important;
			border: 1px solid #cdcdcd !important;
			cursor: default;
		}
	}

	:global(.ant-checkbox + span) {
		display: flex;
		border: 1px solid rgba(var(--selectedCommunityToJoinHeaderBackgroundRgb,
					var(--headerBackgroundRgb)),
				0.4) !important;
		border-radius: 6px !important;
		padding: 2px 12px !important;
		font-size: 13px !important;
		color: var(--selectedCommunityToJoinHeaderBackground,
				--headerBackground) !important;
		line-height: 20px !important;
		margin: 3px 0px !important;
		white-space: nowrap;
		background-color: rgba(var(--selectedCommunityToJoinHeaderBackgroundRgb,
					var(--headerBackgroundRgb)),
				0.06);
	}

	:global(.ant-checkbox.ant-checkbox-checked + span) {
		background: var(--selectedCommunityToJoinHeaderBackground,
				var(--headerBackground)) !important;
		color: #ffffff !important;
	}

	&.accents {
		font-family: "Avenir Heavy", sans-serif;
	}
}