.cardTitleContainer {
}

.cardTitle {
	font-family: "Avenir Heavy";
	font-style: normal;
	font-weight: 900;
	font-size: 20px;
	line-height: 24px;
	color: #000000;
	padding: 18px 24px;
	margin-bottom: 0;
}

.folders {
	height: 50px;
	padding: 0 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
}

.backToRoot {
	border-right: 1px solid #d9d9d9;
	padding: 0 15px;
	display: flex;
	align-items: center;
	height: 100%;
}

.leftArrow {
	transform: rotate(90deg);
	width: 11px;
	height: 11px;
}

.folder {
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 10px;
	h1 {
		margin-bottom: 0;
		font-family: "Nunito-Regular";
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: #32363d;
		text-overflow: ellipsis;
		overflow: hidden;
		word-wrap: break-word;
		white-space: nowrap;
	}
}

.arrowDown {
	min-width: 14px;
	min-height: 14px;
	max-width: 14px;
	max-height: 14px;
}
