@import "/src/assets/scss/mixins.scss";

.container {
	display: flex;
	background: var(--white);
	flex-direction: column;
	border-radius: 12px;
	.photoBoxes {
		display: flex;
		flex-direction: column;
		flex: 1;
		padding: 10px;

		> div:first-child {
			display: flex;
			flex-direction: row;
			flex: 1;
			margin: 20px !important;
			background: #8080801c;
			padding: 10px;
			border-radius: 12px;
			@include for-phone-only {
				padding: 0px;
				max-height: 112px;
				overflow: auto;
			}
		}
		.box {
			display: flex;
			background: var(--white);
			border: dotted 1px var(--primaryColor) !important;
			height: 70px;
			justify-content: center;
			align-items: center;
			&:hover {
				border: 1px solid var(--primaryColor) !important;
			}
		}
	}
	.plusIcon {
		font-size: 20px;
		color: var(--announcementHeaderBackground) !important;
	}
	.photoText {
		display: flex;
		text-align: -webkit-center;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-weight: 900;
		color: var(--announcementHeaderBackground);
		cursor: pointer;
		div:nth-of-type(2) {
			font-weight: normal;
		}

		.uploadPhotoIcon {
			fill: var(--primaryColor);
		}
	}
	:global(.ant-upload.ant-upload-select.ant-upload-select-picture-card) {
		width: 124px !important;
		background: none !important;
		border-radius: 12px;
		border: none;
	}
	:global(.ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture-card) {
		width: 134px;
		border-radius: 12px;
	}
	:global(.ant-upload-list-picture-card-container) {
		width: 134px;
	}
	:global(.ant-upload-list-item-info) {
		&::before {
			border-radius: 12px;
		}
	}
	.tagsContainer {
		.title {
			display: flex;
			flex-direction: row;
			justify-content: center;
			font-weight: bold;
			color: var(--secondaryColor);
		}
		.tags {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			background: var(--white);
			padding: 5px 25px;
			border-radius: 8px;
			margin-top: 5px;

			.checboxGroup {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				:global(.ant-checkbox) {
					display: none;
				}
				:global(.ant-checkbox + span) {
					display: flex;
					flex-basis: 30%;
					flex-grow: 1;
					border: 1px solid var(--primaryColor) !important;
					border-radius: 6px !important;
					padding: 5px 15px !important;
					font-size: 13px !important;
					font-weight: bold;
					color: var(--primaryColor) !important;
					line-height: 20px !important;
					margin: 3px 0px !important;
				}
				:global(.ant-checkbox.ant-checkbox-checked + span) {
					background: var(--primaryColor) !important;
					color: var(--white) !important;
				}
			}
		}
	}
}
.uploadIconDiv {
	position: relative;
	display: flex;
	.uploadPhotoIcon {
		fill: var(--primaryColor);
	}
	:global {
		.anticon.anticon-plus-circle {
			position: absolute;
			right: -10px;
			top: -5px;
			font-size: 18px;
			> svg {
				fill: var(
					--selectedCommunityToJoinPrimaryColor,
					--primaryColor
				);
			}
		}
	}
}
