.profileCheckListItem {
	background-color: #f7f8fa;
	padding: 10px 20px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	margin-bottom: 5px;

	.defaultSquare {
		background-color: #efefef;
		border-radius: 4px;
		display: inline-block;
		width: 30px;
		min-width: 30px;
		height: 30px;
		margin-right: 10px;

		&.checkedSquare {
			background: url("../../assets/images/icons/common_icons/checkmark_ico.png")
				no-repeat center #00c569;
		}
	}

	p {
		font-size: 15px;
		line-height: 15px;
		font-weight: 700;
		flex-grow: 1;
		margin: 0;
	}
}
