@import "assets/scss/mixins.scss";

.container {
	margin: 0 auto;
	width: 100%;
	max-width: 300px;

	@include extra-small {
		max-width: 100%;
	}
}
