.notApprovedContainer {
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: space-evenly;

	.heavyTitle {
		font-family: "Avenir-Heavy", sans-serif;
	}

	.roundButton {
		button {
			background: var(--primaryColor);
			border: 1px solid;
			border-radius: 20px;
			/* width: 130px; */
			font-weight: bold;
			color: #fff;

			&:hover {
				background-color: rgba(var(--primaryColorRgb), 0.5) !important;
			}
		}
	}
}

.btnContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	// padding: 20px 0px;
	padding: 12px 0px;
	width: 452px;
	background-color: white;

	.btnPosition {
		align-self: center;
	}
}

.pictureWrapper {
	display: flex;
	// margin-top: 24px;
}