.container {
	padding: 20px 0px;
	display: flex;
	flex: 1;
	flex-direction: column;
	.lodingContainer {
		display: flex;
		flex-direction: column;
		flex: 1;
		justify-content: center;
	}
}
