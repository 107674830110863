.offerBox {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	border-radius: 15px;
	-webkit-box-shadow: 0px 0px 10px 2px rgba(153, 153, 153, 0.3);
	-moz-box-shadow: 0px 0px 10px 2px rgba(153, 153, 153, 0.3);
	box-shadow: 0px 0px 10px 2px rgba(153, 153, 153, 0.3);
	background-color: #ffffff;
	&:hover {
		-webkit-box-shadow: 0px 0px 10px 2px rgba(153, 153, 153, 0.5);
		-moz-box-shadow: 0px 0px 10px 2px rgba(153, 153, 153, 0.5);
		box-shadow: 0px 0px 10px 2px rgba(153, 153, 153, 0.5);
	}

	.cover {
		height: 190px;
		display: flex;
		img {
			object-fit: cover !important;
			width: 100%;
			height: 100%;
			border-top-left-radius: 15px;
			border-top-right-radius: 15px;
		}
	}
	.content {
		margin: 16px 8px;
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		.title {
			font-size: 16px;
			font-weight: bold;
			color: #000000;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-family: 'Avenir-Heavy';
		}
		:global(.ant-divider-horizontal) {
			margin: 24px 0px 10px 0px;
			margin-left: -8px;
			margin-right: -8px;
			width: auto;
		}
		.description {
			font-family: "Nunito-Regular";
			:global(.trackY) {
				width: 5px !important;
				right: -5px !important;
			}
			:global(.ScrollbarsCustom.trackYVisible) {
				padding-right: 2px !important;
			}
			// overflow: auto;
			flex-grow: 1;
			word-break: break-word;
		}
		.seemorebtn {
			color: #00bdd9;
			font-family: Nunito-SemiBold;
			&:hover {
				cursor: pointer;
			}
		}
		.tagsContainer {
			small {
				color: var(--primaryColor);
				font-size: 12px;
				line-height: 12px;
				font-weight: normal;
			}

			span {
				font-size: 14px;
				line-height: 21px;
				font-weight: normal;
				color: rgba(0, 0, 0, 0.7);
			}
			.tag {
				cursor: pointer;
				&:not(:first-child) {
					margin-right: 4px;
				}
				font-family: "Nunito-Regular";
				font-style: normal;
				font-weight: 400;
				// font-size: 14px;
				line-height: 20px;
			}
		}
		.websitePhoneContainer {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-top: 16px;
			.website {
				//display: inline-block;
				//border: 1px solid var(--primaryColor);
				//border-radius: 20px;
				//padding: 2px 10px;
				//color: #ffffff;
				font-size: 12px;
				font-weight: bold;
				text-decoration: underline;
				line-height: 22px;
				font-family: "Avenir Heavy", sans-serif;
				//background-color: var(--primaryColor);
				//height: fit-content;
			}
			.phone {
				border: 1px solid var(--primaryColor);
				border-radius: 20px;
				padding: 0px 15px;
				background-color: var(--primaryColor);
				color: #ffffff;
				font-family: "Avenir Heavy", sans-serif;

				span {
					font-size: 12px;
				}
				&:hover {
					border: 1px solid var(--primaryColor);
					color: var(--primaryColor);
					background-color: #ffffff;
				}
			}
		}

		.locationAndExpiryDate {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			margin-top: 16px;
		}

		.redeemContainer {
			.label {
				// color: var(--primaryColor);
			}
			.redeemButtons {
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				padding: 10px 0px;
				font-family: "Avenir Heavy", sans-serif;
				a {
					border-radius: 20px;
					padding: 0px 40px;
					background-color: var(--primaryColor);
					color: #ffffff;
					&:hover {
						background-color: #ffffff;
						border: 1px solid var(--primaryColor);
						color: var(--primaryColor);
					}
				}
			}
		}
		.contentMinHeight {
			min-height: 190px;
		}
	}
}
