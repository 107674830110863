@import '../../../../assets/scss/mixins.scss';
.callToActionBtnContainer {
	//   padding: 30px 0px 20px 0px;
	padding: 0px 20px 0px 0px;
	margin-bottom: 10px;

	button {
		background: transparent;
		border-color: var(--primaryColor);
		border-radius: 10px;
		font-weight: bold;
		color: var(--primaryColor);
		&:hover {
			background: transparent;
			color: var(--primaryColor);
			background-color: rgba($color: var(--primaryColorRgb), $alpha: 0.2);
			border-color: var(--primaryColor);
		}
		&:focus {
			background: transparent !important;
			border-color: var(--primaryColor) !important;
			color: var(--primaryColor) !important;
		}
	}
}
@include for-phone-only {
	.formColWrapper {
		flex-direction: column-reverse !important;
		justify-content: flex-end;
		gap:15px;
		.photoFormWrapper {
			flex:none !important;
			max-width: 100% !important;
			// max-height: 26% !important;
		}
		.fieldsFormWrapper {
			max-width: 100%
		}
	};
}
