@import "assets/scss/mixins.scss";

.container {
	display: flex;
	flex-direction: row;
	flex: 1;
	background-color: #ffffff;
	border-radius: 5px;
	padding: 8px;
	align-items: center;
	margin-bottom: 15px;
	.inputBox {
		display: flex;
		flex: 1;
		background-color: rgba(250, 250, 250, 1);
		border-color: rgba(245, 245, 245, 1);
		flex-direction: row;
		flex-direction: row;
		margin: 0px 10px;
		border-radius: 25px;
		padding: 0px 15px;
		line-height: 45px;
		border-width: 1px;
		@include extra-small {
			line-height: 40px;
		}
		border-style: solid;
		color: #999;
		font-weight: lighter;
		&:hover {
			cursor: pointer;
			background-color: rgba(245, 245, 245, 1);
			border-color: rgba(235, 235, 235, 1);
			color: #333;
		}
		font-family: "Avenir Light", sans-serif;
	}
}

.avatar {
	> img {
		width: 64px;
		height: 64px;
		border-radius: 50px;
		@include extra-small {
			width: 40px;
			height: 40px;
			min-width: 40px;
		}
	}
}
