@import "assets/scss/index.scss";

.cellContainer {
	display: flex;
	flex: 1;
	margin: 2px 3px;
	border: unset !important;
	background-color: #ffffff !important;
}

.cellContainerSelected {
	display: flex;
	flex: 1;
	border: unset !important;
	background-color: #ffffff !important;

	button {
		color: white;
	}

	@media only screen and (max-width: 998px) {
		display: flex;
		flex: 1;
		margin: 0px !important;
		background-color: var(--primaryColor) !important;
	}
}

.cellContainerSelected:last-child {
	margin: 0px;
	background-color: rgba(0, 0, 0, 0.05) !important;

	@media only screen and (max-width: 998px) {
		background-color: var(--primaryColor) !important;
	}
}

.cellContainerSelected:nth-last-child(2) {
	margin: 0px;
	background-color: rgba(0, 0, 0, 0.05) !important;

	@media only screen and (max-width: 998px) {
		background-color: var(--primaryColor) !important;
	}
}

.cellContainer:last-child {
	margin: 0px;
	background-color: rgba(0, 0, 0, 0.05) !important;
}

.cellContainer:nth-last-child(2) {
	margin: 0px;
	background-color: rgba(0, 0, 0, 0.05) !important;
}

:global(.rbc-month-row + .rbc-month-row) {
	border: unset !important;
}

:global(.rbc-month-view) {
	border: unset !important;
	gap: 4px;

	@media only screen and (max-width: 998px) {
		gap: 2px;
	}
}
