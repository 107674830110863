@import "assets/scss/mixins.scss";

.membersSection {
	background: #fff;
	border-radius: 8px;
	padding: 30px;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;

	.headerContainer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-bottom: 10px;

		.membersHeader {
			display: flex;
			flex: 1;
			flex-direction: row;
			justify-content: space-between;
		}

		.settingsHeader {
			display: flex;

			.label {
				font-size: 16px;
				margin-left: 6px;
				font-weight: bold;
				line-height: 18px;
			}
		}

		h3 {
			margin: 0;
			font-size: 26px;
			line-height: 38px;
			font-weight: 700;
			color: #131313;

			span {
				color: rgba(19, 19, 19, 0.7);
				display: inline-block;
				margin-left: 15px;
			}
		}

		.cogWheel {
			align-self: center;

			span {
				font-size: 22px;
			}

			:hover {
				cursor: pointer;
			}
		}
	}
}

.memberPreviewSection {
	background: #fff;
	border-radius: 8px;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
}

.noMemberSelected {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: rgba(14, 83, 107, 0.5);
	font-style: italic;
	font-size: 12px;
}

.mainContainer {
	display: flex;
	flex-direction: column;
	flex: 1;

	.settingIcon {
		circle {
			stroke: var(--primaryColor);
		}
		path {
			stroke: var(--primaryColor);
		}
		cursor: pointer;
	}

	.memberWrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;

		.memberCount {
			font-size: 16px;
			font-family: "Avenir-Medium";
			color: #000000;
			margin-bottom: 10px;
		}
	}

	:global(.ScrollbarsCustom) {
		padding-right: 15px;

		.ScrollbarsCustom-Wrapper {
			height: 100%;
			position: relative !important;
		}

		:global(.ScrollbarsCustom-TrackY) {
			top: 0 !important;
			bottom: 0 !important;
			right: 0px !important;
			background: rgba(
				var(
					--selectedCommunityToJoinPrimaryColorRgb,
					var(--primaryColorRgb)
				),
				$alpha: 0.2
			) !important;
			width: 6px !important;
			height: calc(100% -20px) !important;

			:global(.ScrollbarsCustom-ThumbY) {
				border-radius: 3px;
				background-color: var(--primaryColor) !important;
			}
		}

		:global(.ScrollbarsCustom-TrackX) {
			background: rgba(
				var(
					--selectedCommunityToJoinPrimaryColorRgb,
					var(--primaryColorRgb)
				),
				$alpha: 0.2
			) !important;

			:global(.ScrollbarsCustom-ThumbX) {
				border-radius: 3px;
				background-color: var(--primaryColor) !important;
			}
		}
	}

	.membersWrapper {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
		gap: 10px;
		overflow: auto;

		@include extra-small {
			grid-template-columns: 1fr;
		}
	}

	.infiniteScrollContainer {
		display: flex;
		flex-direction: column;
		flex: 1;
	}
}
