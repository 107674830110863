.fileDescriptionWrapper {
	padding: 10px 30px 10px;
	border-bottom: 1px solid #ececec;
	.title {
		font-weight: bold;
		margin-right: 5px;
	}
	small {
		color: var(--primaryColor);
		font-size: 12px;
		line-height: 12px;
		font-weight: normal;
	}
	.description {
	}
}
