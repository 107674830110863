.wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-bottom: 24px;
	.title {
		margin-bottom: 8px;
		line-height: 20px;
		color: #000;
		font-size: 18px;
		font-weight: 700;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.reactPlayerContainer {
		position: relative;
		display: flex;
		justify-content: center;
		flex-direction: column;
		flex: 1;
		padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
	}
	.tagsContainer {
		margin-top: 16px;
		small {
			color: var(--primaryColor);
			font-size: 14px;
			line-height: 14px;
			font-weight: normal;
		}

		span {
			font-size: 14px;
			line-height: 21px;
			font-weight: normal;
			color: rgba(0, 0, 0, 0.7);
		}
		.tag {
			cursor: pointer;
			&:not(:first-child) {
				margin-right: 4px;
			}
			font-family: "Nunito-Regular";
			font-style: normal;
			font-weight: 400;
			// font-size: 14px;
			line-height: 20px;
		}
	}
}
.loaderContainer {
	display: flex;
	flex: 1;
}

.player {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
