.createFeedBox {
	display: flex;
	align-items: center;
	width: 100%;
	background: #fff;
	padding: 10px 30px 10px 20px;
	border-radius: 8px;
	margin-bottom: 20px;

	.avatar {
		width: 72px;
		height: 72px;
		min-width: 72px;
		border-radius: 100%;
		overflow: hidden;
		margin-right: 27px;

		img {
			max-width: 100%;
		}
	}

	.createFeedInput {
		flex-grow: 1;
		position: relative;

		input[type="text"] {
			background: rgba(122, 143, 166, 0.06);
			border: none;
			height: 40px;
			line-height: 40px;
			padding: 0 115px 0 20px;
			border-radius: 20px;
			width: 100%;
			outline: none;
			font-size: 13px;
			font-weight: normal;
			color: #767676;
			background-repeat: no-repeat;
			background-position: 15px center;

			&::placeholder {
				color: #767676;
				opacity: 1;
			}

			&:-ms-input-placeholder {
				color: #767676;
			}

			&::-ms-input-placeholder {
				color: #767676;
			}
		}

		input[type="file"] {
			width: 0.1px;
			height: 0.1px;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			z-index: -1;
		}

		label {
			height: 20px;
			line-height: 20px;
			border-radius: 10px;
			padding: 1px 9px 0 23px;
			font-size: 10px;
			font-weight: 700;
			color: #fff;
			background: url("../../assets/images/icons/common_icons/attach_file_ico.png")
				no-repeat 9px center var(--primaryColor);
			display: inline-block;
			cursor: pointer;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 15px;
			margin: auto;
		}
	}
}
