@import 'assets/scss/mixins.scss';

.flexRow {
	display: flex;
	flex-direction: row;
	justify-content: center;
	// margin-bottom: 10px;
	align-items: center;

	@include for-phone-only {
		flex-direction: column;

		.currencyWrapper {
			padding-top: 5px;
			text-align: center
		}
	}

	;
}

.checkbox {
	:global(.ant-checkbox) {
		display: none;
	}

	:global(.ant-checkbox + span) {
		display: flex;
		border: 1px solid var(--selectedCommunityToJoinHeaderBackground, --headerBackground) !important;
		border-radius: 25px !important;
		padding: 4px 15px !important;
		font-size: 13px !important;
		font-weight: 900;
		color: var(--selectedCommunityToJoinHeaderBackground,
				--headerBackground) !important;
		line-height: 20px !important;
		margin: 3px 0px !important;
	}

	:global(.ant-checkbox.ant-checkbox-checked + span) {
		background: var(--selectedCommunityToJoinHeaderBackground,
				--headerBackground) !important;
		color: #ffffff !important;
	}

	&.accents {
		font-family: "Avenir Light", sans-serif;
	}
}