@import "assets/scss/mixins.scss";

.mentionContent {
	display: inline;
	color: #1200ed !important;
	font-size: 14px;
	line-height: 18px;
	font-weight: 400;
	font-family: "Nunito-Regular";
	&:hover {
		cursor: pointer;
	}

	@include extra-small {
		font-size: 12px !important;
	}
}
.modalContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	.title {
		font-size: 24px;
		color: var(--primaryColor);
	}
	.subTitle {
		font-size: 16px;
		color: var(--primaryColor);
		text-align: center;
		max-width: 70%;
	}
}
