@import "assets/scss/mixins.scss";

.container {
	display: flex;
	flex-direction: column;
	flex: 1;
	max-width: 50%;
	width: 100%;
	height: 100%;

	@include for-phone-only {
		max-width: 100%;
	}

	.featuredImage {
		display: flex;
		flex-direction: row;
		justify-content: center;

		img {
			object-fit: cover;
			border-radius: 15px;
			width: 100%;
			height: 400px;

			@include for-phone-only {
				height: 150px;
			}
		}
	}

	.imageList {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: nowrap;
		margin: 16px 0;
		gap: 8px;
		overflow: auto;
		scrollbar-width: thin;
		min-height: 100px;

		@include for-phone-only {
			min-height: 80px;
		}

		&::-webkit-scrollbar {
			width: 1px;
		}

		&::-webkit-scrollbar-track {
			background: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background-color: transparent;
		}

		.previewImage {
			img {
				object-fit: cover;
				height: 85px;
				max-height: 100px;
				width: 85px;
				max-width: 100px;
				border-radius: 8px;

				@include for-phone-only {
					width: 65px;
					height: 65px;
				}
			}

			&:hover {
				cursor: pointer;
			}
		}
	}
}
