@import "../../assets/scss/mixins.scss";

.container {
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	margin-bottom: 32px;
	.mainBtn {
		&:hover {
			border-color: var(--selectedCommunityToJoinHeaderBackground);
			color: var(--selectedCommunityToJoinHeaderBackground);
		}
		&.activeButton {
			background: var(--selectedCommunityToJoinHeaderBackground);
			color: #fff;
			border-radius: 8px;
		}
	}
	.cardContainer {
		cursor: pointer;
		display: flex;
		justify-content: flex-start;
		border-bottom: 1px solid #dfdfdf;
		.icon {
			margin-right: 12px;
			align-self: center;
		}
		.text {
			display: flex;
			flex-direction: column;
		}
		&:hover {
			background-color: #cdcdcd;
		}
	}
	.payBtn {
		display: block;
		font-size: 16px;
		width: calc(100% - 30px);
		height: 40px;
		margin: 40px 15px 0;
		background-color: var(--selectedCommunityToJoinHeaderBackground);
		box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06),
			0 2px 5px rgba(0, 0, 0, 0.08),
			inset 0 1px 0 var(--selectedCommunityToJoinHeaderBackground);
		border-radius: 4px;
		color: #fff;
		font-weight: 600;
		cursor: pointer;
		transition: all 100ms ease-in-out;
		will-change: transform, background-color, box-shadow;
	}
	.payBtn:active {
		background-color: var(--selectedCommunityToJoinHeaderBackground);
		box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06),
			0 2px 5px rgba(0, 0, 0, 0.08),
			inset 0 1px 0 var(--selectedCommunityToJoinHeaderBackground);
		transform: scale(0.99);
	}
	.payBtn:hover {
		background-color: rgba(
			$color: var(--selectedCommunityToJoinHeaderBackgroundRgb),
			$alpha: 0.5
		);
		box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06),
			0 2px 5px rgba(0, 0, 0, 0.08),
			inset 0 1px 0
				rgba(
					$color: var(--selectedCommunityToJoinHeaderBackgroundRgb),
					$alpha: 0.5
				);
		transform: scale(0.99);
	}
	.payBtn.payBtnError {
		transform: translateY(15px);
	}
	.payBtn.payBtnError:active {
		transform: scale(0.99) translateY(15px);
	}
	.payBtn:disabled {
		opacity: 0.5;
		cursor: default;
		background-color: var(--selectedCommunityToJoinHeaderBackground);
		box-shadow: none;
	}
	.activeCard {
		background-color: #ecececfa;
	}
	.emptyCardListTxtContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
.displayNone {
	display: none !important;
}
.skeletonContainer {
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	:global(.ant-skeleton-element) {
		width: 100%;
		margin-top: 20px;
	}
}
