.container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: rgba(236, 236, 236, 0.3);
	height: 59px;
	font-size: 12px;
	width: 100%;
	.underlinedTextWithPrimaryColor {
		color: var(
			--selectedCommunityToJoinHeaderBackground,
			--headerBackground
		);
		text-decoration: underline;
	}
}
