.memberBox {
	padding: 20px;
	display: flex;
	align-items: flex-start;
	background-color: #f7f6f3;
	border-radius: 10px;
	margin-top: 10px;
	cursor: pointer;

	&:hover {
		background-color: #e8e7e4;
		transition: all 0.4s ease;
	}

	.imageHolder {
		position: relative;
		width: 52px;
		height: 52px;
		min-width: 52px;
		min-height: 52px;
		border-radius: 100%;
		border: 2px solid #fff;
		overflow: hidden;
		margin-right: 15px;

		img {
			width: 100%;
		}
	}

	.memberInfo {
		flex-grow: 1;

		p {
			text-overflow: ellipsis;
			overflow: hidden;
			display: -webkit-box !important;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			white-space: normal;
			margin: 0;
			font-size: 18px;
			line-height: 27px;
			font-weight: 700;
			color: #131313;
			word-break: break-word;
		}

		span {
			text-overflow: ellipsis;
			overflow: hidden;
			display: -webkit-box !important;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			word-break: break-word;

			white-space: normal;
			font-size: 14px;
			line-height: 21px;
			font-weight: 700;
			color: rgba(19, 19, 19, 0.7);
		}
	}

	.memberInfo2 {
		p {
			margin: 0;
			font-size: 14px;
			line-height: 30px;
			font-weight: 700;
			color: rgba(19, 19, 19, 0.7);
			text-align: right;
		}

		span {
			font-size: 14px;
			line-height: 21px;
			font-weight: 700;
			color: rgba(19, 19, 19, 0.7);
			text-align: right;
			display: block;
		}
	}

	&.active {
		background-color: var(--primaryColor);
		.memberInfo,
		.memberInfo2 {
			span,
			p {
				color: rgba(299, 299, 299, 0.7);
			}
		}
	}
}
