.container {
	display: flex;
	flex-direction: column;
	flex: 1;
}
.divider {
	display: flex;
	clear: both;
	width: 100%;
	min-width: 100%;
	margin: 24px 0;
}
