@import "assets/scss/mixins.scss";

.groupBox {
	display: flex;
	flex-direction: column;
	height: 130px;
	width: 100%;
	background: #eef5f8;
	border-radius: 15px;
	margin-bottom: 8px;
	padding: 18px 35px;

	&.spaceBetweenContent {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.transparentBackgroundButton {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 0px 18px;
		color: var(--selectedCommunityToJoinHeaderBackground);
		background-color: transparent;
		border-radius: 15px;
		border-color: var(--selectedCommunityToJoinHeaderBackground);
		width: 110px;

		&:hover {
			border-color: var(--selectedCommunityToJoinHeaderBackground);
			color: var(--selectedCommunityToJoinHeaderBackground);
			background-color: rgba(var(--selectedCommunityToJoinHeaderBackgroundRgb),
					0.1);
		}
	}

	.dangerBtn {
		color: #eb5757;
		border-color: #eb5757;
		margin-left: auto;

		&:hover {
			background-color: rgba(235, 87, 87, 0.1);
		}
	}

	.headerBackgroundColorSvg {
		color: var(--selectedCommunityToJoinHeaderBackground);
		top: -2px;
		position: relative;
		margin-right: 6px;
	}

	.flexColumnDirection {
		display: flex;
		flex-direction: column;
	}

	.mdSizedTxt {
		font-size: 16px;
		text-transform: capitalize;
	}

	.boldTitle {
		font-size: 18px;
		font-weight: bold;
		font-family: "Avenir Heavy", sans-serif;
		margin: 8px 0px;
	}

	.inlineGreyTextWithIcon {
		display: flex;
		flex-direction: row;
		font-size: 16px;
		font-style: normal;
		font-weight: 900;
		color: rgba($color: #000000, $alpha: 0.6);
		align-items: baseline;
		margin-bottom: 20px;
	}

	.headerBackgroundColorTxt {
		color: var(--selectedCommunityToJoinHeaderBackground);
		font-weight: 900;
		font-size: 16px;
		margin: auto 0px;
		padding: 0px 18px;
		border: 1px solid var(--selectedCommunityToJoinHeaderBackground);
		border-radius: 15px;
		height: 32px;
		width: 110px;
		line-height: 30px;
		text-align: center;
	}
}

.groupCardContainer {
	padding: 32px 24px;
	display: flex;
	flex-direction: column;
	width: 552px;
	gap: 12px;
	background-color: white;
	box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
	border-radius: 8px;
	position: relative;

	@include sm {
		width: 100%;
		flex-direction: column;
		gap: 5px;
		margin-top: 0px;
		border-radius: 0px;
		padding: 20px 10px;

	}

	@include sm {
		width: auto;
	}

	.imageHolder {
		width: 72px;
		height: 72px;
		min-width: 72px;
		min-height: 72px;
		// border-radius: 100%;
		border-radius: 8px;
		overflow: hidden;
		// margin-right: 15px;

		img {
			width: 100%;
		}

		.textAvatar {
			display: flex;
			justify-content: center;
			align-items: center;
			font-family: "Lato", sans-serif;
			font-style: normal;
			font-weight: 700;
			font-size: 40px;
			height: 100%;
			width: 100%;
			color: #fff;
			background-color: var(--selectedCommunityToJoinPrimaryColor,
					var(--primaryColor));
			border-color: var(--selectedCommunityToJoinPrimaryColor,
					var(--primaryColor));
			border: none;
		}
	}

	.cardBodyContainer {
		display: flex;
		flex-direction: column;
		gap: 12px;
		width: 100%;

		@include sm {
			gap: 5px;
		}

		.cardBodyHeader {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;

			@include sm {
				justify-content: flex-start;
			}

			.title {
				max-width: 250px;
				font-size: 14px;
				font-weight: 700;
				line-height: 18px;
				color: #292F45;
				font-family: "Nunito", sans-serif;
			}

			.membersCount {
				font-size: 12px;
				font-weight: 400;
				line-height: 16px;
				color: #9CA1BA;
				font-family: "Nunito", sans-serif;
				display: flex;
				align-items: center;
				gap: 6px;

				@include sm {
					padding-top: 4px;
				}
			}

			.privacyContainer {
				display: flex;
				align-items: center;
				gap: 4px;
				padding-top: 10px;
				font-family: 'Nunito-Regular';
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 20px;

				@include sm {
					padding-top: 5px;
				}
			}


		}
	}

	.cardDescription {
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		color: #4D5A81;
		font-family: "Nunito-Regular", sans-serif;
		margin-left: 84px;
	}

	.buttonContainer {
		position: absolute;
		right: 24px;
		width: 150px;

		:global(.ant-btn:not(.ant-btn-primary):hover) {
			border-color: var(--selectedCommunityToJoinPrimaryColor, var(--primaryColor)) !important;
			background-color: rgba(var(--selectedCommunityToJoinPrimaryColorRgb,
						var(--primaryColorRgb)),
					$alpha: 0.8) !important;

			// border-color: #fff !important;
			color: white !important;
		}

		&:hover {
			.leaveIcon {
				stroke: currentColor;
			}
		}

		.leaveIcon {
			margin-right: 8px;
			stroke: var(--selectedCommunityToJoinPrimaryColor,
					var(--primaryColor));
		}

		@include sm {
			position: relative;
			width: 100%;
			right: 0;
		}

		button {
			@include sm {
				width: 100% !important;
			}


		}

		.transparentBackgroundButton {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 10px 24px;
			color: var(--primaryColor);
			background-color: transparent;
			border-radius: 8px;
			border-color: var(--primaryColor);
			width: 160px;
			font-size: 14px;
			line-height: 20px;
			height: 40px;

			&:hover {
				border-color: var(--selectedCommunityToJoinHeaderBackground);
				color: var(--selectedCommunityToJoinHeaderBackground);
				background-color: rgba(var(--selectedCommunityToJoinHeaderBackgroundRgb),
						0.1);
			}
		}

		.dangerBtn {
			color: #eb5757;
			border-color: #eb5757;
			margin-left: auto;
			height: 40px;

			&:hover {
				background-color: rgba(235, 87, 87, 0.1);
			}
		}

		.joinCancelButtons {
			width: 100%;

			.joinedButton {
				button {
					font-size: 14px;
					color: #9CA1BA;
					border: 1px solid #DDE1E4;

					@include sm {

						width: 100%;
					}

				}
			}

			.cancelButton {
				display: none;

				button {
					color: white;
					background-color: #D82140;

					@include sm {
						width: 100%;
					}


				}
			}

			&:hover {
				.joinedButton {
					display: none;
				}

				.cancelButton {
					display: block;

					button {
						color: white;
						background-color: #D82140;
						border: none;
					}
				}
			}
		}

		// .inlineGreyTextWithIcon {
		// 	display: flex;
		// 	flex-direction: row;
		// 	font-size: 16px;
		// 	font-style: normal;
		// 	font-weight: 900;
		// 	color: rgba($color: #000000, $alpha: 0.6);
		// 	align-items: baseline;
		// 	margin-bottom: 20px;
		// }
	}
}