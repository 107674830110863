@import 'assets/scss/mixins.scss';
.uploadInput {
	display: flex;
	flex-direction: column;
	font-family: "Avenir Heavy", sans-serif;
	// @include sm {
	// 	font-size: 10px;
	// }
	> span {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
