.searchBarMultiple {
	background-color: #fff;
	padding: 9px 30px;
	border-radius: 8px;
	margin-bottom: 20px;

	:global {
		.ant-form-item {
			margin-bottom: 0;
		}

		.ant-input {
			font-weight: normal;
			font-size: 16px;
			color: #131313;
			border: 2px solid #eaeaea;
			border-radius: 8px;
			padding: 13px 20px;
			line-height: 24px;
			height: 50px;

			&::placeholder {
				color: #131313;
				opacity: 1;
			}

			&:-ms-input-placeholder {
				color: #131313;
			}

			&::-ms-input-placeholder {
				color: #131313;
			}
		}

		.ant-btn {
			font-weight: 700;
			font-size: 16px;
			border: none;
			color: #fff;
			background-color: var(--announcementHeaderBackground) !important;
			border-radius: 8px;
			// padding: 13px 20px;
			line-height: 24px;
			height: 40px;
			text-align: center;
			width: 100%;

			&:active {
				background-color: var(--announcementHeaderBackground) !important;
				color: #fff !important;
			}

			&:hover {
				outline: none !important;
				background: var(--announcementHeaderBackground) !important;
				color: var(--white) !important;
			}

			&:focus {
				outline: none !important;
				background: var(--primaryColor) !important;
				color: var(--white) !important;
			}
		}
	}
}