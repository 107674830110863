@import "assets/scss/mixins.scss";

.commentBox {
	display: flex;
	flex: 1;
	flex-direction: "row";
	justify-content: center;
	padding: 15px 0px;
	margin: 0 0 0 10px;

	.textAndBtnContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: auto !important;
		cursor: pointer;
	}

	&.lastCommentBox {
		border-bottom: unset;
	}

	.avatarSection {
		text-align: center;

		.avatar {
			width: 40px;
			height: 40px;
			min-width: 40px;
			border-radius: 100%;
			overflow: hidden;

			img {
				max-width: 100%;
			}

			@include extra-small {
				width: 35px;
				height: 35px;
				min-width: 35px;
			}
		}

		.likes {
			font-size: 11px;
			line-height: 11px;
			font-weight: normal;
			color: #7a8fa6;
			display: inline-block;

			img {
				width: 11px;
				margin: -1px 4px 0 0;
			}
		}
	}

	@include extra-small {
		padding: 0;
		margin: 0 0 15px 0;
	}

	.commentInfo {
		flex-grow: 1;
		padding: 0 0 0 15px;

		@include extra-small {
			padding: 0 0 0 10px;
		}

		.textWithEnters {
			white-space: pre-wrap;
			background: #f4f7f9;
			border-radius: 0px 8px 8px 8px;
			margin-top: 5px;
			padding: 10px 14px;
			min-height: 40px;
		}

		.inlineElements {
			display: flex;
			flex-direction: row;
		}

		.commentTime {
			font-size: 11px;
			font-weight: normal;
			color: #525252;
			white-space: nowrap;
			margin-left: 8px;
			font-family: "Avenir";
		}

		h3 {
			font-size: 16px;
			line-height: 16px;
			font-weight: 600;
			color: #000;
			margin: 0;
			font-family: "Avenir";
			cursor: pointer;
		}

		h4 {
			font-size: 12px;
			line-height: 16px;
			font-weight: 600;
			color: #7a8fa6;
			margin: 0;
		}

		.primaryColorTags {
			color: var(--primaryColor);
			font-size: 12px;
			line-height: 12px;
			font-weight: normal;
		}

		div {
			font-size: 14px;
			font-weight: normal;
			margin: 0;
			overflow-wrap: anywhere;
		}

		a {
			color: var(--primaryColor);
		}
	}
}
