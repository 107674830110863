@import '../../../../assets/scss/mixins.scss';

.container {
	display: flex;
	flex-direction: column;
	background: #f7f6f3;
	border-radius: 10px;
	padding: 10px;
	margin-top: 10px;
	align-self: stretch;
	white-space: break-spaces;
	word-break: break-all;

	&.sameMsgBlock {
		margin-top: 2px;
	}

	&.loggedUserMsg {
		display: flex;
		background: #b4f5ff;
	}

	.top {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.leftSide {
			display: flex;
			position: relative;

			.avatar {
				height: 30px;
				width: 30px;
				border-radius: 100%;
				border: 2px solid #fff;
				overflow: hidden;
				margin-right: 10px;
				position: absolute;
				top: -3px;

				// left: -45px;

				img {
					width: 100%;
				}
			}

			.userName {
				padding-left: 40px;
				align-self: flex-end;
				font-weight: bold;
				color: var(--secondaryColor);
			}
		}

		.rightSide {
			position: relative;

			@include sm {
				font-size: 12px;
			}
		}

		&.reverseTop {
			display: flex;

			.avatar {
				height: 30px;
				width: 30px;
				left: unset;
				right: -10px;
				top: -3px;
			}

			.userName {
				align-self: stretch;
				flex-grow: 1;
				padding-right: 40px;
			}
		}
	}

	.bottom {
		word-break: break-word;

		&.boldMessage {
			font-weight: bold;
			color: var(--secondaryColor);
		}
	}
}