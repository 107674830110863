.CustomCheckBox {
    :global(.ant-checkbox-wrapper.ant-checkbox-wrapper-checked) {
        background-color: rgba(var(--selectedCommunityToJoinPrimaryColorRgb,
                    var(--primaryColorRgb)),
                $alpha: 0.3);
        border-color: var(--selectedCommunityToJoinPrimaryColor,
                var(--primaryColor));
    }

    :global(.ant-checkbox-checked::after) {
        border: 0px;
    }

    :global(.ant-checkbox-group) {
        display: flex;
        flex-wrap: wrap;
        gap: 8px
    }

    :global(.ant-checkbox:hover .ant-checkbox-inner) {
        border-color: rgba(var(--selectedCommunityToJoinPrimaryColorRgb,
                    var(--primaryColorRgb)),
                $alpha: 1);
        border-radius: 4px;
    }

    :global(.ant-checkbox-wrapper) {
        padding: 0px 12px;
        border: 1px solid #DDE1E4;
        border-radius: 8px;
        margin: 0px !important;
        height: 32px;
        align-items: center;

    }

    :global(.ant-checkbox-wrapper:hover .ant-checkbox-inner) {
        border-color: rgba(var(--selectedCommunityToJoinPrimaryColorRgb,
                    var(--primaryColorRgb)),
                $alpha: 1);
        border-radius: 4px;
    }

    :global(.ant-checkbox-checked .ant-checkbox-inner) {
        background-color: rgba(var(--selectedCommunityToJoinPrimaryColorRgb,
                    var(--primaryColorRgb)),
                $alpha: 1);
        border-radius: 4px;
        border: 0px;

    }

    :global(.ant-checkbox + span) {
        font-family: 'Nunito-Regular';
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #4D5A81;
    }

    :global(.ant-checkbox) {
        top: 0
    }
}