.buttonsContainer {
	display: flex;
	flex-direction: column;
	@media (max-width: 600px) {
		flex-direction: row;
		width: 100%;
		margin-top: 16px;
		gap: 15px;
	}
}

.modalContent {
	padding: 20px;
	font-family: "Avenir Light", sans-serif;
	text-align: center;
}
