.menuWrapper {
	height: 40px;
	color: rgba(
		var(
			--selectedCommunityToJoinHeaderBackgroundRgb,
			var(--primaryColorRgb)
		),
		1
	);
}

.leaveButton {
	max-width: 65px;

	border: 1px solid
		rgba(
			var(
				--selectedCommunityToJoinHeaderBackgroundRgb,
				var(--primaryColorRgb)
			),
			1
		);
	border-radius: 8px;
}
