@import "../../assets/scss/mixins.scss";

.readMore {
	button {
		color: rgba(var(--selectedCommunityToJoinHeaderBackgroundRgb,
					var(--primaryColorRgb)),
				1);

		&:hover,
		&:focus,
		&:active {
			color: rgba(var(--selectedCommunityToJoinHeaderBackgroundRgb,
						var(--primaryColorRgb)),
					1);
			transform: scale(1.05);
		}
	}

	.collapsed {
		height: 100%;
		animation: readMore 0.5s ease-in-out forwards;
	}

	.expanded {
		height: 4.5em;
		animation: readMore 0.5s ease-in-out backwards;


	}

	::-webkit-scrollbar {
		width: 4px;
	}

	::-webkit-scrollbar-thumb {
		background: rgba(0, 0, 0, .5);
		border-radius: 3px;
	}

	span {
		margin: 0 !important;
		color: #00bdd9;
		font-size: 14px;
		line-height: 18px;
		font-weight: 400;
		font-family: "Nunito-Regular";
	}
}

@keyframes readMore {
	0% {
		height: 100%;
	}

	100% {
		eight: 4.5em;
	}
}