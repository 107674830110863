.container {

	// background: #fff;
	@media (max-width: 600px) {
		width: 100%;
	}

	.buttonContainer {
		// display: flex;
		// flex-direction: column;
		// align-items: flex-end;
		background: var(--primaryColor);
		// border: "2px solid #000";
		border-radius: 10px;
		text-align: center;

		&:hover {
			cursor: pointer;
			background: rgba($color: var(--primaryColorRgb), $alpha: 0.7);
		}

		:global(.ant-btn:not(.ant-btn-primary):hover) {
			border-color: rgba($color: var(--primaryColorRgb), $alpha: 0.7) !important;
			border-radius: 10px;

			// border-color: #fff !important;
			// color: rgba(var(--primaryColorRgb), 1) !important;
		}
	}
}

.modalContent {
	padding: 20px;
	font-family: "Avenir Light", sans-serif;
	text-align: center;
}