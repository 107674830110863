@import "assets/scss/mixins.scss";

.content {
	.title {
		font-size: 25px;
		text-align: center;
		color: #000000;
		font-weight: bold;
	}
	.subTitle {
		border-bottom: 1px solid #f0f0f0;
		font-size: 20px;
		text-align: center;
		color: #000000;
		padding: 5px 5px;
		font-weight: bold;
	}

	.row {
		margin-bottom: 5px;
		.bold {
			font-weight: bold;
			font-size: 18px;
			color: #000000;
		}
		.normal {
			font-size: 18px;
			color: #000000;
		}
	}
	@include extra-small {
		.title {
			font-size: 20px;
		}
		.subTitle {
			font-size: 14px;
		}
		.row {
			margin-bottom: 5px;
			.bold {
				font-size: 16px;
			}
			.normal {
				font-size: 14px;
			}
		}
	}
}
