@import "../../../assets/scss/index.scss";

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: relative;
	width: 100%;
	background: var(
		--selectedCommunityToJoinHeaderBackground,
		var(--headerBackground)
	);
	padding: 19px 17px;
	z-index: 4;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
	height: 118px;

	.link {
		color: #fff;
		position: absolute;
		font-family: "Avenir Heavy", sans-serif;
	}
	.roundLogo {
		position: relative;
		transform: translate(0%, 28%);
		width: 130px;
		height: 130px;
		border-radius: 50%;
	}
	.objectFitContainedLogo {
		object-fit: contain;
	}

	.leftPositionedButton {
		width: 100px;
		height: fit-content;
		cursor: pointer;
		.arrowLeftIcon {
			margin-right: 8px;
		}
	}
}
