@import "assets/scss/mixins.scss";

.thumbnailIcon {
    padding:6px;
    width: 40px !important;
    height: 40px !important;
    object-fit: contain;
    border: 1px solid var(--primaryColor);
    background: white;
    border-radius: 8px;
    @include sm {
        height: 55px !important;
        width: 55px !important;
    }
}
.thumbnailIconfile {
    padding:6px;
    width: 25px !important;
    height: 25px !important;
    object-fit: contain;
    border: 1px solid var(--primaryColor);
    background: white;
    border-radius: 8px;
    color: rgb(226, 229, 231);
    @include sm {
        height: 40px !important;
        width: 40px !important;
    }
}
.bigIcon {
    width: 70px !important;
    height:70px !important;
    object-fit: contain;

}
.bigIconImage {
    width: 70px;
    height:70px;
    object-fit: contain;
    color: rgb(226, 229, 231),
}