.container {
	:global(.ant-select-selector) {
		height: 100% !important;
	}
	.select {
		height: 40px;
		border-radius: 8px;
		&:hover > div {
			border-color: var(--headerBackground) !important;
			border-style: solid !important;
			border-width: 1px !important;
		}
		> div {
			height: 40px !important;
			display: flex;
			align-items: center;
		}
		> div > span:nth-child(2) {
			font-weight: bold !important;
			font-size: 14px;
			font-family: Avenir;
			color: #000;
			text-align: left;
		}
	}
}
