@import "../../../../assets/scss/index.scss";

.centeredBox {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 10px;
	background-color: white;
	max-width: 70%;
	margin: 50px auto 0;
	padding: 40px;
	
	@include sm{
		padding: 20px 10px;
		max-width: 100%;
	}
}

.heading{
	font-size: 24px;
    color: var(--primaryColor);
    text-align: center;
    font-family: "Avenir Heavy";
	max-width: 70%;

	@include sm {
		font-size: 16px;
		max-width: 100%;
	}
}

.description{
	font-size: 16px;
    color: var(--primaryColor);
    text-align: center;
    max-width: 70%;
    font-family: "Nunito-Medium";
	@include sm {
		font-size: 14px;
	}
}

.italicGreyText {
	font-style: italic;
	color: grey;
}
