@import "assets/scss/mixins.scss";

.commentBar {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	background: #fff;
	margin-bottom: 10px;

	// border-bottom: 1px solid rgba(122, 143, 166, 0.19);
	.commentInput {
		display: flex;
		flex-direction: column;
		flex: 1;

		.mentionsInputContainer {
			display: flex;
			background: #f4f5f7;
			border-radius: 8px;
			flex-direction: column;
			align-items: flex-end;
		}

		.sendButtonContainer {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 10px 0;

			button {
				display: flex;
				flex-direction: column;
				justify-content: center;
				background: none !important;
				height: 100%;
				.sendSvgEnabled {
					path {
						stroke: var(--primaryColor);
					}
				}
				.sendSvgDisabled {
					path {
						stroke: #666666;
					}
				}
				span {
					color: #78878b;
					height: 16px;
				}
			}
		}
	}

	.avatar {
		width: 40px;
		height: 40px;
		align-self: flex-start;
		border-radius: 100%;
		overflow: hidden;
		margin-right: 16px;

		img {
			max-width: 100%;
			height: 100%;
		}

		@include extra-small {
			width: 38px;
			height: 38px;
			min-width: 38px;
			margin-right: 10px;
		}
	}

	button {
		background: none;
		border: none;
		font-weight: 700;
		color: var(--primaryColor);
		cursor: pointer;
		outline: none;
	}
}

.mentionsInput {
	display: flex;
	flex-direction: row;
	flex: 1;
	border-radius: 10px;
	padding: 7px;
	font-size: 13px;
	height: 100%;
	align-items: center;

	textarea {
		border: none !important;
		font-family: "Nunito-Regular" !important;
		padding: 10px 0px 10px 11px;

		&:focus,
		&:active {
			outline: none;
		}

		&::placeholder {
			font-family: "Nunito-Regular" !important;
			color: #4d5a81;
		}
	}

	.mention {
		display: none;
		position: relative;
		z-index: 1;
		visibility: hidden;
		color: var(--primaryColor);
	}
}

.divPositionRelativeWrapper {
	> div {
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
		border-radius: 8px !important;
		z-index: 1 !important;
		background-color: white !important;
		min-width: 100px !important;
		top: 45px !important;
		left: 65px !important;
		position: absolute !important;
		margin-top: 0 !important;

		@include extra-small {
			top: 40px !important;
			left: 0 !important;
			width: 100% !important;
		}
	}

	.listItem {
		width: 300px;
		color: var(--announcementHeaderBackground) !important;
		padding: 4px 20px 4px !important;

		&.listItemFocused {
			background: var(--primaryColor) !important;
			color: #ffffff !important;
		}

		@include extra-small {
			width: 100%;
		}
	}

	ul {
		max-height: 290px;
		overflow: auto;

		li {
			div {
				height: 45px;
				display: flex;
				gap: 15px;
				align-items: center;

				span {
					font-family: "Avenir Heavy", sans-serif;
					font-size: 14px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: break-all;
				}
			}
		}
	}
}
