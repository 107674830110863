@import "../../../../assets/scss/mixins.scss";

.container {
	display: flex;
	flex-direction: row;
	// justify-content: space-around;
	margin-bottom: 42px;

	.card {
		display: flex;
		flex-direction: column;
		flex: 1;

		background-color: #ffffff;
		margin: 16px;
		// max-height: 780px;
		// min-width: 0px;
		align-items: center;
		padding: 0px 26px 20px;
		font-family: "Avenir Light", sans-serif;
		text-align: center;
		.headerIcon {
			padding: 18px;
			img {
				width: 100%;
				min-height: 101px; // temporary solution. because the ugeniePlus doesn't have the same height as the others.
			}
		}
		.headerTitle {
			@include extra-small {
				font-size: 14px;
			}
			@include small {
				font-size: 16px;
			}
			@include medium {
				font-size: 18px;
			}
			@include large {
				font-size: 20px;
			}
		}
		.smallIcon {
			// min-height: 70px;
			img {
				@include extra-small {
					max-width: 49px;
					max-height: 48px;
				}
				@include small {
					max-width: 49px;
					max-height: 48px;
				}
				@include medium {
					max-width: 59px;
					max-height: 58px;
				}
				@include large {
					max-width: 69px;
					max-height: 68px;
				}
			}
		}
		.listedItems {
			@include small {
				font-size: 10px;
			}
			@include medium {
				font-size: 16px;
			}
			@include large {
				font-size: 18px;
			}

			margin: 8px 0px;
		}
		.price {
			margin: 22px 0px;
			.boldNumber {
				font-weight: bold;
				@include small {
					font-size: 20px;
				}
				@include medium {
					font-size: 24px;
				}
				@include large {
					font-size: 26px;
				}
				line-height: 26px;
			}
			.lightText {
				@include extra-small {
					font-size: 12px;
				}
				@include small {
					font-size: 14px;
				}
				@include medium {
					font-size: 16px;
				}
				@include large {
					font-size: 18px;
				}
				font-weight: 500;
			}
		}
		.button {
			margin: 22px 0px;

			button {
				background-color: #009bb9;
				border-radius: 20px;
				&:hover {
					background-color: #00aed1;
				}
				@include small {
					font-size: 8px;
				}
				@include medium {
					font-size: 12px;
				}
				@include large {
					font-size: 16px;
				}
			}
		}
	}
}
