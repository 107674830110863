.container {
	display: flex;
	flex-direction: column;
	padding: 8px 0px 16px 16px;
	.btnFullWidth {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-bottom: 26px;
		button {
			flex: 1;
			background: transparent;
			border-color: var(--primaryColor);
			border-radius: 10px;
			font-weight: bold;
			color: var(--primaryColor);
			&:hover {
				background: transparent;
				color: var(--primaryColor);
				background-color: rgba(
					$color: var(--primaryColorRgb),
					$alpha: 0.2
				);
				border-color: var(--primaryColor);
			}
			&:focus {
				background: transparent !important;
				border-color: var(--primaryColor) !important;
				color: var(--primaryColor) !important;
			}
		}
	}
	.header {
		margin-bottom: 4px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.titleSlim {
			font-size: 18px;
			font-weight: 500;
			color: #555;
		}
	}
	.content {
		font-family: "Avenir Light", sans-serif;
		&:hover {
			color: #1890ff;
		}
	}

	.checkboxGroup {
		display: flex;
		flex-direction: column;
		font-size: 14px;
		:global(.ant-checkbox-group) {
			display: flex;
			flex-direction: column;
		}
		&.accents {
			font-family: "Avenir Light", sans-serif;
		}
	}
}
