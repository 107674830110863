@import 'assets/scss/mixins.scss';

.uploadInput {
	display: flex;
	flex-direction: column;
	font-family: "Avenir Heavy", sans-serif;

	// @include sm {
	// 	font-size: 10px;
	// }
	background-color: white;

	.ButtonText {
		font-family: 'Nunito-Regular';
		font-size: 14px;
		line-height: 20px;
	}

	.uploadText {
		font-family: 'Nunito-Regular';
		color: #4D5A81;
		font-style: 'normal';
		font-size: 14px;
		margin-bottom: 8px !important;
	}

	>span {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.uploadIcon {
		fill: rgba(var(--selectedCommunityToJoinPrimaryColorRgb,
					var(--primaryColorRgb)),
				$alpha: 0.3);
		stroke: rgba(var(--selectedCommunityToJoinPrimaryColorRgb,
					var(--primaryColorRgb)),
				$alpha: 1);
	}

	:global(.ant-upload.ant-upload-drag) {
		height: 180px;
	}

	:global(.ant-upload-list-picture .ant-upload-list-item-name) {
		width: 40px;
	}

	:global(.ant-upload.ant-upload-drag p.ant-upload-drag-icon) {
		margin-bottom: 18px !important;
	}
}