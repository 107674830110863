.container {
	margin-bottom: 10px;
	// @media (max-width: 600px) {
	// 	width: 100%;
	// }

}

.mainContainer {
	@media (max-width: 600px) {
		width: 100%;
	}
}

.buttonContainer {
	// display: flex;
	// justify-content: center;
	background-color: var(--primaryColor);
	// border: "2px solid #000";
	border-radius: 10px;

	:global(ant-btn-link) {
		width: 100%;
	}

	&:hover {
		cursor: pointer;
		background: rgba($color: var(--primaryColorRgb), $alpha: 0.7);
	}

	:global(.ant-btn:not(.ant-btn-primary):hover) {
		// border: 2px  !important;
		border-color: rgba($color: var(--primaryColorRgb), $alpha: 0.7) !important;
		border-radius: 10px;
		// color: rgba(var(--primaryColorRgb), 1) !important;
	}

}

.modalContainer {
	:global(.ant-modal-header) {
		border-top-left-radius: 8px !important;
		border-top-right-radius: 8px !important;
	}

	:global(.ant-modal-content) {
		border-radius: 8px !important;
	}

	:global(.ant-modal-footer) {
		padding-right: 26px;
	}

	:global(.ant-modal-title) {
		font-weight: bold;
	}

	:global(.ant-btn) {
		border-radius: 6px;
	}
}

.modalContent {
	padding: 20px;
	font-family: "Avenir Light", sans-serif;
	text-align: center;
}