@import "assets/scss/mixins.scss";

.onboardingLayout {
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	background-color: #f4f5f7;

	.container {
		display: flex;
		flex-direction: column;
		flex: 1;
		max-width: 552px;
		align-items: center;

		@include for-phone-only {
			padding: 0px 12px;
			overflow-x: hidden;
		}

		.header {
			margin-top: 40px;
			display: flex;
			flex-direction: column;
			text-align: center;
			margin-bottom: 16px;

			.title {
				color: var(--selectedCommunityToJoinHeaderBackground,
						var(--headerBackground));
				// font-weight: 900;
				font-family: "Avenir-Regular", sans-serif;
				font-size: 24px;
				margin-bottom: 8px;

				@include sm {
					font-size: 20px;
				}
			}

			.subtitle {
				font-family: "Nunito-Regular", sans-serif;
				font-size: 14px;
				line-height: 20px;
				max-width: 400px;
				color: #4d5a81;
				margin-bottom: 0;
			}
		}
	}

	.topLevelContainer {
		width: 100%;
		background: white;
		display: flex;
		justify-content: center;
		margin-top: 40px;

		@include sm {
			background: #f4f5f7;
			bottom: 0;
			padding: 0px 12px;
		}

		.btnContainer {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			// padding: 20px 0px;
			padding: 12px 0px;
			background-color: white;
			width: 552px;

			button {
				width: 170px !important;

			}

			:global(.ant-btn:not(.ant-btn-primary):hover) {
				border-color: var(--selectedCommunityToJoinPrimaryColor, var(--primaryColor)) !important;
				background-color: rgba(var(--selectedCommunityToJoinPrimaryColorRgb,
							var(--primaryColorRgb)),
						$alpha: 0.8) !important;

				// border-color: #fff !important;
				color: white !important;
			}

			@include sm {
				width: 100%;
				padding: 12px;
				gap: 10px;
			}

			.btnPosition {
				align-self: center;
			}
		}
	}
}