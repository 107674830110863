@import "assets/scss/mixins.scss";

.videoBox {
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: row;
	flex: 1;
	align-self: stretch;
	cursor: pointer;
	border-radius: 8px;
	background: #ffffff;
	// -webkit-box-shadow: 0px 0px 2px 1px rgba(153, 153, 153, 0.3);
	// -moz-box-shadow: 0px 0px 2px 1px rgba(153, 153, 153, 0.3);
	// box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.15);
	// &:hover {
	// 	-webkit-box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.05),
	// 		0 1px 20px 0 rgba(0, 0, 0, 0.15);
	// 	-moz-box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.05),
	// 		0 1px 20px 0 rgba(0, 0, 0, 0.15);
	// 	box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.05),
	// 		0 1px 20px 0 rgba(0, 0, 0, 0.15);
	// }
	@include extra-small {
		flex-direction: column;
		align-items: center;
	}

	.positionedIconHolder{
		display: none;

		@include sm{
			display: block;
			position: absolute;
			top: 135px;
		}
	}

	.videoHolder {
		border-radius: inherit;
		position: relative;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 150px;
		height: 175px;
		flex-shrink: 0;
		margin: 20px;

		@include extra-small {
			width: 100%;
			margin: 0;
		}
		// .msWordIcon {
		// 	padding:6px;
		// 	width: 40px;
		// 	height: 40px;
		// 	object-fit: contain;
		// 	border: 1px solid var(--primaryColor);
		// 	background: white;
		// 	border-radius: 8px;
		// }
		.iconHolder {
			position: absolute;
			bottom: 1px;
			width: 100%;
			text-align: center;
			@include sm {
				// bottom: -25px;
				display: none;
			}
		}

		img {
			-webkit-transition: all 0.3s ease;
			-moz-transition: all 0.3s ease;
			-o-transition: all 0.3s ease;
			-ms-transition: all 0.3s ease;
			transition: all 0.3s ease;
			height: 100%;
			width: 100%;
			border-radius: 8px;
		}
	}
	.videoDetails {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		flex: 1;
		position: relative;
		overflow: hidden;
		padding: 20px;
		@include extra-small {
			width: 100%;
		}
		// > a > button {
		// 	border: 0;
		// 	position: absolute;
		// 	top: 15px;
		// 	right: 15px;

		// 	> span {
		// 		margin: 0;

		// 		&:not(:first-child) {
		// 			margin-left: 8px;
		// 		}
		// 	}
		// }

		.groupButtons {
			display: flex;
			flex-direction: row;
			margin-top: auto;
			gap: 5px;
			.viewButton {
				width: 160px;
				height: 40px;
				border-radius: 8px;
				span {
					font-size: 14px;
				}
				margin-top: auto;
				font-weight: 400;
				line-height: 20px;
				padding: 0;
				display: flex;
				justify-content: space-between;
				font-family: "Nunito-Regular";
				gap: 5px;
				border: 1px solid
					rgba(
						var(
							--selectedCommunityToJoinHeaderBackgroundRgb,
							var(--primaryColorRgb)
						),
						1
					);
				color: rgba(
					var(
						--selectedCommunityToJoinHeaderBackgroundRgb,
						var(--primaryColorRgb)
					),
					1
				);
				text-align: center;
				display: flex;
				justify-content: center;
				align-items: center;
				
			}
			.deleteButton {
				@extend .viewButton;
				width:50px;
				.deleteIcon {
					fill:#D57B8C;
					
				}
				&:hover {
					background-color: #DC3545;
					color: white;
					.deleteIcon {
						fill:white;
						
					}
					}
					
			}

		}


		.tagsContainer {
			padding-right: 45px;
			overflow: hidden;
			text-overflow: ellipsis;
			small {
				color: var(--primaryColor);
				font-size: 12px;
				line-height: 12px;
				font-weight: normal;
			}

			span {
				font-size: 14px;
				line-height: 21px;
				font-weight: normal;
				color: rgba(0, 0, 0, 0.7);
			}
			margin-bottom: 5px;
			.tag {
				cursor: pointer;
				&:not(:first-child) {
					margin-left: 5px;
				}
				font-family: "Nunito-Regular";
				font-style: normal;
				font-weight: 00;
				font-size: 14px;
				line-height: 20px;
			}
		}
	}

	span {
		display: inline-block;
		// color: #000;
		font-size: 16px;
		line-height: 23px;
		font-weight: normal;
		margin: 5px 0;
		word-break: break-all;
		img {
			margin: -3px 5px 0 0;
		}
	}

	h2 {
		line-height: 20px;
		color: #292f45;
		font-size: 20px;
		font-weight: 500;
		font-family: "Avenir Heavy", sans-serif;
		margin-bottom: 0;
		padding-right: 45px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	p {
		margin: 0;
		color: #000;
		font-size: 14px;
		line-height: 16px;
		font-weight: normal;
		padding: 0 8px 8px 8px;
	}

	.description {
		margin: 5px 0;
		color: #4d5a81;
		font-size: 14px;
		line-height: 18px;
		font-weight: 400;
		font-family: "Nunito-Regular";
		padding-right: 45px;
	}
}
// ! FIND SOLUTION ON FEED POSTS
.positionHack {
	padding-bottom: 100%;

	> iframe {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
	}
}
.checkBoxGroup {
	margin-top: auto !important;
	:global(.ant-checkbox) {
		display: none;
	}
	:global(.ant-checkbox + span) {
		display: flex;
		border: 1px solid var(--primaryColor) !important;
		border-radius: 25px !important;
		padding: 4px 15px !important;
		font-size: 13px !important;
		font-weight: 900;
		color: var(--primaryColor) !important;
		line-height: 20px !important;
		margin: 3px 0px !important;
	}
	:global(.ant-checkbox.ant-checkbox-checked + span) {
		background: var(--primaryColor) !important;
		color: #ffffff !important;
	}
}
.checkBoxGroupGhost {
	:global(.ant-checkbox) {
		display: none;
	}
	:global(.ant-checkbox + span) {
		display: flex;
		font-size: 13px !important;
		font-weight: 300;
		color: #444444 !important;
		line-height: 14px !important;
		margin: 3px 0px !important;
	}
}
.tagTitle {
	color: var(--announcementHeaderBackground);
	margin-bottom: 5px;
	line-height: 40px;
	&.required {
		&::before {
			display: inline-block;
			margin-right: 4px;
			color: #ff4d4f;
			font-size: 14px;
			line-height: 1;
			content: "*";
		}
	}
}
.headerTags {
	display: flex !important;
	flex: 1 !important;
	flex-direction: row !important;
	justify-content: space-between;
	> div:last-child {
		margin-bottom: 5px !important;
	}
}
.allNoneContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-family: "Avenir Heavy", sans-serif;

	div {
		max-height: 20px !important;
		min-height: 20px !important;
		margin-bottom: 0 !important;
	}
}
