.container {
	display: flex;
	flex-direction: column;
	flex: 1;
	.cardsContainer {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
	}
}
.horizontalLine {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #F0F0F0;
    padding: 0;
    margin: 0,
}