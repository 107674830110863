// these variant of Rgb colors are used everywhere where the opacity is need
// I kept the original colors too because in browser's dev tools there is no way to visualize the Rgb colors (these Rgb colors are made only by numbers, for example: 25, 14, 11 and is not possible to visualize them)
:root {
	--headerBackground: #126887;
	--headerBackgroundRgb: 18, 104, 135;
	--announcementHeaderBackground: #25bed8;
	--announcementHeaderBackgroundRgb: 37, 190, 216;
	--postHeaderBackground: #7f7f7f;
	--postHeaderBackground: 127, 127, 127;
	--primaryColor: #0688a9;
	--primaryColorRgb: 6, 136, 169;
	--secondaryColor: #000000;
	--secondaryColorRgb: 0, 0, 0;
	--white: #ffffff;
	--green: #00c569;
	--breakpointPhone: 500px;
	--groupSiderColor: #444;
	// --selectedCommunityToJoinHeaderBackground: undefined;
	// --selectedCommunityToJoinHeaderBackgroundRgb: undefined;
	// --selectedCommunityToJoinAnnouncementHeaderBackground:undefined;
	// --selectedCommunityToJoinAnnouncementHeaderBackgroundRgb: undefined;
	// --selectedCommunityToJoinPrimaryColor: undefined;
	// --selectedCommunityToJoinPrimaryColorRgb: undefined;
}
