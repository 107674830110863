@import "assets/scss/index.scss";

.title {
	font-family: "Avenir";
	font-weight: 500;
	font-size: 24px;
	line-height: 28px;
	text-align: center;
	margin: 50px 0;

	@include sm {
		font-size: 20px;
		line-height: 24px;
	}
}

.container {
	background: var(--white);
	padding: 100px 0px 20px;
	border-radius: 8px;
	position: relative;
	padding: 48px 32px;
}

@include sm {
	margin: 0%;
	padding: 0px;
	padding-top: 50px;

	.title {
		margin-bottom: 35px;
	}
}
