.container {
	background-color: #e4e4e47a;
	display: flex;
	flex-direction: column;
	flex: 1;
	width: inherit;
	margin-bottom: 31px;
	min-height: 80px;
	max-height: 140px;
	overflow: hidden;

	.imageContainer {
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		max-width: 160px;
		object-fit: contain;
	}

	.anchor {
		display: flex;
		flex: 1 1;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		width: inherit;

		.textContainer {
			align-self: center;
			padding: 18px 16px;
			line-height: 17px;
		}
	}

	.text {
		color: #000000;
		font-size: 16px;
		width: inherit;
		white-space: nowrap;
		overflow: hidden;
		white-space: normal;
	}

	.twoLinesEllipsis {
		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box !important;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		white-space: normal;
	}

	.url {
		color: #808080;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: bolder;
		margin-bottom: 4px;
	}

	.fadeText {
		font-weight: 200;
	}

	.imgPlaceholder {
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.oneLineEllipsis {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		text-transform: lowercase;
	}
}