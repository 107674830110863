@import "assets/scss/mixins.scss";


.absoluteBottomInfo {
	display: flex;
	flex-direction: column;
	gap: 5px;

	.info {
		padding: 0px 31px 0px 30px;

		svg {
			height: 16px;
			width: 16px;

			path {
				stroke: #ffffff;
			}
		}
	}

	.siderBottomInfo {
		padding: 0px 15px 21px 15px;

		.title {
			color: #FFF;

			text-align: center;
			font-family: 'Nunito-Medium';
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;

			.logo {
				color: #FFF;

				display: inline;
				text-align: center;
				font-family: 'Nunito-Medium';
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
		}

	}
}

.footerWrapper {
	display: flex;
	flex-direction: column;
	gap: 13px;



	.aboutUsWrapper {
		padding: 0px 31px;
		display: flex;
		gap: 9px;
		align-items: center;

		@include extra-small {
			padding: 0px 40px;
		}

		svg {
			height: 16px;
			width: 16px;

			path {
				stroke: #ffffff;
			}
		}

		span {
			color: #FFF;
			font-family: 'Nunito-Medium';
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}


	}

	.siderBottomInfo {
		padding: 0 30px;
		z-index: 1;

		@include sm {
			align-self: flex-start;
			padding: 0px 39px;
		}

		.title {
			color: var(--white);
			padding-bottom: 27px;
			font-family: "Nunito-Medium";
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;

			.logo {
				text-decoration: underline;
				color: #ffffff;
			}
		}

		.aboutSection {
			display: flex;
			flex-direction: column;
			font-size: 10px;

			a {
				color: var(--white);
				padding: 2px 0px;
			}
		}

		.row {
			display: flex;
			font-family: "Avenir Heavy", sans-serif;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 15px;
		}
	}
}