.container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 10px 25px;
	background: var(--white);
	border-radius: 8px;
	.input {
		flex: 4;
		margin-right: 20px;
		border-radius: 13px;
	}
	.button {
		flex: 1;
		border-radius: 8px;
		background: var(--primaryColor);
		color: var(--white);
		font-weight: bold;

		&:hover {
			outline: none !important;
			background: var(--announcementHeaderBackground) !important;
			color: var(--white) !important;
		}
		&:focus {
			outline: none !important;
			background: var(--announcementHeaderBackground) !important;
			color: var(--white) !important;
		}
		&:active {
			background-color: var(--announcementHeaderBackground) !important;
			color: #fff !important;
		}
	}
}
