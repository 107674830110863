@import 'assets/scss/mixins.scss';

.notificationsMenu {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 4px 12px;

	>div:first-child {
		margin-right: 4px;
		color: #191919;
	}
}

.userIcon {
	margin-left: 20px;
	display: flex;

	@include extra-small {
		margin-left: 11px;
	}

	:hover {
		cursor: pointer;
	}

	&.imgContainer {
		position: relative;

		.redBullet {
			position: absolute;
			height: 15px;
			width: 15px;
			text-align: center;
			background-color: #dc0000;
			border-radius: 50%;
			// top: -3px;
			right: -4px;
			color: #ffffff;
			line-height: 19px;
			font-size: 10px;
			font-weight: bold;
		}
	}
}

.switchPrimaryColor {}