.container {
	padding: 20px 10px;


	.userAvatarBox {
		:global(.avatar-uploader.ant-upload-picture-card-wrapper) {
			display: flex;
			flex-direction: row;
			justify-content: center;

			:global(.ant-upload.ant-upload-select.ant-upload-select-picture-card) {
				border-radius: 20%;
			}
		}

		:global(.ant-upload) {
			display: block;
			position: relative;
		}

		img {
			border-radius: 50%;
			width: 100%;
			height: 100%;
		}

		.imgOverlay {
			display: flex;
			opacity: 0;
			transition: 0.5s ease;
			background: rgba(105, 105, 105, 0.24);
			position: absolute;
			top: 8px;
			right: 0;
			bottom: 0;
			left: 9px;
			width: 105px;
			height: 105px;
			border-radius: 50%;

			&:hover {
				opacity: 1;
			}
		}

		&.hideAvatarBorder {
			:global(.ant-upload.ant-upload-select.ant-upload-select-picture-card) {
				border: none;
				background: transparent;
			}
		}

		.upload {


			.editIcon {
				color: #ffffff;
				font-size: 23px;
				padding-bottom: 10px;
				font-size: 18px;
				margin-top: 16px;
				padding: 4px;
				color: grey;
			}
		}


	}
}

:global(.custom-modal) {

	:global(.ant-modal-body) {
		width: 50%;
	}

	:global(.ant-btn) {
		border-color: var(--selectedCommunityToJoinPrimaryColor,
				var(--primaryColor));
		height: 40px;
		border-radius: 35px;
		color: var(--selectedCommunityToJoinPrimaryColor,
				var(--primaryColor));

		&:hover {
			background-color: rgba(var(--selectedCommunityToJoinPrimaryColorRgb,
						var(--primaryColorRgb)),
					$alpha: 0.8);
			border-color: #fff;
			color: #fff;
		}
	}

	:global(.ant-btn-primary) {
		background-color: var(--selectedCommunityToJoinPrimaryColor,
				var(--primaryColor));
		height: 40px;
		border-radius: 35px;
		color: #fff;
		border: var(--selectedCommunityToJoinPrimaryColor,
				var(--primaryColor));

		&:hover {
			background-color: rgba(var(--selectedCommunityToJoinPrimaryColorRgb,
						var(--primaryColorRgb)),
					$alpha: 0.8);
			border-color: #fff;
			color: #fff;
		}
	}

	:global(.ant-slider-handle) {
		border: solid 2px rgba($color: var(--selectedCommunityToJoinHeaderBackgroundRgb,
					var(--headerBackgroundRgb)),
				$alpha: 0.8);

		&:hover,
		&:active,
		&:focus {
			border: solid 2px var(--selectedCommunityToJoinPrimaryColor,
					var(--primaryColor));
			;
		}
	}

	:global(.ant-slider-track) {
		background-color: var(--selectedCommunityToJoinPrimaryColor,
				var(--primaryColor));

		&:hover,
		&:active,
		&:focus {
			background-color: var(--selectedCommunityToJoinPrimaryColor,
					var(--primaryColor));
			;
		}
	}

	:global(.ant-slider:hover .ant-slider-track) {
		background-color: var(--selectedCommunityToJoinPrimaryColor,
				var(--primaryColor));
	}

	:global(.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open)) {
		border-color: var(--selectedCommunityToJoinPrimaryColor,
				var(--primaryColor));
	}
}