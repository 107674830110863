.fullProfileBox {
	padding: 40px 30px;

	.topInfo {
		display: flex;

		.dropdownMenu {
			display: flex;
			flex: 1;

			:global(.ant-btn) {
				border: unset;
				box-shadow: none;

				:hover {
					color: var(--primaryColor);
				}
			}
		}

		.avatarHolder {
			position: relative;
			text-align: center;
			width: 175px;
			min-width: 175px;
			margin-right: 30px;
			text-align: left;

			.avatar {
				width: 175px;
				height: 175px;
				min-width: 175px;
				border-radius: 100%;
				overflow: hidden;
				margin-bottom: 15px;

				img {
					max-width: 100%;
				}
			}
		}

		.profileInfo {
			.location {
				margin-left: 10px;
			}

			h3 {
				font-weight: normal;
				font-size: 29px;
				line-height: 37px;
				margin: 0;
				color: #272727;

				strong {
					font-weight: 700;
				}
			}

			h4 {
				font-weight: normal;
				font-size: 15px;
				line-height: 37px;
				margin: 0;
				color: #7a8fa6;

				strong {
					font-weight: 700;
				}
			}

			.tagsContainer {
				padding-right: 45px;
				overflow: hidden;
				text-overflow: ellipsis;

				small {
					color: var(--primaryColor);
					font-size: 12px;
					line-height: 12px;
					font-weight: normal;
				}

				span {
					font-size: 14px;
					line-height: 21px;
					font-weight: normal;
					color: rgba(0, 0, 0, 0.7);
				}

				margin-bottom: 5px;

				.tag {
					cursor: pointer;

					&:not(:first-child) {
						margin-left: 5px;
					}

					font-family: "Nunito-Regular";
					font-style: normal;
					font-weight: 00;
					font-size: 14px;
					line-height: 20px;
				}
			}

			p {
				font-weight: normal;
				font-size: 12px;
				color: #7a8fa6;
			}
		}
	}

	.tags {
		text-align: center;
		margin-bottom: 30px;
	}

	h5 {
		font-weight: normal;
		font-size: 16px;
		margin: 0 0 20px;
	}

	:global {
		.BrainhubCarouselItem {
			width: 100px !important;
			max-width: 100px !important;
			min-width: 100px !important;
			margin-right: 10px;
			cursor: pointer;
			border-radius: 8px;
			overflow: hidden;
		}

		.ant-progress-text {
			font-weight: 700;
			font-size: 10px;
			color: #1b1b1b;
		}

		.ant-progress-inner {
			background-color: rgba(31, 190, 215, 0.23);
		}

		.ant-progress-bg {
			background-color: #2fbbf0;
			height: 8px !important;
		}

		.ant-tag {
			font-weight: normal;
			font-size: 12px;
			color: #2fbbf0;
			border-radius: 12px;
			line-height: 24px;
			background-color: #fff;
			border-color: #2fbbf0;
			margin-bottom: 8px;
		}
	}

	.posts {
		margin-top: 15px;

		.myMasonryGrid {
			display: flex;
			margin-left: -30px;
			/* gutter size offset */
			//width: auto;
		}

		.myMasonryGridColumn {
			padding-left: 30px;
			/* gutter size */
			background-clip: padding-box;
		}

		/* Style your items */
		.myMasonryGridColumn>div {
			/* change div to reference your elements you put in <Masonry> */
			//background: grey;
			margin-bottom: 30px;
			//height: 270px;
		}
	}

	.messagesBtn {
		cursor: pointer;
		background: var(--primaryColor);
		border-radius: 4px;
		height: 30px;
		line-height: 30px;
		align-items: center;
		justify-content: center;
		color: #fff;
		width: 135px;
		height: 40px;
		gap: 10px;
		display: flex;
		font-size: 14px;
		font-weight: 700;
		font-family: "Avenir Heavy", sans-serif;
	}
}

.menuContainer {
	margin-top: 4px !important;
	border-radius: 6px !important;
	max-width: 324px;

	.menuItem {
		white-space: pre-wrap !important;
		display: flex;
		flex-direction: row;

		.icon {
			display: flex;
			flex-direction: row;
			justify-content: center;
		}
	}

	color: "grey";

	.boldText {
		font-weight: bold;
	}

	.smallSizeText {
		font-size: 11px;
	}
}

.modal {
	.textarea {
		padding: 10px;
	}

	.subTitle {
		font-size: 14px;
		color: grey;
		padding: 4px 10px;
	}

	:global(.ant-modal-footer) {
		margin-top: 10px;
		border-top: unset;
	}
}

:global(.ant-modal-wrap) {
	position: absolute !important;
}