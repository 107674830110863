@import 'assets/scss/mixins.scss';
.uploadInput {
	display: flex;
	flex-direction: column;
	font-family: "Avenir Heavy", sans-serif;
	> span {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
.link {
    margin: 5px 0px;
	@include sm {
		margin: 0px
	}
}
