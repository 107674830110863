@import "assets/scss/mixins.scss";

.container {
	display: flex;
	flex-direction: column;
	flex: 1;
	border-radius: 20px;
	padding: 28px;
	background-color: #ffffff;
	margin-top: 16px;

	@include for-phone-only {
		padding: 24px;
		flex-shrink: 0;
	}

	.headerText {
		font-size: 22px;
		@include for-phone-only {
			font-size: 16px;
		}
		text-transform: capitalize;
		color: var(--headerBackground);
		font-family: "Avenir Heavy", sans-serif;
	}

	.flexWrapper {
		display: flex;
		flex-direction: row;
		flex: 1;
		gap: 32px;

		@include for-phone-only {
			flex-direction: column-reverse;
			gap: 16px;
		}
	}

	.boldBlackText {
		color: #03142d;
		font-size: 24px;
		font-family: "Avenir Heavy", sans-serif;
	}

	.flexOneBox {
		display: flex;
		flex-direction: row;
		flex: 1;
		max-width: 50%;
		width: 100%;

		@include for-phone-only {
			max-width: 100%;
		}
	}

	.smallText {
		font-size: 12px;
	}

	.greyColor {
		color: #78878b;
	}

	.bold {
		font-family: "Avenir Heavy", sans-serif;
	}

	.flexRowCenter {
		display: flex;
		flex-direction: row;
		flex: 1;
		justify-content: center;
		align-items: center;
		margin-top: 30px;
		gap: 16px;
	}

	.flexColumn {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	.tagsWrapper {
		display: flex;
		flex-direction: row;
		flex: 1;
		.tagItem {
			margin-right: 4px;
			color: var(--headerBackground);
			font-family: "Avenir-Heavy", sans-serif;
			font-weight: bold;
		}
	}

	.textWithEnters {
		white-space: pre-wrap;
	}

	.button {
		color: var(--secondaryColor);
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 25px 15px;
		gap: 8px;
		border-radius: 10px;
		font-family: Avenir-Heavy;

		&:hover {
			color: var(--headerBackground);
			border-color: var(--headerBackground);

			path {
				fill: white !important;
			}
		}

		svg {
			width: 18px;
			height: 18px;
			path {
				fill: var(--headerBackground);
			}
		}

		&:focus {
			color: var(--primaryColor);
		}
		span {
			margin: 0px 4px;
		}
	}

	.marginBottom16 {
		margin-bottom: 16px;
	}
}
