@import "assets/scss/index.scss";

.container {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	border-radius: 15px;
	-webkit-box-shadow: 0px 0px 10px 2px rgba(153, 153, 153, 0.3);
	-moz-box-shadow: 0px 0px 10px 2px rgba(153, 153, 153, 0.3);
	box-shadow: 0px 0px 10px 2px rgba(153, 153, 153, 0.3);
	background-color: #ffffff;

	&:hover {
		-webkit-box-shadow: 0px 0px 10px 2px rgba(153, 153, 153, 0.5);
		-moz-box-shadow: 0px 0px 10px 2px rgba(153, 153, 153, 0.5);
		box-shadow: 0px 0px 10px 2px rgba(153, 153, 153, 0.5);
	}
}

.image {
	object-fit: cover;
}

.cover {
	height: 190px;
	display: flex;
	position: relative;
	background: rgb(255, 255, 255);
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;

	img {
		width: 100%;
		height: 100%;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
	}

	.absoluteFlexRowWrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		position: absolute;
		bottom: 12px;
		width: 100%;
		padding: 0px 20px;
		align-items: center;

		.boldWhiteText {
			color: #ffffff;
			font-family: "Avenir-Heavy", sans-serif;
			font-size: 22px;
			font-weight: bold;
		}

		z-index: 2;
	}

	.absoluteGradientBottomToTop {
		background: rgb(0, 0, 0);
		background: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0.49) 0%,
			transparent 24%
		);
		position: absolute;
		bottom: 0px;
		width: 100%;
		height: 100%;
	}
}

.content {
	padding: 16px;
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	font-size: 15px;

	.title {
		font-size: 16px;
		font-weight: bold;
		color: #000000;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	:global(.ant-divider-horizontal) {
		margin: 24px 0px 10px 0px;
		margin-left: -8px;
		margin-right: -8px;
		width: auto;
	}

	.description {
		margin: 10px 0px 4px;
		max-height: 330px;
		flex-grow: 1;
		word-break: break-word;

		:global(.trackY) {
			width: 5px !important;
			right: -5px !important;
		}

		:global(.ScrollbarsCustom.trackYVisible) {
			padding-right: 2px !important;
		}
	}

	.seemorebtn {
		color: #00bdd9;
		font-family: Nunito-SemiBold;

		&:hover {
			cursor: pointer;
		}
	}

	.contact {
		font-weight: bold;
		font-family: "Avenir-Heavy", sans-serif;
		color: #78878b;
		margin-left: 4px;
	}

	.tagsContainer {
		display: flex;
		flex-direction: row;
		margin-bottom: 12px;
		flex-wrap: wrap;

		div {
			display: flex;
			color: var(--primaryColor);
			font-size: 12px;
			line-height: 12px;
			font-weight: normal;
			margin-right: 6px;
		}

		span {
			font-size: 14px;
			line-height: 21px;
			font-weight: normal;
			color: rgba(0, 0, 0, 0.7);
		}

		.tag {
			cursor: pointer;

			&:not(:first-child) {
				margin-right: 4px;
			}

			font-family: "Nunito-Regular";
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
		}
	}
}

.boldTxtSecondaryColor {
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	font-family: "Avenir-Heavy", sans-serif;
	color: var(--secondaryColor);
	text-transform: capitalize;
	overflow: hidden;
	text-overflow: ellipsis;
	word-wrap: break-word;
	overflow-wrap: break-word;
	word-break: break-word;

	@include extra-small {
		font-size: 18px;
		line-height: 24px;
	}
}

.fadeGreyColor {
	color: #78878b;
}

.flexRowJustifyContentStart {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	font-family: "Avenir-Heavy", sans-serif;
}

.smallGreyBtn {
	border: 1px solid #cdcdcd;
	border-radius: 8px;
	color: grey;
	height: 24px;
	line-height: 12px;
	padding: 4px 10px;

	&:hover {
		background-color: rgb(233, 233, 233);
		border-radius: 8px;
	}
}

.mainBtn {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2px 16px;
	border-radius: 6px;
	border: 1px var(--secondaryColor) solid;
	color: var(--secondaryColor);
	width: auto;

	@include for-desktop-only {
		width: 160px;
	}

	@include for-phone-only {
		margin: 4px 0px;
		font-size: 12px;
		width: 100%;
		line-height: 32px;
	}

	svg {
		fill: currentColor;
		margin-right: 6px;
	}

	&:hover {
		background-color: #f1f1f1;
	}
}

.secondaryColorTxt {
	color: var(--secondaryColor);
}

.darkGreyColorBtn {
	color: #494949;
	border: 1px #494949 solid;
}

.flexWrapper {
	display: flex;
	flex-direction: row;

	@include for-phone-only {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

.borderBottomRadius15 {
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
}

.flexCenter {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex: 1;
	padding: 2px 8px;
	align-items: center;
	line-height: 23px;
	gap: 8px;
	font-family: Avenir-Heavy;

	&:hover {
		background-color: rgba(#fff, 0.3);
	}

	svg {
		fill: #fff;
	}
}
