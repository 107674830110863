@import "assets/scss/index.scss";

.evenetWrapper {
}

@include sm {
	.evenetWrapper {
		height: 100%;

		@include extra-small {
			section {
				display: none;
			}
		}

		.eventDetails {
			max-width: 100% !important;
		}
	}
}

.eventInfoBox {
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 18px;

	@include extra-small {
		gap: 16px;
	}

	.eventButtonandEvent {
		background: #fff;
		padding: 32px 24px 36px 24px;
		border-radius: 8px;

		@include extra-small {
			padding: 32px 24px;
		}

		.addToCalendarBtn {
			display: flex;
			flex-direction: row;
			font-family: "Avenir Heavy", sans-serif;
			border: 1px solid var(--primaryColor);
			border-radius: 8px;
			height: 40px;
			align-items: center;
			margin-top: 16px;
			color: var(--primaryColor);
			width: 160px;

			:global(.ant-menu-horizontal) {
				border-bottom: none !important;
			}

			:global(.ant-menu-submenu-title) {
				color: var(--primaryColor) !important;
				font-family: "Nunito-Regular";
			}

			:global(.ant-menu-submenu-title .ant-menu-item-icon + span) {
				margin-left: 4px;
			}

			:global(.ant-menu-submenu::after) {
				border-bottom: 0px !important;
			}

			:global(.react-add-to-calendar__button) {
				background-color: var(--primaryColor) !important;
				padding: 8px;
				color: #ffffff;
				font-weight: bold;
				white-space: nowrap;
			}

			:global(.react-add-to-calendar__dropdown) {
				-webkit-box-shadow: 7px 6px 19px -9px rgba(0, 0, 0, 0.75);
				-moz-box-shadow: 7px 6px 19px -9px rgba(0, 0, 0, 0.75);
				box-shadow: 7px 6px 19px -9px rgba(0, 0, 0, 0.75);
				font-family: "Avenir Heavy", sans-serif;
				z-index: 1;
				width: 100%;

				ul {
					padding: 10px;
				}

				ul li a {
					color: var(--announcementHeaderBackground);
					font-weight: bold;
					display: inline-block;
					margin: 4px 0px;
					width: 100%;

					&:hover {
						border-radius: 4px;
						color: white;
						background: var(--announcementHeaderBackground);
					}
				}

				background-color: #ffffff;
				border-radius: 10px;
			}
		}
	}

	.eventInfo {
		display: flex;
		flex-direction: column;

		.eventImg {
			max-width: 300px;

			img {
				width: 100%;
			}
		}

		.eventTitleInfo {
			background: #fff;
			padding: 23px 24px;
			align-items: center;
			border-radius: 8px;
			max-width: 100%;
			white-space: pre;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;

			@include extra-small {
				padding: 14px 15px;
			}

			> h3 {
				margin: 0px;
				line-height: 100%;

				@include sm {
					margin: 0px 20px 0px 0px;
					line-height: 20px;
					font-size: 18px;
					font-family: "Nunito-Bold";
					font-weight: 700;
				}

				font-size: 24px;
				font-family: "Avenir-Medium";
				word-break: break-word;
				white-space: normal;
			}

			> p {
				font-size: 14px;
				line-height: 100%;
				font-family: "Avenir-Medium";
				color: var(--primaryColor);

				@include extra-small {
					line-height: 20px;
				}
			}

			> div {
				max-width: 100%;
				overflow: hidden;
				white-space: pre-line;
			}

			> span {
				line-height: 20px;
				font-weight: normal;

				img {
					display: inline-block;
					margin-top: -2px;
					margin-right: 5px;
				}
			}
		}
	}

	.relatedEvents {
		@include extra-small {
			.relatedEventsWidth {
				margin: 0px !important;
				padding: 0px !important;
				flex: 1;
				width: 100%;
				max-width: 100% !important;
			}
		}

		h3 {
			margin-top: 36px;
			margin-bottom: 23px;

			font-size: 24px;
			font-family: "Avenir-Medium";

			@include extra-small {
				font-size: 16px;
				margin-top: 36px;
				margin-bottom: 19px;
			}
		}
	}
}

.listOfEvents {
	width: 33.33%;

	:global(.ant-col-8) {
		max-width: 100%;
	}

	@include extra-small {
		display: none;
	}
}

.calendarProfileInfoBox {
	background: #fff;
	border-radius: 8px;
	padding: 30px;

	@include sm {
		padding: 0px;
	}

	.profileInfoBox {
		padding: 45px 30px;
		background: #f7f6f3;
		border-radius: 8px;
		text-align: center;
		margin-bottom: 30px;

		.avatarHolder {
			border-radius: 100%;
			overflow: hidden;
			width: 110px;
			height: 110px;
			display: inline-block;
			border: 4px solid #fff;
			margin-bottom: 10px;

			img {
				width: 100%;
			}
		}

		h4 {
			margin: 0;
			font-size: 16px;
			line-height: 16px;
			font-weight: 500;
			color: #272727;

			strong {
				font-weight: 700;
			}
		}

		p {
			margin: 0 0 20px;
			font-size: 12px;
			line-height: 20px;
			font-weight: 700;
			color: #7a8fa6;
		}

		a {
			font-size: 12px;
			line-height: 20px;
			font-weight: normal;
			color: var(--primaryColor);

			&:hover {
				text-decoration: underline;
			}
		}

		span {
			font-size: 12px;
			line-height: 20px;
			font-weight: normal;
			color: #131313;
		}
	}

	.mapInfoBox {
		padding: 30px;
		background: #f7f6f3;
		border-radius: 8px;

		h5 {
			margin: 0;
			font-size: 20px;
			line-height: 25px;
			font-weight: 700;
			color: #272727;
			padding: 0;
		}

		> div {
			margin: 0;
			padding: 0;
			position: relative;

			p {
				font-size: 16px;
				line-height: 25px;
				font-weight: normal;
				color: #272727;
				overflow: hidden;
				display: flex;
				flex-wrap: wrap;
				line-break: anywhere;
			}
		}

		iframe {
			border: 0;
			width: 100%;
			height: 373px;
			display: block;
			margin-top: 25px;
		}
	}
}

.eventLocationAndCalendar {
	.location {
		path {
			stroke: var(--primaryColor);
		}
	}
	margin-top: 18px;
	display: flex;
	gap: 5px;
}

.backBackButtonContainer {
	font-family: "Nunito-Medium";
	color: var(--primaryColor);
	display: flex;
	cursor: pointer;
	font-size: 16px;

	@include extra-small {
		display: none;
	}

	> :first-child {
		cursor: pointer;
	}

	svg {
		path {
			stroke: var(--primaryColor);
		}
	}

	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	span {
		font-family: "Nunito-Medium";
		margin-left: 9px;
		font-size: 16px;
	}
}

.backBackButtonContainerEvent {
	font-family: "Nunito-Medium";
	color: var(--primaryColor);
	display: flex;
	cursor: pointer;
	font-size: 16px;
	display: none !important;

	@include extra-small {
		display: flex !important;
	}

	> :first-child {
		cursor: pointer;
	}

	svg {
		path {
			stroke: var(--primaryColor);
		}
	}

	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	span {
		font-family: "Nunito-Medium";
		margin-left: 9px;
		font-size: 16px;
	}
}
