@import "assets/scss/mixins.scss";

.authenticationBox {
	width: 100%;
	max-width: 456px;
	margin-top: 16px;

	.container {
		background: var(--white);
		border-radius: 8px;
		position: relative;
		padding: 48px 32px;
	}

	:global(.ant-btn:not(.ant-btn-primary):hover) {
		border-color: var(
			--selectedCommunityToJoinPrimaryColor,
			var(--primaryColor)
		) !important;
		background-color: var(
			--selectedCommunityToJoinPrimaryColor,
			var(--primaryColor)
		) !important;

		color: white !important;
	}

	@include sm {
		margin: 0%;
		padding: 0px;

		.container {
			padding: 24px 16px;
		}
	}
}
