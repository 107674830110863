@import "assets/scss/mixins.scss";

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;

	// padding: 16px 0px;
	@include sm {
		// padding: 10px 20px;
	}

	.logoSection {
		display: flex;
		margin: 20px;

		.imageStyle {
			max-height: 120px;
			background-color: #fff;
			border-radius: 50%;
			width: 120px;
			-webkit-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.1);
			box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.1);
		}
	}

	.header {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-family: "Avenir-Heavy", sans-serif;
		margin-bottom: 38px;
		margin-top: 15px;

		.headerTitle {
			font-size: 18px;
			margin-bottom: 10px;
			font-weight: 700;
			text-transform: uppercase;
		}

		.headerSubTitle {
			text-align: center;
			font-family: "Avenir Light", sans-serif;
			font-size: 14px;
		}
	}

	.flexColumnDirection {
		display: flex;
		flex-direction: column;
		gap: 8px;
		// padding: 18px 10px;
		// background-color: #fff;
		// border-radius: 2px;
		width: 100%;
		// margin-bottom: 23px;
	}

	.flexRowDirection {
		display: flex;
		flex-direction: row;
	}

	.amountRow {
		color: var(--selectedCommunityToJoinPrimaryColor,
				var(--primaryColor)) !important;
		background-color: rgba(var(--selectedCommunityToJoinPrimaryColorRgb,
					var(--primaryColorRgb)),
				$alpha: 0.3);
		padding: 8px 12px;
		border-radius: 8px;
		margin-top: 8px;
		font-weight: 700;
		font-size: 16px;
		font-family: "Nunito-Bold";
	}

	.selectSection {
		width: 100%;
		margin: 10px;
		display: flex;
		justify-content: center;
		margin-bottom: 46px;
	}

	.flexRowSpaceBetween {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		@include sm {
			flex-direction: column;

			button {
				margin-bottom: 4px;
			}
		}
	}

	.mainBtn {
		border-radius: 8px;

		&:hover {
			border-color: var(--selectedCommunityToJoinHeaderBackground);
			color: var(--selectedCommunityToJoinHeaderBackground);
		}

		&.activeButton {
			background: var(--selectedCommunityToJoinHeaderBackground);
			color: #fff;
		}
	}

	.flexRow .flex2 {
		display: flex;
		flex: 2;
		color: var(--selectedCommunityToJoinPrimaryColor,
				var(--primaryColor));
	}

	.flex1 {
		display: flex;
		flex: 1;
		color: #4D5A81;

	}

	.priceFlex1 {
		display: flex;
		flex: 1;


	}

	.heavyText {
		// font-size: 16px;
		font-family: "Nunito-Regular", sans-serif;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;

	}
}

input,
button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
	border-style: none;
}

.cardContainer {
	// background-color: #fff;
	// padding: 18px;
	width: 100%;
	max-height: 300px;
	position: relative;
	// box-shadow: 0px 0px 9px 1px var(--selectedCommunityToJoinHeaderBackground);
	border-radius: 8px;
}

@keyframes fade {
	from {
		opacity: 0;
		transform: scale3D(0.95, 0.95, 0.95);
	}

	to {
		opacity: 1;
		transform: scale3D(1, 1, 1);
	}
}

@keyframes void-animation-out {

	0%,
	to {
		opacity: 1;
	}
}