@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css?family=Heebo:400,700,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,600,700,900&display=swap");
@import "./mixins.scss";

@font-face {
	font-family: "Inter-Regular";
	src: url("../fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Inter-Bold";
	src: url("../fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Inter-Light";
	src: url("../fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Inter-Medium";
	src: url("../fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Avenir Light";
	src: local("Avenir Light"),
		url("../fonts/AvenirLight.ttf") format("truetype");
}

@font-face {
	font-family: "Avenir Heavy";
	src: local("Avenir Heavy"),
		url("../fonts/AvenirHeavy.ttf") format("truetype");
}

@font-face {
	font-family: "Avenir-Medium";
	src: url("../fonts/Avenir-Medium.eot") format("truetype");
}

@font-face {
	font-family: "Nunito-Regular";
	src: url("../fonts/Nunito-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Nunito-Bold";
	src: url("../fonts/Nunito-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Nunito-ExtraBold";
	src: url("../fonts/Nunito-ExtraBold.ttf") format("truetype");
}

@font-face {
	font-family: "Nunito-Black";
	src: url("../fonts/Nunito-Black.ttf") format("truetype");
}

@font-face {
	font-family: "Nunito-ExtraLight";
	src: url("../fonts/Nunito-ExtraLight.ttf") format("truetype");
}

@font-face {
	font-family: "Nunito-Light";
	src: url("../fonts/Nunito-Light.ttf") format("truetype");
}

@font-face {
	font-family: "Nunito-SemiBold";
	src: url("../fonts/Nunito-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "Nunito-ExtraBoldItalic";
	src: url("../fonts/Nunito-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
	font-family: "Nunito-BlackItalic";
	src: url("../fonts/Nunito-BlackItalic.ttf") format("truetype");
}

@font-face {
	font-family: "Nunito-BoldItalic";
	src: url("../fonts/Nunito-BoldItalic.ttf") format("truetype");
}

@font-face {
	font-family: "Nunito-ExtraLightItalic";
	src: url("../fonts/Nunito-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
	font-family: "Nunito-Italic";
	src: url("../fonts/Nunito-Italic.ttf") format("truetype");
}

@font-face {
	font-family: "Nunito-LightItalic";
	src: url("../fonts/Nunito-LightItalic.ttf") format("truetype");
}

@font-face {
	font-family: "Nunito-SemiBoldItalic";
	src: url("../fonts/Nunito-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
	font-family: "Nunito-Medium";
	src: url("../fonts/Nunito-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "Nunito-MediumItalic";
	src: url("../fonts/Nunito-MediumItalic.ttf") format("truetype");
}

body {
	margin: 0;
	min-width: 600px;
	overflow: hidden;
	font-family: "Nunito-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@include sm {
		min-width: unset !important;
	}

	// button {
	// 	font-family: "Avenir Heavy", -apple-system, BlinkMacSystemFont,
	// 		"Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
	// 		"Droid Sans", "Helvetica Neue", sans-serif;
	// }
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

#root {
	display: flex;
	flex: 1;
	flex-direction: column;
	height: 100%;
}

.ant-input:focus {
	border-color: var(--primaryColor);
}

.ant-modal {
	width: 100% !important;
	max-width: 750px !important;
	margin-top: 30px;
}

.ant-modal-body {
	padding: 0;
}

.customRow {
	flex: 2;
	// overflow: hidden;

	.ant-row {
		height: 100%;
	}

	.ant-col {
		padding-bottom: 0 !important;
	}
}

.ScrollbarsCustom {
	//position: unset !important;

	.ScrollbarsCustom-Wrapper {
		height: 100%;
		position: relative !important;
	}

	.ScrollbarsCustom-TrackY {
		top: 0 !important;
		bottom: 0 !important;
		right: -18px !important;
		background: #fff;
		width: 4px !important;
		height: 100% !important;

		.ScrollbarsCustom-ThumbY {
			background: #dcd9d3;
			border-radius: 3px;
			//height: 90px !important;
		}
	}
}

.ant-layout-sider-children {
	display: flex;
	flex-direction: column;
	overflow-y: auto;

	@include sm {
		overflow: auto;
	}
}

.ant-layout {
	max-width: 100%;

	.ant-layout-header {
		background: #fff;
		color: #000;
	}

	.ant-layout-sider {
		overflow: auto;

		@include extra-small {
			position: absolute;
		}

		.ant-menu,
		.ant-menu-sub,
		.ant-menu-submenu > .ant-menu {
			color: #fff;
			font-size: 14px;
			font-weight: 700;
		}

		.ant-menu-submenu {
			.ant-menu-sub {
				.ant-menu-item {
					padding: 0 30px 0 55px !important;
					line-height: 24px;
					height: 25px;
					font-size: 12px;
					font-weight: normal;
				}
			}
		}

		.ant-menu-dark {
			// margin-top: 20px;

			.ant-menu-item,
			.ant-menu-submenu-title {
				color: #fff !important;
				position: relative;

				a {
					color: #fff !important;
				}

				span {
					display: inline-block;
					width: 100%;

					img {
						margin-right: 10px;
					}

					em {
						display: block;
						width: 100%;
						height: 1px;
						background-color: #2481a2;
						float: left;
					}

					sup {
						width: 20px;
						height: 20px;
						line-height: 20px;
						text-align: center;
						background-color: var(--primaryColor);
						display: inline-block;
						top: 0;
						bottom: 0;
						right: 13px;
						margin: auto;
						border-radius: 100%;
						color: #fff;
						position: absolute;
						font-size: 13px;
						font-weight: 700;
					}
				}
			}

			.ant-menu-inline {
				&.ant-menu-sub {
					-webkit-box-shadow: none;
					-moz-box-shadow: none;
					-ms-box-shadow: none;
					-o-box-shadow: none;
					box-shadow: none;
				}
			}
		}

		.ant-menu-submenu-selected,
		.ant-menu-item-group-title,
		.ant-menu-item:hover,
		.ant-menu-submenu-title:hover {
			a {
				color: #fff !important;
			}
		}

		.ant-menu-submenu-title {
			margin: 0;
			padding: 0 30px !important;

			img {
				margin-right: 7px;
			}

			.ant-menu-submenu-arrow {
				display: none !important;
			}
		}

		.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
			color: #fff;
			background: transparent;
			padding: 0px 15px !important;

			@include extra-small {
				padding: 0px 22px !important;
			}

			span {
				background: var(--primaryColor);
				border-radius: 8px;

				span {
					padding: 0px 13px;
				}

				em {
					display: none;
				}
			}
		}

		.ant-menu-inline {
			border: none;

			.ant-menu-item {
				margin: 0;
				padding: 0 28px !important;
				min-height: 50px;
				max-height: 50px;

				&:hover {
					padding: 0 15px !important;
				}

				span {
					&:hover {
						background-color: rgba(
							var(--primaryColorRgb),
							0.5
						) !important;
						height: 50px;
						display: flex;
						width: 100%;

						span {
							padding: 0px 13px;
						}

						background: var(--primaryColor);
						border-radius: 8px;
					}

					a {
						width: 100%;
					}

					em {
						display: none;
					}
				}
			}
		}

		.ant-menu-inline .ant-menu-item,
		.ant-menu-inline .ant-menu-submenu-title {
			width: 100%;

			span {
				em {
					margin-top: -1px;
				}
			}
		}
	}
}

.ant-layout-content {
	overflow: auto;
	flex-direction: column;
	display: flex;
	flex-grow: 1;
	background: #f4f5f7;
}

:global(.group-transitions-enter) {
	flex: 0;
}

:global(.group-transitions-enter-active) {
	flex: 1;
	transition: flex 1300ms ease-in;
}

:global(.group-transitions-exit) {
	flex: 1;
}

:global(.group-transitions-exit-active) {
	flex: 0;
	transition: flex 1300ms ease-out;
}

.emptyPlaceholder {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: center;
	margin: 30px 0px;
	font-size: 20px;
	color: #5f5f5f;
}

.formItemCenteredNoBorderBtn {
	:global {
		.ant-form-item-control-input-content {
			border: none !important;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}
	}
}

.formItemCenteredNoMarginBtn {
	:global {
		.ant-form-item-control-input-content {
			border: none !important;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin: 0px;
		}
	}
}

.onboardingPrimaryBtnSpaceBetweenTxt {
	display: flex !important;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.onboardingSecondaryBtn {
	&:global(.ant-btn) {
		color: grey;
		background: transparent;
		border: none;
		display: flex;
		justify-content: flex-start;
		box-shadow: none;
		align-items: center;

		.onboardingSecondartBtnIcon {
			line-height: 11px !important;
		}

		&:hover,
		&:focus,
		&:active {
			color: rgba(
				$color:
					var(
						--selectedCommunityToJoinHeaderBackgroundRgb,
						var(--headerBackgroundRgb)
					),
				$alpha: 0.8
			);
		}
	}
}

.primaryColorTextBtn {
	&:global(.ant-btn) {
		background-color: #fff;
		height: 40px;
		border-radius: 35px;
		color: var(--selectedCommunityToJoinPrimaryColor, var(--primaryColor));

		&:hover {
			background-color: rgba($color: #fff, $alpha: 0.8);
			border-color: #fff;
			color: var(
				--selectedCommunityToJoinPrimaryColor,
				var(--primaryColor)
			);
		}
	}
}

.primaryColorBackgroundBtn {
	&:global(.ant-btn) {
		background-color: var(
			--selectedCommunityToJoinPrimaryColor,
			var(--primaryColor)
		);
		height: 40px;
		border-radius: 35px;
		color: #fff;

		&:hover {
			background-color: rgba(
				var(
					--selectedCommunityToJoinPrimaryColorRgb,
					var(--primaryColorRgb)
				),
				$alpha: 0.8
			);
			border-color: #fff;
			color: #fff;
		}
	}
}

.solidPrimaryColorBackgroundBtn {
	&:global(.ant-btn:not(.ant-btn-primary):hover) {
		// border-color: rgba(var(--primaryColorRgb), 1) !important;
		border-color: #fff !important;
		// color: rgba(var(--primaryColorRgb), 1) !important;
	}

	&:global(.ant-btn) {
		background-color: var(
			--selectedCommunityToJoinPrimaryColor,
			var(--primaryColor)
		);
		height: 40px;
		border-radius: 8px;
		color: #fff;
		width: 100%;
		height: 40px;

		&:focus,
		&:hover {
			background-color: rgba(
				var(
					--selectedCommunityToJoinPrimaryColorRgb,
					var(--primaryColorRgb)
				),
				$alpha: 0.8
			);
			border-color: #fff;
			color: #fff;
		}
	}
}

.outlinePrimaryColorBackgroundBtn {
	&:global(.ant-btn:not(.ant-btn-primary):hover) {
		// border-color: rgba(var(--primaryColorRgb), 1) !important;
		border-color: #fff !important;
		// color: rgba(var(--primaryColorRgb), 1) !important;
	}

	&:global(.ant-btn) {
		background-color: white;
		height: 40px;
		border-radius: 8px;
		color: var(--selectedCommunityToJoinPrimaryColor, var(--primaryColor));
		width: 100%;
		height: 40px;
		border-color: var(
			--selectedCommunityToJoinPrimaryColor,
			var(--primaryColor)
		);

		&:focus,
		&:hover {
			background-color: rgba(
				var(
					--selectedCommunityToJoinPrimaryColorRgb,
					var(--primaryColorRgb)
				),
				$alpha: 1
			);
			border-color: #fff;
			color: #fff;
		}
	}
}

.outlineGrayBackgroundBtn {
	&:global(.ant-btn:not(.ant-btn-primary):hover) {
		// border-color: rgba(var(--primaryColorRgb), 1) !important;
		border-color: #fff !important;
		// color: rgba(var(--primaryColorRgb), 1) !important;
	}

	&:global(.ant-btn) {
		background-color: white;
		height: 40px;
		border-radius: 8px;
		color: #4d5a81;
		width: 100%;
		height: 40px;
		border-color: #4d5a81;
	}
}

.solidDangerColorBackgroundBtn {
	&:global(.ant-btn:not(.ant-btn-primary):hover) {
		border-color: red !important;
		background-color: red !important;
		// color: rgba(var(--primaryColorRgb), 1) !important;
	}

	&:global(.ant-btn) {
		background-color: red;
		height: 40px;
		border-radius: 8px;
		color: #fff;
		width: 100%;
		height: 40px;

		&:hover,
		&:focus {
			background-color: red;
			border-color: #fff;
			color: #fff;
		}
	}
}

.headerBackgroundColor {
	background-color: var(--headerBackground);
}

.cursorPointer {
	cursor: pointer;
}

.greyBackgroundColor {
	background-color: grey;
}

:global(.ant-dropdown) {
	z-index: 2 !important;
}

:global(.ant-input-affix-wrapper) {
	border-radius: 8px !important;
}

.ant-select-selector {
	border-radius: 8px !important;
}

.ant-form-item-explain {
	margin-top: 4px;
}

:global(.ant-form-item-label > label) {
	font-family: "Avenir";
}

:global(.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover) {
	font-family: "Nunito-Regular";
}

:global(.ant-select-item-option-content) {
	font-family: "Nunito-Regular";
}

:global(.ant-select-single.ant-select-show-arrow .ant-select-selection-item) {
	font-family: "Nunito-Regular";
}

:global(.ant-form-item .ant-form-item-label > label) {
	font-family: "Avenir-Heavy";
}

:global(.ant-upload.ant-upload-drag) {
	background-color: white !important;
	border-radius: 8px !important;
}

:global(.ant-upload.ant-upload-drag p.ant-upload-text) {
	@include sm {
		font-size: 14px !important;
	}
}

:global(.ant-upload.ant-upload-drag .ant-upload-drag-container) {
	padding: 5px;
}

:global(.ant-upload.ant-upload-drag p.ant-upload-hint) {
	@include sm {
		font-size: 12px !important;
	}
}

:global(.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
		.ant-select-selector) {
	border-color: black !important;
}

:global(.ant-select:not(.ant-select-disabled):hover .ant-select-selector) {
	border-color: var(--primaryColor) !important;
	box-shadow: 1px var(--primaryColor);
}

////this is date picker
:global(.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
		.ant-select-selector) {
	border-color: var(--primaryColor) !important;
	box-shadow: var(--primaryColor) !important;
}

:global(.ant-select:not(.ant-select-disabled):hover .ant-select-selector) {
	border-color: var(--primaryColor) !important;
	box-shadow: none;
}

:global(.ant-picker-focused) {
	border-color: var(--primaryColor) !important;
	border-right-width: 1px;
	box-shadow: var(--primaryColor) !important;
}

:global(.ant-picker-cell-in-view.ant-picker-cell-today
		.ant-picker-cell-inner::before) {
	border: 1px solid var(--primaryColor) !important;
}

:global(.ant-picker-cell-in-view.ant-picker-cell-selected
		.ant-picker-cell-inner) {
	background: var(--primaryColor) !important;
}

:global(.ant-input:hover) {
	border-color: var(--primaryColor) !important;
}

:global(.ant-input:focus) {
	box-shadow: var(--primaryColor) !important;
}

:global(.ant-select-item-option-selected:not(.ant-select-item-option-disabled)) {
	background-color: var(--primaryColor) !important;
	color: white !important;
}

:global(.ant-picker:hover) {
	border-color: var(--primaryColor) !important;
}

:global(.ant-picker) {
	width: 100%;

	border-radius: 8px !important;
}

:global(.ant-picker-input) {
	width: 100% !important;
}

:global(.ant-form-item-control-input-content) {
	border-radius: 8px !important;
}

:global(.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover) {
	border-color: var(--primaryColor) !important;
}

:global(.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover) {
	border-color: var(--primaryColor) !important;
}

:global(.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus) {
	border-color: var(--primaryColor) !important;
	box-shadow: var(--primaryColor) !important;
}

:global(.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus-within) {
	border-color: var(--primaryColor) !important;
	box-shadow: var(--primaryColor) !important;
}

//button
:global(.styles_container__rm-AF .styles_primaryButton__LLcvC button:hover) {
	background-color: rgba(var(--primaryColorRgb), 0.8) !important;
	border-color: rgba(var(--primaryColorRgb), 0.8) !important;
}

:global(.styles_container__CnGSJ .styles_primaryButton__NZlr3 button:hover) {
	background-color: rgba(var(--primaryColorRgb), 0.8) !important;
	border-color: rgba(var(--primaryColorRgb), 0.8) !important;
}

:global(.ant-btn:not(.ant-btn-primary):hover) {
	border-color: rgba(var(--primaryColorRgb), 1) !important;
	background-color: rgba(var(--primaryColorRgb), 0.8) !important;

	// border-color: #fff !important;
	color: white !important;
}

:global(.styles_AppBar__JudwJ
		.styles_rightSide__wy1qp
		.styles_messagesBtn__8gZ7m:active) {
	background-color: rgba(var(--primaryColorRgb), 0.8) !important;
}

:global {
	.ant-switch.ant-switch-checked {
		background-color: #12b28c !important;
	}

	.ant-switch {
		background-color: #edf0f4 !important;
		width: 48px !important;
		height: 24px !important;
	}

	.ant-switch-handle::before {
		background-color: white !important;
		box-shadow: 0px 2px 8px 0px #00000029 !important;
		width: 18px !important;
		height: 17px !important;
	}

	.ant-switch-handle {
		top: 3.5px !important;
	}
}

// side bar tool tip
:global(.ant-tooltip-content) {
	display: none;
}

:global(.styles_form__p6eNH .styles_link__YvwpR) {
	border-radius: 8px !important;
}

:global(.styles_form__p6eNH .styles_datePicker__h-zEV) {
	border-radius: 8px !important;
}

:global(.styles_link__KS0Wo) {
	border-radius: 8px !important;
}

//input
:global(.ant-input) {
	border-radius: 8px !important;
}

:global(.ant-input-affix-wrapper) {
	height: 40px !important;
}

:global(.ant-input) {
	height: 40px !important;
}

:global(.ant-input-affix-wrapper > .ant-input) {
	height: 30px !important;
	align-self: center;
}

:global(.media-directory-tree .ant-tree-list .ant-tree-treenode) {
	padding: 20px !important;
}

:global(.media-directory-tree .ant-tree-treenode .ant-tree-indent) {
	order: 1;
}

:global(.media-directory-tree .ant-tree-treenode .ant-tree-switcher) {
	order: 3;
}

:global(.media-directory-tree
		.ant-tree-treenode
		.ant-tree-node-content-wrapper) {
	order: 2;
	display: flex;
	gap: 25px;
}

:global(.media-directory-tree .ant-tree-title) {
	font-family: "Nunito-Medium";
	font-size: 16px;
	line-height: 20px;
	color: #32363d;
}

:global(.media-directory-tree .ant-tree-switcher-icon) {
	transform: 0.3s;
}

:global(.media-directory-tree
		.ant-tree-treenode-switcher-open
		.ant-tree-switcher-icon) {
	transform: rotate(180deg);
}

:global(.media-directory-tree .ant-tree-treenode-selected::before) {
	background-color: #f0f2f5 !important;
}

:global(.media-directory-tree
		.ant-tree.ant-tree-directory
		.ant-tree-treenode
		.ant-tree-node-content-wrapper.ant-tree-node-selected) {
	color: #32363d;
}

:global(.media-directory-tree .ant-tree-switcher) {
	height: 18px !important;
	width: 18px !important;

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 30px white inset !important;
	}
}
