@import 'assets/scss/mixins.scss';

.form {
	display: flex;
	flex-direction: column;
	padding: 20px 50px 20px 10px;
	background: #ffffff;
	border-radius: 8px;
	flex: 1;

	@include sm {
		padding: 0px;

		.mainEditProfileLayout {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 10px;
		}

		.formLayout {
			flex: 1;
			max-width: 100%;
			padding: 0px 10px 0px
		}
	}

	.twoColumnsPerRow {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		:global(.ant-form-item-control-input) {
			min-height: unset !important;
		}
	}

	.email {
		color: grey;
	}

	textarea {
		resize: none;
		height: auto !important;
	}

	:global(.ant-form-item) {
		margin-bottom: 10px;
	}

	:global(.ant-col .ant-form-item-label) {
		padding: 0px;
	}

	.submitButton {
		display: flex;
		flex-direction: row;
		margin-top: 25px;
		justify-content: center;

		@include sm {
			justify-content: center;

			:global(.ant-form-item) {
				width: 100%
			}

			;
		}

		button {
			background: var(--primaryColor);
			border: 1px solid;
			border-radius: 25px;
			font-weight: bold;
			padding: 0px 20px;
			line-height: 0px;
			min-width: 200px;

			@include sm {
				width: 100%;
				border-radius: 16px;

			}
		}
	}
}