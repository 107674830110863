@import "assets/scss/mixins.scss";

.communitiesWrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 562px;

	@include sm {
		width: unset;
		padding: 0px 8px;
		align-self: stretch;
		overflow-x: hidden;
	}

	.header {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 40px 0px 16px 0px;

		@include sm {
			width: unset;
			padding: 0px 8px;
			align-self: stretch;
		}

		.title {
			color: var(--selectedCommunityToJoinHeaderBackground);
			font-family: "Avenir-Medium", sans-serif;
			font-style: normal;
			// font-weight: 900;
			font-size: 24px;
			line-height: 33px;
			text-align: center;
		}

		.subTitle {
			font-style: normal;
			font-family: 'Nunito-Regular';
			// font-weight: 300;
			font-size: 14px;
			line-height: 20px;
			text-align: center;
			color: #4D5A81;
		}
	}

	.fullWidthInputField {
		display: flex;
		flex-direction: row;
		flex: 1;
	}

	:global(.ant-layout-content) {
		overflow: auto;
	}
}