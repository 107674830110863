.container {
	display: flex;
	flex-direction: column;
	align-self: center;
	flex: 1;
	max-width: 700px;
	width: 100%;

	.tagFiltersContainer {
		margin-bottom: 15px;
		position: relative;
		min-height: 70px;
	}

	.newContent {
		position: absolute;
		z-index: 1;
		left: 0;
		right: 0;
		top: -15px;
		margin: auto;
		display: flex;
		flex-direction: column;
		height: auto;
		text-align: center;
		align-items: center;
		border-radius: 34px;
		line-height: 15px;
		padding-left: 30px;
		margin-bottom: 10px;
		width: 100%;

		>span:last-child {
			font-size: 10px;
			font-weight: 300;
		}
	}
}