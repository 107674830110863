@import "assets/scss/mixins.scss";

.searchBarMultiple {
	background-color: #fff;
	padding: 15px 14px;
	border-radius: 8px;

	@include extra-small {
		background-color: transparent;
		padding: 0px;
	}

	.search {
		flex-grow: 1;

		:global(.ant-input-affix-wrapper) {
			border: 1px solid #f3f3f3;
		}

		:global(.ant-select:not(.ant-select-customize-input)
				.ant-select-selector) {
			border: 1px solid #f3f3f3;
		}

		@include extra-small {
			flex-grow: 0;
			flex: 1;
			min-width: 38%;

			:global(.ant-form .ant-form-item) {
				max-width: max-content;
			}

			:global(.ant-input-affix-wrapper) {
				border: none;
			}

			:global(.ant-select:not(.ant-select-customize-input)
					.ant-select-selector) {
				border: none;
			}
		}
	}

	.searchButton {
		max-width: 120px;
	}

	:global(.ant-select-single
			.ant-select-selector
			.ant-select-selection-placeholder) {
		height: 42px;
		color: #4d5a81;
		font-size: 14px;
		font-family: "Nunito-Regular";
		font-weight: 400;
	}

	::placeholder {
		color: #4d5a81;
		font-size: 14px;
		font-family: "Nunito-Regular";
		font-weight: 400;
	}

	:global {
		.ant-form-item {
			margin-bottom: 0;
		}

		.ant-input {
			font-weight: normal;
			font-size: 16px;
			color: #131313;
			border-radius: 8px;
			padding: 13px 20px;
			line-height: 24px;
			height: 50px;

			&::placeholder {
				color: #4d5a81;
				font-size: 14px;
				font-family: "Nunito-Regular";
				font-weight: 400;
			}

			&:-ms-input-placeholder {
				color: #4d5a81;
				font-size: 14px;
				font-family: "Nunito-Regular";
				font-weight: 400;
			}

			&::-ms-input-placeholder {
				color: #4d5a81;
				font-size: 14px;
				font-family: "Nunito-Regular";
				font-weight: 400;
			}
		}

		.ant-select-selector {
			height: 50px !important;
			border-radius: 8px !important;

			input {
				height: 50px !important;
			}

			.ant-select-selection-placeholder {
				&::after {
					height: 50px;
					width: 100%;
				}
			}

			.ant-select-selection-placeholder {
				line-height: 48px;

				&::after {
					display: none;
				}
			}

			.ant-select-selection-item {
				line-height: 48px;
			}
		}

		.ant-input-affix-wrapper {
			padding: 0px 10px;
			height: auto;
			border-radius: 8px;

			.ant-input {
				height: 48px;
			}

			span {
				font-size: 20px;
			}
		}

		.ant-btn {
			font-weight: 700;
			font-size: 16px;
			border: none;
			color: #fff;
			background-color: var(--primaryColor);
			border-radius: 8px;
			// padding: 13px 20px;
			gap: 4px;
			line-height: 24px;
			height: 40px;
			text-align: center;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 120px;

			@include extra-small {
				width: 40px;
			}

			div {
				@include extra-small {
					visibility: hidden;
					height: 0px;
					width: 0px;
				}
			}

			svg {
				height: 20px;
				width: 20px;

				@include extra-small {
					min-height: 20px;
					min-width: 20px;
					margin-left: 5px;
				}
			}

			&:hover {
				background-color: var(
					--announcementHeaderBackground
				) !important;
				color: #fff !important;
			}

			&:active {
				background-color: var(
					--announcementHeaderBackground
				) !important;
				color: #fff !important;
			}

			&:focus {
				background-color: var(
					--announcementHeaderBackground
				) !important;
				color: #fff !important;
			}
		}

		.ant-select-selection-placeholder {
			line-height: 46px !important;
			font-size: 16px;
		}
	}
}
