@import "assets/scss/mixins.scss";
@import "assets/scss/index.scss";

.topTitle {
	font-family: "Avenir-Medium";
	font-weight: 500;
	font-size: 24px;
	line-height: 28px;
	text-align: center;
	margin: 50px 0;

	@include sm {
		font-size: 20px;
		line-height: 24px;
	}

	span {
		font-weight: bold;
	}
}

.form {
	:global(.ant-form-item-control-input-content) {
		border: 1px solid
			rgba(
				$color:
					var(
						--selectedCommunityToJoinPrimaryColorRgb,
						(145, 145, 150)
					),
				$alpha: 0.3
			);
	}

	.userConsentCheckboxes {
		> label {
			margin-bottom: 4px;
		}
	}

	hr {
		border: 0;
		border-top: 1px solid #ececec;
	}

	form {
		text-align: left;
	}

	:global(.ant-form-item .ant-form-item-label) {
		padding: 0px;
	}

	:global(.ant-form-item) {
		margin-bottom: 20px;
	}

	.termsAndConditionsLabel {
		font-family: "Nunito-Regular";
		font-size: 12px;
		margin-top: 20px;
		line-height: 16px;
		text-align: start;
		color: #4d5a81;

		> span {
			margin-left: 5px;
		}
	}

	.userConsentCheckboxes {
		> label {
			margin-bottom: 4px;
		}

		.checkboxContent {
			display: flex;
			flex-direction: row;
			margin-top: 30px;

			.checkbox {
				display: flex;

				:global(.ant-checkbox-checked .ant-checkbox-inner) {
					background-color: var(
						--selectedCommunityToJoinPrimaryColor,
						var(--primaryColor)
					);
					border-color: var(
						--selectedCommunityToJoinPrimaryColor,
						var(--primaryColor)
					);
				}
			}

			.textLeftAligned {
				font-family: "Nunito-Regular";
				font-size: 14px;
				text-align: left;
				color: #4d5a81;
			}
		}
	}

	.checkboxContent {
		display: flex;
		flex-direction: row;
		margin-top: 30px;

		.checkbox {
			display: flex;

			:global(.ant-checkbox-checked .ant-checkbox-inner) {
				background-color: var(
					--selectedCommunityToJoinPrimaryColor,
					var(--primaryColor)
				);
				border-color: var(
					--selectedCommunityToJoinPrimaryColor,
					var(--primaryColor)
				);
			}

			// :global(.ant-checkbox) {
			// 	align-self: center;
			// }
		}
	}

	.signupHeaderContainer {
		max-width: 552px;

		.title {
			margin-bottom: 24px;
			text-align: center;
			color: #292f45;
			font-size: 24px;
			line-height: 32px;
			font-family: "Avenir-Medium";

			.communityName {
				color: var(
					--selectedCommunityToJoinPrimaryColor,
					var(--primaryColor)
				);
			}
		}

		.subtitle {
			font-family: Nunito-Regular;
			font-style: normal;
			font-weight: 300;
			font-size: 18px;
			line-height: 25px;
		}
	}

	.underlinedTextWithPrimaryColor {
		color: var(--selectedCommunityToJoinPrimaryColor, var(--primaryColor));
		cursor: pointer;
		font-size: 12px;
		line-height: 16px;
		font-family: "Nunito-Regular";
	}

	.underlinedTextWithPrimaryColorLink {
		color: var(--selectedCommunityToJoinPrimaryColor, var(--primaryColor));
		cursor: pointer;
		font-size: 14px;
		line-height: 28px;
		font-weight: 700;
		font-family: "Nunito-Bold";
	}
}
