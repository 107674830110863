@import '../../../../assets/scss/mixins.scss';
.threadsSection {
	.flexBoxSpaceBetween {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 20px 30px 0px;
		@include sm {
			.ThreadsText{
			font-size: 18px;
				
			}
			padding: 10px 15px 0px;
		}
		.messageIcon {
			display: flex;
			svg {
				width: 26px;
				color: var(--primaryColor);
				background: transparent;
				border-color: var(--primaryColor);
				&:hover {
					cursor: pointer;
					color: rgba(var(--primaryColorRgb), 0.7);
				}
			}
		}
	}
	background: #fff;
	border-radius: 8px;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;

	:global(.ScrollbarsCustom-Content) {
		padding: 0px  15px!important;
	}
	h3 {
		margin: 0;
		font-size: 26px;
		line-height: 38px;
		font-weight: 700;
		color: #131313;
		span {
			color: rgba(19, 19, 19, 0.7);
			display: inline-block;
			margin-left: 15px;
		}
	}
}
