@import "assets/scss/index.scss";

.container {
	display: flex;
	flex-direction: row;
	padding: 15px 24px;
	justify-content: space-between;

	@include extra-small {
		padding: 12px 13px;
	}

	.monthPicker {
		display: flex;
		height: 16px;

		.monthLabel {
			font-family: 'Avenir-Medium';
			font-style: normal;
			font-size: 24px;
			/* identical to box height, or 24px */


			color: #292F45;

			@include extra-small {
				font-size: 16px;
				line-height: 100%;
				align-self: center;


			}
		}

		:global(.ant-picker) {
			border: none !important;

			width: 0px;

			@include extra-small {
				height: 15px;
			}

			:global(.ant-picker-input > input) {
				visibility: hidden;
			}

			span {
				margin: 0px;
			}

			svg {
				height: 10px;
				width: 10px;

				@include extra-small {

					height: 16px;
					width: 16px;
				}
			}
		}


	}

	.yearPicker {
		display: flex;
		height: 16px;
		margin-right: 4px;

		.yearLabel {
			font-family: 'Avenir-Medium';
			font-style: normal;
			font-size: 24px;
			color: #292F45;

			@include extra-small {
				font-size: 16px;
				line-height: 100%;
				align-self: center;

			}
		}

		:global(.ant-picker) {
			border: none !important;
			width: 0px;

			@include extra-small {
				height: 15px;

			}

			:global(.ant-picker-input > input) {
				visibility: hidden;
			}

			span {
				margin: 0px;
			}

			svg {
				height: 10px;
				width: 10px;

				@include extra-small {

					height: 16px;
					width: 16px;
				}
			}

		}
	}

	.headerText {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.buttonsContainer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;

		.horizontalyButtonsContainer {
			display: flex;
			flex-direction: row;
			gap: 10px;
			text-align: center;
			width: 0px;
			overflow: hidden;

			@include extra-small {
				display: none;
			}

			.btn {
				// border: 1px solid #00000040;
				background-color: rgba(44, 27, 115, 0.1);
				display: flex;
				line-height: 20px;
				width: 89px;
				height: 40px;
				padding: 0px 8px;
				border-radius: 8px;
				align-items: center;
				justify-content: center;
				text-transform: capitalize;
				font-size: 14px;
				color: var(--primaryColor);
				// &:first-child {
				// 	border-top-left-radius: 4px;
				// 	border-bottom-left-radius: 4px;
				// }

				// &:last-child {
				// 	border-right: 1px solid #00000040;
				// 	border-top-right-radius: 4px;
				// 	border-bottom-right-radius: 4px;
				// }
				///this is hover and can be used when we have design for the week and day
				&:hover {
					cursor: pointer;
					border-radius: 8px;
					background-color: var(--primaryColor);
					color: white;
					text-transform: capitalize;

				}

				// &:hover {
				// 	cursor: not-allowed;
				// }

				&.activeBtn {
					border-radius: 8px;
					cursor: pointer;
					background-color: var(--primaryColor);
					text-transform: capitalize;
					color: white;

				}
			}

			.disabledBtn {
				display: flex;
				line-height: 20px;
				width: 89px;
				height: 40px;
				padding: 0px 8px;
				border-radius: 8px;
				align-items: center;
				justify-content: center;
				text-transform: capitalize;
				font-size: 14px;
				background-color: #cccccc;
				color: #666666;

				&:hover {
					cursor: not-allowed;
				}
			}
		}

		font-family: "Avenir ",
		sans-serif;
	}
}