@import "assets/scss/mixins.scss";

.menuContainer {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-top: 135px !important;
	overflow: auto;

	@include extra-small {
		display: none;
	}

	&:global(.ant-menu-inline-collapsed) {
		li {
			padding: 0 30px !important;
			height: 50px;
			line-height: 50px;
			margin: 0px !important;
		}
	}

	&:global(.ant-menu.ant-menu-dark) {
		background: unset;
	}

	.menuItem {
		height: 50px;

		.defaultLinks {
			text-transform: capitalize;
			display: flex;
			align-items: center;
			gap: 22px;

			&:hover {
				padding: 0px 15px;
			}
		}

		.linkItem {
			text-transform: capitalize;
			display: flex !important;
			align-items: center;
			color: #fff;
			font-family: "Nunito-Medium";
			font-size: 16px;
			font-style: normal;
			text-overflow: ellipsis;
			font-weight: 500;
			line-height: 50px;
			gap: 22px;
			min-height: 50px;
			overflow: hidden;

			svg {
				height: 24px;
				width: 24px;
			}
		}

		@include extra-small {
			min-height: 0px;
		}

		@include sm {
			height: 50px !important;

			.linkItem {
				height: 50px;
			}
		}

		&:global(.ant-menu-item-selected) {
			height: 50px;
		}
	}
}

.mobileMenuContainer {
	display: flex;
	flex-direction: column;
	flex: 1;
	display: none;

	@include extra-small {
		display: flex;
	}

	&:global(.ant-menu-inline-collapsed) {
		li {
			padding: 0 35px !important;
			// display: flex;
			// align-self: center;
			height: 50px;
			line-height: 50px;
			margin: 0px !important;
		}
	}

	&:global(.ant-menu.ant-menu-dark) {
		background: unset;
	}

	.menuItem {
		height: 50px;

		.defaultLinks {
			text-transform: capitalize;
			display: flex;
			align-items: center;
			gap: 22px;

			&:hover {
				padding: 0px 13px;
			}
		}

		.linkItem {
			text-transform: capitalize;
			display: flex !important;
			align-items: center;
			color: #fff;
			font-family: "Nunito-Medium";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 50px;
			gap: 22px;
			min-height: 50px;

			svg {
				height: 24px;
				width: 24px;
			}
		}

		@include extra-small {
			min-height: 0px;
		}

		@include sm {
			height: 50px !important;

			.linkItem {
				height: 50px;
			}
		}

		&:global(.ant-menu-item-selected) {
			height: 50px;
		}
	}
}

.absoluteMenuContainer {
	display: flex;
	flex-direction: column;
	flex: 1;

	&:global(.ant-menu-inline-collapsed) {
		li {
			padding: 0px !important;
			align-self: center;
			height: 50px;
			line-height: 50px;
			margin: 0px !important;
		}

		span {
			align-self: center;
		}
	}

	&:global(.ant-menu.ant-menu-dark) {
		background: unset;
	}

	.menuItem {
		height: 50px;

		.defaultLinks {
			text-transform: capitalize;
			display: flex;
			align-items: center;
			gap: 22px;

			&:hover {
				padding: 0px 13px;
			}
		}

		.linkItem {
			text-transform: capitalize;
			display: flex !important;
			align-items: center;
			color: #fff;
			font-family: "Nunito-Medium";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 50px;
			gap: 22px;
			min-height: 50px;

			svg {
				height: 24px;
				width: 24px;
			}
		}

		@include extra-small {
			min-height: 0px;
		}

		@include sm {
			height: 50px !important;

			.linkItem {
				height: 50px;
			}
		}

		&:global(.ant-menu-item-selected) {
			height: 50px;
			span {
				min-width: 50px;
			}
		}
	}
}
