@import '../../../assets/scss/mixins.scss';
// .container {
// 	display: flex;
// 	flex-direction: column;
// 	background-color: #ffffff;

// 	margin: 16px;
// 	.mainSection {
// 		display: flex;
// 		flex-direction: column;
// 		margin: 12px 0px;
// 		padding: 10px 20px;
// 		border-bottom: 1px solid #cdcdcd;
//         background-color: #ffffff;
// 		.smallText {
// 			color: grey;
// 		}
// 		.textGreenButton {
// 			font-family: "Avenir-Heavy", sans-serif;
// 			color: rgb(80, 129, 6);
// 		}
// 		.headerTitle {
// 			font-family: "Avenir-Heavy", sans-serif;
// 			font-size: 22px;
// 			flex-grow: 1;
// 			margin-bottom: 14px;
// 		}
// 		.productSection {
// 			display: flex;
// 			flex-direction: row;
// 			margin: 18px 0px;
// 			.description {
// 				display: flex;
// 				flex-direction: row;
// 				flex: 1;
// 			}
// 			.icon {
// 				margin-right: 22px;
// 			}
// 			.title {
// 				font-family: "Avenir-Heavy", sans-serif;
// 			}
// 		}
// 	}
// 	.table {
// 		th {
// 			background-color: #ffffff;
// 		}
// 	}
// }

.mainContainer {
	background-color: #fff;
	padding: 20px;
}

.headerContainer {
	display: flex;
	flex-direction: row;
	justify-self: space-between;
	align-items: center;
	margin-bottom: 10;
	@include sm {
		flex-direction: column;
		align-items: baseline;
		
	}
	.titleContainer {
		display: flex;
		justify-content: 'flex-start';
		flex-direction: column;
		flex:1;
		
	}
}

.headerTitle {
	font-family: "Avenir-Heavy", sans-serif;
	font-size: 22px;
	flex-grow: 1;
	@include sm {
		font-size: 18px;
		margin-bottom: 10px;
	}
	
}
.planTitle {
	padding-left: 15px;
	padding-right: 15px;
	@include sm {
	padding: 0px
}
}
.modalContainer {
	:global(.ant-modal-header) {
		border-top-left-radius: 8px !important;
		border-top-right-radius: 8px !important;
	}
	:global(.ant-modal-content) {
		border-radius: 8px !important;
	}
	:global(.ant-modal-footer) {
		padding-right: 26px;
	}
}

.modalContent {
	padding: 20px;
	font-family: "Avenir Light", sans-serif;
	text-align: center;
}
