@import "assets/scss/mixins.scss";

.container {
	display: flex;
	flex-direction: row;
	background: var(--white);
	padding: 0 20px;
	border-radius: 8px;
	//margin-top: 20px;
	min-height: 70px;
	position: absolute;
	left: 0;
	right: 0;
	@include sm {
		padding: 0px 0px 0px 10px !important
	}

	.arrow {
		&:nth-of-type(1) {
			margin-right: 20px;
		}
		&:nth-of-type(2) {
			margin-left: 20px;
		}
		display: flex;
		flex-direction: column;
		align-self: center;
		flex-direction: column;
		align-self: center;
		font-size: 20px;
		color: var(--primaryColor);
		//margin: 0px 5px;
	}

	.checkboxGroup {
		display: flex;
		overflow-x: auto;
		flex-direction: row;
		flex-grow: 1;
		&.checkboxGroup::-webkit-scrollbar {
			display: none; /* Safari and Chrome */
		}
		&.checkboxGroup {
			-ms-overflow-style: none; /* Internet Explorer 10+ */
			scrollbar-width: none; /* Firefox */
		}
		:global(.ant-checkbox-group) {
			display: flex;
			align-items: center;
		}
		:global(.ant-checkbox) {
			display: none;
		}
		:global(.ant-checkbox + span) {
			display: flex;
			border: 1px solid var(--primaryColor) !important;
			border-radius: 20px !important;
			padding: 5px 20px !important;
			font-size: 13px !important;
			font-weight: bold;
			color: var(--primaryColor) !important;
			line-height: 20px !important;
			margin: 3px 0px !important;
			white-space: nowrap;
		}
		:global(.ant-checkbox.ant-checkbox-checked + span) {
			background: var(--primaryColor) !important;
			color: var(--white) !important;
		}
		&.accents {
			font-family: "Avenir Light", sans-serif;
		}
	}

	@include extra-small {
		padding: 0 15px;
	}
}
.activeState {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 18px;
	width: 100%;

	@include sm {
		font-size: 14px;
	}

	strong {
		margin-left: 8px;
	}
	button {
		margin-right: auto;
		border-width: 1px;
		border-style: solid;
		border-color: var(--primaryColor);
		cursor: pointer;
		outline: none;
		color: var(--primaryColor);
		border-radius: 20px;
		padding: 0px 15px;
		background: transparent;
		margin-left: 8px;
	
		height: 30px;
		font-size: 14px;
	
		font-weight: 700
	}
}

