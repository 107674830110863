.offerBox {
	display: inline-block;
	width: 100%;

	.imageHolder {
		border-radius: 4px;
		position: relative;
		overflow: hidden;
		margin-bottom: 20px;

		img {
			width: 100%;
			-webkit-transition: all 0.3s ease;
			-moz-transition: all 0.3s ease;
			-o-transition: all 0.3s ease;
			-ms-transition: all 0.3s ease;
			transition: all 0.3s ease;
		}
	}

	&:hover {
		.imageHolder {
			img {
				-webkit-transform: scale(1.1);
				-moz-transform: scale(1.1);
				-ms-transform: scale(1.1);
				-o-transform: scale(1.1);
				transform: scale(1.1);
			}
		}
	}

	p {
		margin: 0;
		color: #000;
		font-size: 16px;
		line-height: 23px;
		font-weight: normal;
	}

	span {
		display: inline-block;
		color: #00c569;
		font-size: 16px;
		line-height: 23px;
		font-weight: normal;
	}
}
