@import "assets/scss/mixins.scss";

.communityWrapper {
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: flex-start;
	justify-content: flex-start;
	width: 700px;
	margin-top: 60px;
	gap: 8px;

	@include for-tablet-portait-only {
		width: 550px;
		padding: 0px 8px;
		align-self: stretch;
	}

	@include for-phone-only {
		width: unset;
		padding: 0px 8px;
		align-self: stretch;
	}

	.tagsArea {
		width: 100%;
		//height: 100%;
		max-width: 700px;
		background: #fff;
		padding: 20px 20px;
		border-radius: 8px;
		// margin: auto;
		position: relative;
		display: flex;
		flex-direction: column;
		flex: 1;

		>div {
			padding-left: 0;
			padding-right: 0;
		}

		.label {
			font-weight: bold;
			margin-top: 30px;
			margin-bottom: 2px;
		}

		.checkboxGroup {
			:global(.ant-checkbox-group) {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
			}

			:global(.ant-checkbox) {
				display: none;
			}

			>div.singleCheckbox {
				display: inline-flex;
				border: 1px solid var(--selectedCommunityToJoinHeaderBackground) !important;
				border-radius: 6px !important;
				padding: 5px 20px !important;
				font-size: 13px !important;
				font-weight: bold;
				color: var(--selectedCommunityToJoinHeaderBackground) !important;
				line-height: 20px !important;
				margin: 3px 8px 3px 0px !important;
				white-space: nowrap;
				cursor: pointer;

				&.selected {
					background: var(--selectedCommunityToJoinHeaderBackground) !important;
					color: #ffffff !important;
				}

				&.disabled {
					color: #cdcdcd !important;
					border: 1px solid #cdcdcd !important;
					cursor: default;
				}
			}

			:global(.ant-checkbox + span) {
				display: flex;
				border: 1px solid var(--selectedCommunityToJoinHeaderBackground) !important;
				border-radius: 6px !important;
				padding: 5px 20px !important;
				font-size: 13px !important;
				font-weight: bold;
				color: var(--selectedCommunityToJoinHeaderBackground) !important;
				line-height: 20px !important;
				margin: 3px 0px !important;
				white-space: nowrap;
			}

			:global(.ant-checkbox.ant-checkbox-checked + span) {
				background: var(--selectedCommunityToJoinHeaderBackground) !important;
				color: #ffffff !important;
			}

			&.accents {
				font-family: "Avenir Heavy", sans-serif;
			}
		}
	}

	.btnContainer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 20px 20px;
		width: 100%;

		.antBtn {
			font-weight: 700;
			font-size: 14px;
			border: none;
			color: #fff;
			// background-color: #1FBED7;
			background-color: #000;
			border-radius: 25px;
			padding: 13px 20px;
			line-height: 24px;
			height: 50px;
			width: 200px;
			text-align: center;
			margin: 20px 0 0 auto;
			margin: 0px 10px;

			&:disabled {
				opacity: 0.6;
				pointer-events: none;
			}

			&:hover,
			&:focus,
			&:active {
				background-color: var(--selectedCommunityToJoinHeaderBackground);
				color: #fff;
			}

			@include sm {
				max-width: 140px;
				max-height: 35px;
				line-height: 5px;
			}
		}
	}

	.subTitle {
		margin-bottom: 8px;

		&.errorText {
			color: red;
			font-size: 12px;
		}

		&.greyText {
			color: grey;
			font-size: 12px;
		}
	}
}