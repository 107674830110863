.avatar {
	width: 54px;
	height: 54px;
	min-width: 54px;
	border-radius: 100%;
	overflow: hidden;
	margin-right: 13px;

	img {
		max-width: 100%;
	}
}
.moreComments {
	text-align: center;

	a {
		text-decoration: underline;
		font-size: 12px;
		line-height: 12px;
		display: inline-block;
		color: var(--primaryColor);
	}
}

.counterContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 8px;
	color: var(--primaryColor);
	.commentsNavigation {
		display: flex;
		:nth-child(2) {
			padding: 0px 4px;
		}
		:hover.underline {
			cursor: pointer;
			text-decoration: underline;
		}
	}

	// > div:first-child:not(:last-child):hover {
	// 	text-decoration: underline;
	// 	cursor: pointer;
	// }

	// > div:last-child {
	// 	color: #ccc;
	// }
}
.noComments {
	text-align: center;
	font-style: italic;
	color: #ccc;
	font-size: 12px;
}
