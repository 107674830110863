@import "assets/scss/";
@import "../styles.module.scss";

.container {
	:global(.ant-select:not(.ant-select-disabled):hover .ant-select-selector) {
		border-color: #d9d9d9 !important;
	}

	:global(.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector) {
		border-color: #d9d9d9 !important;
		box-shadow: #d9d9d9 !important;
	}

	.autocomplete {
		width: 100%;





		:global(.ant-select-selector) {

			border-radius: 4px;
			background-color: transparent;


		}

		;





		// .select {

		// 	&:hover>div {
		// 		border: 1px solid #d9d9d9 !important;
		// 	}
		// }
	}

	.subTitle {
		margin-bottom: 8px;

		&.errorText {
			color: red;
			font-size: 12px;
		}

		&.greyText {
			color: grey;
			font-size: 12px;
		}
	}
}

:global(.ant-select-single .ant-select-selector .ant-select-selection-placeholder) {
	width: 200px;
	align-self: center;
	color: #9ca1ba;
	font-family: "Nunito-Regular";
	font-size: 14px;
}

:global(.ant-select-single:not(.ant-select-customize-input) .ant-select-selector) {
	height: 40px !important;
}

:global(.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input) {
	height: 40px !important;
}

// <div class="ant-select ant-select-auto-complete styles_autocomplete__7IFyh ant-select-focused ant-select-single ant-select-show-search"><span aria-live="polite" style="width: 0px; height: 0px; position: absolute; overflow: hidden; opacity: 0;">NEPAL</span><div class="ant-select-selector"><span class="ant-select-selection-search"><input type="search" autocomplete="off" class="ant-select-selection-search-input" role="combobox" aria-haspopup="listbox" aria-owns="rc_select_1_list" aria-autocomplete="list" aria-controls="rc_select_1_list" aria-activedescendant="rc_select_1_list_0" value="NEPAL" id="rc_select_1" aria-expanded="false"></span></div></div>