@import "assets/scss/mixins.scss";

.buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.primaryButton {
    padding: 5px;
    margin-bottom: 20px;

    @include sm {
        width: 100%;
    }

    button {
        padding: 0px 36px;
        font-size: 22px;
        line-height: 16px;
        background-color: var(--primaryColor);
        border-color: var(--primaryColor);
        font-weight: bold;
        height: 36px;

        @include sm {
            width: 100%;

            font-size: 14px;
        }

        &:hover,
        &:focus,
        &:active {
            border-color: rgba($color: var(--primaryColorRgb),
                    $alpha: 0.8);
            background-color: rgba($color: var(--primaryColorRgb),
                    $alpha: 0.8);
            color: #fff;
        }
    }
}

.activeButton {
    @include sm {
        width: 100%;
    }

    button {
        padding: 0px 36px;
        font-size: 22px;
        line-height: 16px;
        font-weight: bold;
        height: 36px;

        @include sm {
            width: 100%;
            font-size: 14px;
        }

        border-color: rgba($color: var(--primaryColorRgb),
            $alpha: 0.8);
        background-color: rgba($color: var(--primaryColorRgb),
            $alpha: 0.8);
        color: #fff;
    }
}

.detailsInput {
    padding: 5px;
    min-height: 200px;

    div {
        >textarea {
            border-radius: 8px;
            border: 1px solid #d9d9d9 !important;
        }
    }

    div:first-of-type {
        font-size: 14px;
        min-height: 63px;
        font-weight: normal;
        background-color: rgb(255, 255, 255);

        div:first-of-type {
            overflow: hidden;
            position: relative;
            overflow-wrap: break-word;
            box-sizing: border-box;
            text-align: start;
            white-space: pre-wrap;
            border: none !important;
            border-radius: 8px;
        }
    }

    .listItem {
        border: 1px solid var(--announcementHeaderBackground);
        color: var(--announcementHeaderBackground) !important;
        padding: 4px 20px 4px !important;

        &.listItemFocused {
            background: var(--primaryColor) !important;
            color: #ffffff !important;
        }
    }

    textarea {
        border: none !important;
        line-height: inherit;
        height: 100%;
        padding: 5px;
        background-color: #fff;

        //border-radius: 2px;
        &:hover,
        &:focus,
        &:active {
            border: 1px solid var(--primaryColor) !important;
            border-right-width: 1px !important;
            -webkit-box-shadow: 0 0 0 2px var(--primaryColor) !important;
            box-shadow: var(--primaryColor) !important;

            outline: 0;
        }
    }

    ::placeholder {
        color: #cacaca !important;
    }
}