@import "assets/scss/mixins.scss";

.siderTopInfo {
	background: #fff;
	position: relative;
	min-height: 80px;

	@include extra-small {
		min-height: 100px;
	}

	.communitySection {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		svg {
			fill: #ffffff;

			path {
				stroke: #ffffff;
			}
		}

		.closeButtonContainer {
			position: absolute;
			right: 8px;
			top: 15px;
			cursor: pointer;

			@include extra-small {
				display: none;
			}
		}

		.absoluteCloseButtonContainer {
			position: absolute;
			right: 3px;
			top: 4px;
			cursor: pointer;

			@include extra-small {
				display: none;
			}
		}

		.closeButtonContainerMobile {
			position: absolute;
			right: 8px;
			top: 15px;
			color: #fff;
			cursor: pointer;
			display: none;

			@include extra-small {
				display: block;
			}
		}

		.logoSection {
			z-index: 9;
			position: absolute;
			background-color: var(--headerBackground);
			padding: 10px;
			border-radius: 50%;
			min-width: 120px;
			min-height: 120px;

			@include sm {
				margin-top: 33px;
			}

			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 35px;

			.logo {
				pointer-events: all;
				cursor: pointer;

				> section {
					background: transparent;
				}

				img {
					width: 120px;
					height: 120px;

					border-radius: 50%;
				}
			}
		}

		.absoluteLogoSection {
			z-index: 9;
			position: absolute;
			background-color: var(--headerBackground);
			// padding: 10px;
			border-radius: 50%;
			min-width: 35px;
			min-height: 35px;

			@include sm {
				margin-top: 33px;
			}

			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 22px;

			.logo {
				pointer-events: all;
				cursor: pointer;

				> section {
					background: transparent;
				}

				img {
					width: 35px;
					height: 35px;

					border-radius: 50%;
				}
			}
		}

		.title {
			color: #fff;
			font-size: 16px;
			font-weight: 900;
			padding: 10px;
		}

		// p {
		// 	margin: 0;
		// 	line-height: 21px;
		// 	font-size: 14px;
		// 	font-weight: 700;
		// 	color: #fff;
		// 	strong {
		// 		font-size: 12px;
		// 		color: #9b9b9b;
		// 	}
		// }
	}

	.groups {
		margin: 20px;

		:global(.ant-select-selector) {
			background: #f0f8ff24 !important;
			color: var(--white) !important;
			font-weight: bold !important;
			border: unset !important;
		}

		:global(.ant-select-dropdown) {
			background: #0e536b !important;
			color: var(--white) !important;
		}

		:global(.ant-select-arrow) {
			color: #ffffff73;
		}

		p {
			padding: 15px 20px 15px 30px;
			margin: 0;
			line-height: 20px;
			font-size: 14px;
			font-weight: 700;
			color: #fff;

			span {
				width: 20px;
				height: 20px;
				line-height: 20px;
				text-align: center;
				background-color: var(--primaryColor);
				display: inline-block;
				margin: 0 15px;
				border-radius: 100%;
				font-size: 13px;
			}
		}
	}
}

.groupSiderTopInfo {
	position: relative;
	min-height: 80px;

	.communitySection {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		svg {
			fill: var(--headerBackground);

			path {
				stroke: var(--headerBackground);
			}
		}

		.closeButtonContainer {
			position: absolute;
			right: 8px;
			top: 15px;
			color: #fff;
			cursor: pointer;

			@include extra-small {
				display: none;
			}
		}

		.absoluteCloseButtonContainer {
			position: absolute;
			right: 3px;
			top: 4px;
			color: #fff;
			cursor: pointer;

			@include extra-small {
				display: none;
			}
		}

		.closeButtonContainerMobile {
			position: absolute;
			right: 8px;
			top: 15px;
			color: #fff;
			cursor: pointer;
			display: none;

			@include extra-small {
				display: block;
			}
		}

		.logoSection {
			z-index: 9;
			position: absolute;
			background-color: var(--headerBackground);
			padding: 10px;
			border-radius: 50%;
			min-width: 120px;
			min-height: 120px;

			@include sm {
				margin-top: 33px;
			}

			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 35px;

			.logo {
				pointer-events: all;
				cursor: pointer;

				> section {
					background: transparent;
				}

				img {
					width: 120px;
					height: 120px;

					border-radius: 50%;
				}
			}
		}

		.absoluteLogoSection {
			z-index: 9;
			position: absolute;
			background-color: var(--headerBackground);
			// padding: 10px;
			border-radius: 50%;
			min-width: 35px;
			min-height: 35px;

			@include sm {
				margin-top: 33px;
			}

			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 22px;

			.logo {
				pointer-events: all;
				cursor: pointer;

				> section {
					background: transparent;
				}

				img {
					width: 35px;
					height: 35px;

					border-radius: 50%;
				}
			}
		}

		.title {
			color: #fff;
			font-size: 16px;
			font-weight: 900;
			padding: 10px;
		}
	}

	.groups {
		margin: 20px;

		:global(.ant-select-selector) {
			background: #f0f8ff24 !important;
			color: var(--white) !important;
			font-weight: bold !important;
			border: unset !important;
		}

		:global(.ant-select-dropdown) {
			background: #0e536b !important;
			color: var(--white) !important;
		}

		:global(.ant-select-arrow) {
			color: #ffffff73;
		}

		p {
			padding: 15px 20px 15px 30px;
			margin: 0;
			line-height: 20px;
			font-size: 14px;
			font-weight: 700;
			color: #fff;

			span {
				width: 20px;
				height: 20px;
				line-height: 20px;
				text-align: center;
				background-color: var(--primaryColor);
				display: inline-block;
				margin: 0 15px;
				border-radius: 100%;
				font-size: 13px;
			}
		}
	}
}

.dropDown {
	.defaultOption {
		font-style: italic !important;
	}

	background-color: var(--headerBackground) !important;

	:global(.ant-select-item.ant-select-item-option) {
		color: #ffffff7a;
		font-weight: bold;

		&:global(.ant-select-item-option-active) {
			color: var(--secondaryColor);
			font-weight: bold;
			background: #ffffffd0;
		}

		&:global(.ant-select-item-option-selected) {
			color: var(--secondaryColor);
			font-weight: bold;
		}
	}
}
