@import "assets/scss/mixins.scss";

.container {
	max-width: 1200px;
	margin: auto;
	padding: 50px;
	height: 100%;
	width: 100%;

	> div {
		border-radius: 14px;
		padding: 30px 20px;
		background-color: #fafafa !important;
		box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
	}

	@include extra-small {
		padding: 16px;
		> div {
			padding: 20px 10px;
		}
	}
}
