@import "../../../../assets/scss/mixins.scss";
.container {
	display: flex;
	flex-direction: column;
	background: #f7f6f3;
	border-radius: 10px;
	padding: 10px;
	margin-top: 10px;

	&.selected {
		background: var(--primaryColor);
		color: var(--white) !important;
		.boldMessage {
			color: var(--white) !important;
		}
	}
	cursor: pointer;
	.top {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		.leftSide {
			display: flex;
			position: relative;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			.avatar {
				width: 30px;
				height: 30px;
				border-radius: 100%;
				border: 2px solid #fff;
				overflow: hidden;
				margin-right: 10px;

				img {
					width: 100%;
				}
			}
			.userName {
				align-self: flex-end;
				font-weight: bold;
				color: var(--secondaryColor);
				margin: auto;
			}
			.onlineBadge {
				width: 10px;
				height: 10px;
				background: #8fd782;
				border-radius: 100%;
				align-self: center;
				position: absolute;
				left: -12px;
			}
		}
		.rightSide {
			position: relative;
			@include sm {
				font-size: 12px;
			}
			.leftTopBullet {
				position: absolute;
				position: absolute;
				width: 25px;
				height: 25px;
				top: -20px;
				right: -20px;
				background: #00c569;
				border-radius: 100%;
				color: var(--white);
				text-align: center;
				line-height: 25px;
			}
		}
	}
	.bottom {
		&.boldMessage {
			font-weight: bold;
			color: var(--secondaryColor);
		}
	}
}
