@import "assets/scss/mixins.scss";

.container {
	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: #f4f5f7;

	>.header {
		display: flex;
		flex-direction: row;
		gap: 15px;
		margin-bottom: 12px;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 12px;
		border-bottom: 1px solid #dedede;
		position: relative;

		@include extra-small {
			flex-wrap: wrap;
			justify-content: flex-end;
		}

		.flexRowDiv {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 10px;

			>.sortBy {
				font-family: "Avenir";
				font-style: normal;
				font-weight: 900;
				font-size: 15px;
				color: #6d6d6d;
				margin-bottom: 0;
			}
		}

		.primaryButton {
			@include extra-small {
				width: 100%;
			}

			button {
				@include extra-small {
					width: 100%;
				}

				background-color: var(--primaryColor);
				border-color: var(--primaryColor);
				font-size: 16px;
				line-height: 16px;
				font-weight: bold;
				padding: 0px 26px;
				border-radius: 16px;

				&:hover,
				&:focus,
				&:active {
					border-color: rgba($color: var(--primaryColorRgb),
							$alpha: 0.8);
					background-color: rgba($color: var(--primaryColorRgb),
							$alpha: 0.8);
					color: #fff;
				}
			}
		}
	}

	.mainContent {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		flex: 1;
		gap: 15px;

		.filterSections {
			margin-top: 5px;
			display: flex;
			flex-direction: column;
			flex: 1;
			background-color: #ffffff;
			height: max-content;
			border-radius: 8px;
		}

		.videosSection {
			display: flex;
			flex-direction: column;
			flex: 3;
			height: 100%;
		}
	}

	.filterContainer {
		display: flex;
		flex-direction: column;
		width: 300px;

		.header {
			margin-bottom: 4px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.arrowsIcons {
				cursor: pointer;
			}

			.title {
				font-size: 18px;
				font-weight: 800;
				color: #555;
			}

			.titleSlim {
				font-size: 18px;
				font-weight: 500;
				color: #555;
			}
		}

		.content {
			font-family: "Avenir Light", sans-serif;

			.pointerCursor {
				cursor: pointer;
				border-bottom: 1px solid #999;

				&:hover {
					color: #1890ff;
				}
			}

			.defaultCursor {
				cursor: default;
				color: #333;
				font-weight: 800;
				font-size: 14px;
			}

			.divider {
				font-size: 10px;
				color: #999;
				text-transform: uppercase;
			}

			.checkboxGroup {
				display: flex;
				flex-direction: column;
				font-size: 14px;

				:global(.ant-checkbox-group) {
					display: flex;
					flex-direction: column;
				}

				&.accents {
					font-family: "Avenir Light", sans-serif;
				}
			}
		}
	}
}

.counter {
	font-size: 16px;
	font-weight: 500;
	color: #6d6d6d;
	font-family: "Nunito-Medium";
}

// * Tags

.filterByTagsContainer {
	width: 100%;
	justify-content: space-between;
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 10px;

	.selectedMessage {
		display: flex;
		flex: 1;
		justify-content: flex-end;
		align-items: center;
		gap: 10px;
		font-size: 16px;
		font-weight: 500;
		color: #6d6d6d;
		font-family: "Nunito-Medium";
		width: 100%;

		@include sm {
			font-size: 12px;
		}

		button {
			max-width: 80px;
			border-radius: 20px;
		}
	}
}

.filterByTags {
	font-family: "Nunito-Regular";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: #000000;
	margin-bottom: 0;
}

.tagsDropdown {
	height: 40px;

	>div>span:nth-child(2) {
		font-weight: bold !important;
		font-size: 14px;
		font-family: Avenir;
		color: #000;
		text-align: left;
	}

	>div {
		height: 40px !important;
		display: flex;
		align-items: center;
	}

	border-radius: 8px;

	:global(.ant-select-single.ant-select-open .ant-select-selection-item) {
		color: none;
	}

	&:hover>div {
		border-color: var(--headerBackground) !important;
		border-style: solid !important;
		border-width: 1px !important;
	}
}

// * End Tags

.horizontalFolders {
	margin-bottom: 10px;
}

.folderName {
	font-family: "Nunito-Regular";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: #000000;
	margin-bottom: 0;
}