@import "assets/scss/mixins.scss";

.card {
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	// margin-bottom: 42px;
	padding: 32px 24px;
	width: 264px;
	height: 448px;
	box-shadow: 0px 0px 22px 0px #dedede;
	margin: 0px 10px;
	border-radius: 8px;
	align-items: center;
	font-family: "Avenir Light", sans-serif;

	// text-align: center;
	@include for-phone-only {
		min-width: 200px;
		width: 95%;
		margin: 0px;
	}

	.headerWithImage {
		height: 56px;
		min-width: 56px;
		max-width: 56px;
		overflow: hidden;

		img {
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;
			width: 100%;
		}
	}

	.headerWithoutImage {
		border-radius: 8px;
		height: 56px;
		min-width: 56px;
		max-width: 56px;
		background-color: var(--selectedCommunityToJoinHeaderBackground);
	}

	.priceContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		width: inherit;

		.priceContent {
			position: absolute;
			bottom: -50px;
			background-color: #fff;
			border-radius: 21px;
			width: 240px;
			height: 80px;
			-webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
			box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			font-size: 16px;

			@include for-phone-only {
				width: 150px;
			}

			.priceText {
				font-family: "Avenir-Heavy", sans-serif;
				font-size: 24px;
				font-weight: 900;
				text-transform: uppercase;

				.smallBoldText {
					font-size: 16px;
				}
			}

			.intervalText {
				font-size: 16px;
				font-weight: 400;
			}

			.lightText {
				font-size: 12px;
				font-weight: 500;
			}
		}
	}

	.container {
		// padding-top: 20px;
		// margin-top: 50px;
		display: flex;
		flex: 1;
		flex-direction: column;
		width: 100%;
		gap: 16px;
		justify-content: space-between;

		.title {
			font-family: 'Avenir-Medium';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			color: #4D5A81;
		}

		.description {
			:global(.trackY) {
				width: 5px !important;
				right: -5px !important;
			}

			:global(.ScrollbarsCustom.trackYVisible) {
				padding-right: 8px !important;
			}

			// max-height: 32px;
			// min-height: 32px;
			font-family: 'Nunito-Regular';
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			overflow: hidden;
			/* or 133% */


			/* Text/Help */

			color: #9CA1BA;
			// text-align: center;
			color: #9CA1BA;
			font-family: 'Nunito-Regular';

			&.fixedHeightBox {
				height: 200px;
				padding: 6px;
				overflow-y: auto;
			}

			.showMoreBtn {
				font-weight: bold;
				margin-top: 10px;
				text-decoration: underline;

				&:hover {
					cursor: pointer;
				}
			}
		}

		.whiteShadow {
			height: 20px;
			z-index: 1;
			-webkit-box-shadow: 0px -14px 8px -1px #ffffff;
			box-shadow: 0px -14px 8px -1px #ffffff;
		}

		.planPrice {
			display: flex;
			font-family: 'Avenir-Medium';
			font-style: normal;
			font-weight: 800;
			font-size: 24px;
			line-height: 32px;
			color: var(--selectedCommunityToJoinHeaderBackground);

			.month {
				font-family: 'Avenir-Medium';
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				margin-left: 6px;
				line-height: 38px;
				color: #9CA1BA;
			}
		}

		.buttonContainer {
			display: flex;
			align-items: flex-end;
			// align-items: end;
			flex-direction: column;

			// padding: 0px 20px 10px;
			:global(.ant-btn:not(.ant-btn-primary):hover) {
				border-color: var(--selectedCommunityToJoinPrimaryColor, var(--primaryColor)) !important;
				background-color: rgba(var(--selectedCommunityToJoinPrimaryColorRgb,
							var(--primaryColorRgb)),
						$alpha: 0.8) !important;

				// border-color: #fff !important;
				color: white !important;
			}

			.buttonStyle {
				font-size: 16px;
				border-radius: 50px;
				width: 100%;
				height: 40px;
				background-color: var(--selectedCommunityToJoinHeaderBackground);
				border-color: var(--selectedCommunityToJoinHeaderBackground);

				&:hover {
					background-color: rgba(var(--selectedCommunityToJoinHeaderBackgroundRgb),
							0.5) !important;
					border-color: rgba(var(--selectedCommunityToJoinHeaderBackgroundRgb),
							0.5) !important;
				}
			}

			.smallGreyText {
				margin-top: 6px;
				align-self: center;
				color: grey;
				font-size: 12px;
				height: 20px;
			}
		}

		.listedItemsContainer {
			display: flex;
			flex-direction: column;
			flex: 1;
			// margin: 10px 0px;

			.listedItems {
				// max-height: 180px;
				height: 100% !important;
				max-height: 100% !important;

				// background-color: rgba(
				// 	$color: var(--selectedCommunityToJoinHeaderBackgroundRgb),
				// 	$alpha: 0.2
				// );
				// :global(.ScrollbarsCustom .ScrollbarsCustom-TrackY) {
				// 	right: 0px !important;
				// }
				.benefitContainer {
					display: flex;
					flex-direction: row;
					// align-items: center;
					gap: 12px;
					margin-bottom: 5px;

					// padding-right: 12px;
					.checkboxContainer {
						display: flex;
						// align-items: center;
						margin-top: 3px;
						// padding-left: 15px;
						// padding-right: 15px;
						// margin-top: 5px;
						color: var(--selectedCommunityToJoinHeaderBackground);

						svg {
							fill: currentColor;
						}
					}

					.benefitText {
						font-family: 'Nunito-Regular';
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 20px;
						// text-align: left;
						color: #4D5A81 // margin-top: 5px;
					}
				}
			}
		}
	}

	.horizontalLine {
		border: 0.5px solid #ededed;
	}

	.smallIcon {
		margin: 10px 0px;

		img {
			@include extra-small {
				max-width: 49px;
				max-height: 48px;
			}

			@include small {
				max-width: 49px;
				max-height: 48px;
			}

			@include medium {
				max-width: 59px;
				max-height: 58px;
			}

			@include large {
				max-width: 69px;
				max-height: 68px;
			}
		}
	}
}

.modalContainer {
	:global(.ant-modal-header) {
		border-top-left-radius: 8px !important;
		border-top-right-radius: 8px !important;
	}

	:global(.ant-modal-content) {
		border-radius: 8px !important;
	}

	:global(.ant-modal-footer) {
		padding-right: 26px;
	}

	:global(.ant-modal-title) {
		font-weight: bold;
		font-family: "Avenir-Heavy", sans-serif !important;
	}
}

.modalContent {
	padding: 20px;
	font-family: "Avenir Light", sans-serif;
	text-align: center;
}