@import 'assets/scss/mixins.scss';

.container {
	display: flex;
	flex-direction: column;
	// padding: 10px;
	padding-top: 22px;
	color: #ffffff;

	@include extra-small {
		padding-top: 13px;
	}

	.closeButtonContainer {
		position: absolute;
		right: 6px;
		top: 5px;
		color: #fff;
		cursor: pointer;

		@include extra-small {
			display: none;
		}
	}

	.absoluteCloseButtonContainer {
		position: absolute;
		right: 3px;
		top: 4px;
		color: #fff;
		cursor: pointer;

		@include extra-small {
			display: none;
		}
	}

	.closeButtonContainerMobile {
		position: absolute;
		right: 8px;
		top: 15px;
		color: #fff;
		cursor: pointer;
		display: none;

		@include extra-small {
			display: block;
			right: 20px;
			top: 22px
		}
	}

	.logoSection {
		// background-color: var(--primaryColor);
		// border-radius: 24px;
		padding: 0px 12px;
		display: flex;
		flex: 1;
		// justify-content: space-between;
		color: #ffffff;
		align-items: center;
		font-weight: 800;
		margin-bottom: 16px;
		font-size: 14px;

		&:hover {
			cursor: pointer;
		}

		>img {
			align-self: center;
			width: 35px;
			border-radius: 50%;
		}

		span {
			margin-left: 8px;
		}
	}

	.flexRow {
		display: flex;
		flex-direction: row;

		.icon {
			width: 50px;
			height: 50px;
			margin-right: 15px;
			align-self: center;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 30px;
			background-color: var(--primaryColor);
			border-radius: 50%;
		}

		.flexColumn {
			display: flex;
			flex-direction: column;

			>.title {
				font-size: 16px;
				font-weight: 700;
			}

			.groupDetails {
				display: flex;
				flex-direction: row;

				>.title {
					color: var(--primaryColor);
				}

				&.columnDirection {
					flex-direction: column;
				}
			}
		}
	}

	:global(.ant-divider-horizontal) {
		margin: 8px 0px;
	}
}

.groupDescriptionContainer {
	.title {
		font-size: 16px;
		text-align: center;
		font-weight: bold;
	}
}