@import "../../../../../../assets/scss/index.scss";

.container {
	display: flex;
	flex-direction: row;
	flex: 1;
	justify-content: center;
	padding-right: 12px;
	.primaryBtn {
		background: var(--primaryColor);
		border: 1px solid;
		border-radius: 20px;
		font-weight: bold;
		&:hover,
		&:focus,
		&:active {
			background-color: rgba($color: var(--primaryColorRgb), $alpha: 0.8);
			border-color: rgba($color: var(--primaryColorRgb), $alpha: 0.8);
		}
	}
	.disableBtn {
		background: '#F6F6F9';
		color: 'black';
		border: 1px solid;
		border-radius: 20px;
		font-weight: bold;
		&:hover {
			border-color: black;
			color: black
		}
		&:focus {
			border-color: black;
			color: black;
		}
		&:active {
			border-color: black;
			color: black;
		}
		
	}
}
