.spinnerContainer {
	display: flex;
	flex-direction: column;
	padding: 38px;
}

.modalWrap {
	margin: 0px 20px;
	overflow: hidden !important;
}

.modal {
	:global(.ant-modal-title) {
		font-family: "Avenir Heavy", sans-serif;
	}
}

.mainBtn {
	border-radius: 8px !important;

	&:hover {
		border-color: var(--selectedCommunityToJoinPrimaryColor) !important;
		color: var(--selectedCommunityToJoinPrimaryColor) !important;
	}
	&.activeButton {
		background: var(--selectedCommunityToJoinPrimaryColor) !important;
		color: #fff;
	}
}
