.wrapper {
	position: relative;
	display: flex;
	flex: 1;
	margin-bottom: 24px;
	.reactPlayerContainer {
		position: relative;
		display: flex;
		justify-content: center;
		flex-direction: column;
		flex: 1;
		padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
	}
}

.player {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.backBackButtonContainer {
	font-family: "Avenir Heavy", sans-serif;
	> :first-child {
		cursor: pointer;
	}
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	span {
		margin-left: 10px;
		font-size: 22px;
	}
}
