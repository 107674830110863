.skeletonContainer {
	width: calc(100% - 30px);
	margin: 28px auto;
}

.btnContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 28px;
	.btnLabel {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		flex: 1;
		color: grey;
		margin-bottom: 20px;
	}
	.paymentRequestButton {
		display: flex;
		flex-direction: column;
		width: calc(100% - 30px);
		margin-bottom: 20px;
		:global(.__PrivateStripeElement) {
			iframe {
				width: unset !important;
			}
		}
	}
}
