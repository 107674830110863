@import 'assets//scss//mixins.scss';

.infiniteScroll {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    padding: 16px 0px;
    gap:10px
   
}
