@import "assets/scss/mixins.scss";

.container {
	// display: flex;
	// flex-direction: row;
	// flex-wrap: wrap;
	// border-radius: 8px;
	// overflow: hidden;
	flex: 1;
	.input {
		display: flex;
		flex-direction: row;
		border-radius: 8px;
		height: 32px;
		width: 100%;
	
	}
	// :global(.ant-input-affix-wrapper) {
	// 	border-radius: 0px !important;;
	// }
	:global(.ant-select-selector) {
		border-radius: 0px !important;
	}
	.select {
		width: 33.33%;
	}
	@include sm {
		position: relative;
		margin: 15px 0px;
		align-self: unset;
	}
}
