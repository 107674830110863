@import "assets/scss/index.scss";

.container {
	display: flex;
	flex-direction: column;
	flex: 1;



}

p {
	margin-bottom: 0px !important;
}

.topLevelContainer {
	width: 100%;
	background: white;
	display: flex;
	justify-content: center;

	@include sm {
		background: #f4f5f7;
		bottom: 0;
		padding: 0px 12px;
	}

	.btnContainer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		// padding: 20px 0px;
		padding: 12px 0px;
		width: 552px;
		background-color: white;

		button {
			width: 170px !important;

		}

		:global(.ant-btn:not(.ant-btn-primary):hover) {
			border-color: var(--selectedCommunityToJoinPrimaryColor, var(--primaryColor)) !important;
			background-color: rgba(var(--selectedCommunityToJoinPrimaryColorRgb,
						var(--primaryColorRgb)),
					$alpha: 0.8) !important;

			// border-color: #fff !important;
			color: white !important;
		}

		@include sm {
			width: 100%;
			padding: 12px;
			gap: 10px;
		}

		.btnPosition {
			align-self: center;
		}
	}
}

.pictureWrapper {
	display: flex;
	align-items: center;

	p {
		font-family: 'Nunito-Regular';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #292F45;
		margin-bottom: 8px !important;
	}

	// margin-top: 24px;
}