.postImg {
	margin-bottom: 20px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	position: relative;
	overflow: hidden;
	img {
		max-height: 500px;
		object-fit: contain;
	}
	.blurFilterImg {
		position: absolute;
		object-fit: unset;
		filter: blur(6px);
		-webkit-filter: blur(6px);
		max-height: 500px;
	}
}
