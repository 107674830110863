@import "assets/scss/index.scss";
@import "../styles.module.scss";

.container {
	.subTitle {
		margin-bottom: 8px;

		&.errorText {
			color: red;
			font-size: 12px;
		}

		&.greyText {
			color: grey;
			font-size: 12px;
		}
	}

	.verticalMarginBox {
		margin: 10px 0px;
	}
}