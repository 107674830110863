.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	padding: 20px 0px;
	.label {
		font-weight: bold;
		color: gray;
	}
}
