@import "assets/scss/mixins.scss";

.postBox {
	background: #fff;
	border-radius: 8px;
	padding: 30px;
	margin-bottom: 15px;
	position: relative;

	&.communityBox {
		border-top: 4px solid var(--headerBackground);
	}

	&.communityMemberBox {
		border-top: 4px solid var(--announcementHeaderBackground);
	}

	&.boxShadow {
		-webkit-box-shadow: 0 1px 10px 2px rgba(0, 0, 0, 0.15);
		-moz-box-shadow: 0 1px 10px 2px rgba(0, 0, 0, 0.15);
		box-shadow: 0 1px 10px 2px rgba(0, 0, 0, 0.15);
	}

	.videoPlayer {
		>div:first-child {
			width: unset !important;
			margin-top: 0;
			margin-bottom: 15px;
		}
	}

	.profileInfo {
		display: flex;
		margin-bottom: 20px;

		.avatar {
			width: 54px;
			height: 54px;
			min-width: 54px;
			border-radius: 100%;
			overflow: hidden;
			margin-right: 13px;
			cursor: pointer;

			img {
				max-width: 100%;
			}

			@include extra-small {
				width: 43px;
				height: 43px;
				min-width: 43px;
			}
		}

		@include extra-small {
			margin-bottom: 10px;
		}

		div.header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;

			>span {
				margin: 0;
				font-size: 20px;
				line-height: 29px;
				font-weight: 700;
				color: var(--announcementHeaderBackground);
			}

			>span:not(:first-child) {
				margin-left: 8px;
				cursor: pointer;
			}
		}

		.smallText {
			font-size: 12px;
			color: rgba(0, 0, 0, 0.7);

			.highlightText {
				color: var(--secondaryColor);
			}
		}

		.dropdownBtn {
			display: flex;

			button {
				border-top-right-radius: unset;
				border-bottom-right-radius: unset;
				border-radius: 6px !important;
			}

			:global(.anticon.anticon-more) {
				height: 100%;

				svg:first-of-type {
					height: 100%;
				}
			}
		}

		small {
			color: var(--primaryColor);
			font-size: 12px;
			line-height: 12px;
			font-weight: normal;
		}

		.normalFontWeight {
			font-size: 14px;
			line-height: 21px;
			font-weight: normal;
			color: rgba(0, 0, 0, 0.7);
			font-family: "Nunito-Regular";

			@include extra-small {
				font-size: 12px;
				line-height: 19px;
			}
		}

		.tag {
			cursor: pointer;

			&:not(:first-child) {
				margin-right: 4px;
			}

			font-family: "Nunito-Regular";
			font-style: normal;
			font-weight: 400;
			// font-size: 14px;
			line-height: 20px;
		}
	}

	.postButtonsBar {
		margin-bottom: 45px;

		a {
			display: inline-block;
			margin-right: 33px;
			line-height: 19px;
			color: #7a8fa6;
			font-size: 12px;
			font-weight: 600;

			img {
				margin-right: 6px;
			}
		}
	}

	.contentBoxInfo {
		// margin-top: -30px;
		padding-bottom: 15px;

		// margin-bottom: 15px;
		// border-bottom: 1px solid rgba(122, 143, 166, 0.19);
		h2 {
			margin: 0;
			color: #000;
			font-size: 20px;
			line-height: 23px;
			font-weight: 700;
		}

		p {
			margin: 0;
			color: #000;
			font-size: 16px;
			line-height: 23px;
			font-weight: normal;
		}

		a {
			color: var(--primaryColor) !important;
		}
	}

	@include extra-small {
		padding: 15px !important;
	}
}

.title {
	font-size: 18px;
	font-family: "Avenir Heavy";
	line-height: 24px;
}

.postAuthor {
	font-size: 18px !important;
	font-family: "Avenir";
}

.waitingApproval {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(255, 255, 255, 0.6);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	color: #ddd;
	font-weight: 700;
}

.showMoreContainer {
	>a {
		bottom: 0;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		height: 40px;
		width: 100%;
		position: absolute;
		background: linear-gradient(rgba(255, 255, 255, 0) 0%,
				rgba(255, 255, 255, 0.2) 5%,
				rgba(255, 255, 255, 1) 100%);
		text-transform: lowercase;
		font-weight: 700;
	}
}

.positionHack {
	position: relative;
	height: 400px;
	margin-bottom: 15px;

	>iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
	}
}