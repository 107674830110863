@import "assets/scss/index.scss";

.flexRow {
	display: flex;
	flex-direction: row;
	// padding-top: 100px;
	border-top: 2px;
	background-color: var(--white);
	padding: 10px 0px;
	justify-content: center;
	font-size: 12px;
	width: 100%;
	position: relative;

	.centerFlexItem {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.dashedLine {
		width: 40px;
		height: 1px;
		border: 1px #cdcdcd solid;
		border-style: dashed;
		margin: 0px 8px;
	}

	.boxNumber {
		text-align: -webkit-center;
		border: 1px #cdcdcd solid;
		font-family: "'Nunito-Regular";
		/* padding: 0px 6px; */
		border-radius: 4px;
		height: 16px;
		line-height: 16px;
		width: 16px;
		margin-right: 4px;
	}

	.textHeaderBackgroundColor {
		color: var(--selectedCommunityToJoinPrimaryColor, var(--primaryColor));
	}

	.iconWrapper {
		display: flex;
		margin-right: 4px;

		svg {
			color: var(--selectedCommunityToJoinPrimaryColor,
					var(--primaryColor));
		}
	}

	.text {
		font-family: 'Nunito-Regular';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: #4D5A81;
	}

	.backgroundBoxHeaderBackgroundColor {
		background-color: rgba(var(--selectedCommunityToJoinPrimaryColorRgb,
					var(--primaryColorRgb)),
				$alpha: 0.1);
		border-color: var(--selectedCommunityToJoinPrimaryColor,
				var(--primaryColor));
	}

	.backgroundBoxGreyColor {
		background-color: #f0f0f0;
		color: grey;
		border: none;
	}
}

.scrollableWrapper {
	overflow-x: auto;
	justify-content: flex-start;
	padding-left: 10px;
	padding-right: 10px;
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollableWrapper::-webkit-scrollbar {
	display: none;
}

.absoluteLeftPositionedWhiteShaddow {
	display: flex;
	-webkit-box-shadow: 8px 0px 50px 34px #ffffff;
	box-shadow: 8px 0px 50px 34px #ffffff;
	position: fixed;
	left: 0px;
}

.absoluteRightPositionedWhiteShaddow {
	display: flex;
	-webkit-box-shadow: -8px 0px 50px 34px #ffffff;
	box-shadow: -8px 0px 50px 34px #ffffff;
	position: fixed;
	right: 0px;
}