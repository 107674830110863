@import "assets/scss/mixins.scss";

.form {
	padding: 20px 10px;
	width: 100%;
	background-color: var(--white);
	border-radius: 10px;
	margin-bottom: 15px;

	input {
		border-radius: 8px;
	}

	:global(.ant-btn:not(.ant-btn-primary):hover) {
		color: var(--primaryColor) !important;
	}

	.cancelButton {
		border-style: solid;
		border-width: 1;
		border-color: var(--primaryColor);
		color: var(--primaryColor);
		border: 1px solid;
		border-radius: 20px;
		width: 130px;
		font-weight: bold;
		text-align: center;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.headerFormBox {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.formTitle {
			font-size: 18px;
			font-weight: bold;
			color: var(--announcementHeaderBackground);
			margin-bottom: 10px;
		}
	}

	label {
		color: var(--announcementHeaderBackground) !important;
	}

	textarea {
		resize: none;
	}

	:global(.ant-col .ant-form-item-label) {
		padding: 0px;
	}

	.tags {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
	}

	.tagTitle {
		color: var(--announcementHeaderBackground);
		margin-bottom: 5px;
	}

	.link {
		margin: 0px 0px 10px 0px;
	}

	.datePicker {
		display: flex;
	}

	.absolutePositionDiv {
		position: absolute;
		color: #fff;
		padding: 4px 10px;
		background-color: #0000004a;
		font-family: "Avenir-Heavy", sans-serif;
		border-radius: 20px;
		right: 13px;
		top: 10px;
		cursor: pointer;

		&:hover {
			background-color: #00000070;
		}
	}

	.submitButton {
		display: flex;
		flex-direction: row;
		margin-top: 15px;
		justify-content: space-between;

		:global(.ant-form-item-control-input-content) {
			display: flex;
		}

		button.right {
			background: var(--primaryColor);
			border: 1px solid;
			border-radius: 20px;
			width: 130px;
			font-weight: bold;
		}
	}

	.noOfCharactersRemaining {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		color: #80808082;
		margin-top: -10px;
	}
}

.detailsInput {
	padding: 5px;
	min-height: 200px;

	div {
		> textarea {
			border-radius: 8px;
			border: 1px solid #d9d9d9 !important;
		}
	}

	div:first-of-type {
		font-size: 14px;
		min-height: 63px;
		font-weight: normal;
		background-color: rgb(255, 255, 255);

		div:first-of-type {
			overflow: hidden;
			position: relative;
			overflow-wrap: break-word;
			box-sizing: border-box;
			text-align: start;
			white-space: pre-wrap;
			border: none !important;
			border-radius: 8px;
		}
	}

	.listItem {
		border: 1px solid var(--announcementHeaderBackground);
		color: var(--announcementHeaderBackground) !important;
		padding: 4px 20px 4px !important;

		&.listItemFocused {
			background: var(--primaryColor) !important;
			color: #ffffff !important;
		}
	}

	textarea {
		border: none !important;
		line-height: inherit;
		height: 100%;
		padding: 5px;
		background-color: #fff;

		&:hover,
		&:focus,
		&:active {
			border: 1px solid var(--primaryColor) !important;
			border-right-width: 1px !important;
			-webkit-box-shadow: 0 0 0 2px var(--primaryColor) !important;
			box-shadow: var(--primaryColor) !important;

			outline: 0;
		}
	}

	::placeholder {
		color: #cacaca !important;
	}
}

.divPositionRelativeWrapper {
	> div {
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
		border-radius: 8px !important;
		z-index: 1 !important;
		background-color: white !important;
		min-width: 100px !important;
		top: 40px !important;
		left: 45px !important;
		position: absolute !important;
		margin-top: 0 !important;

		@include extra-small {
			top: 40px !important;
			left: 0 !important;
			width: 100% !important;
		}
	}

	.listItem {
		width: 300px;
		color: var(--announcementHeaderBackground) !important;
		padding: 4px 20px 4px !important;

		&.listItemFocused {
			background: var(--primaryColor) !important;
			color: #ffffff !important;
		}

		@include extra-small {
			width: 100%;
		}
	}

	ul {
		max-height: 290px;
		overflow: auto;

		li {
			div {
				border-radius: 4px;
				height: 45px;
				display: flex;
				gap: 15px;
				align-items: center;

				span {
					font-family: "Avenir Heavy", sans-serif;
					font-size: 14px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: break-all;
				}
			}
		}
	}
}
