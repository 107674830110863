@import "assets/scss/mixins.scss";

.container {
	display: flex;
	align-self: center;
	
	background-color: #ffffff;
	border-radius: 20px;
	justify-content: center;
	align-items: center;
    padding: 22px 0px;
    margin: 16px 0px;
	@include sm {
		width: 100%;
		padding: 10px;
	}
	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
        	padding: 15px 20px;
		margin-top: 10px;
        .absoluteBtn {
			position: absolute;
			right: 26px;
			top: 28px;
			cursor: pointer;
			border: 1px solid rgba(0, 0, 0, 0.25);
			border-radius: 4px;
			padding: 0px 4px;
			.boldGreyTxt {
				height: 22px;
				line-height: 24px;
				font-size: 16px;
				font-weight: bold;
				color: #000;
				text-transform: uppercase;
			}
		}
		.title {
			font-size: 24px;
			color: var(--primaryColor);
			text-align: center;
				font-family: "Avenir-Heavy";

			@include sm {
				font-size: 16px;
				text-align: center;
			}
		}
		.subTitle {
			font-size: 16px;
			color: var(--primaryColor);
			text-align: center;
			max-width: 70%;
				font-family: "Nunito-Medium";

			@include sm {
				font-size: 14px;
			}
		}
		.switchContainer {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			margin-top: 15px;
				font-family: "Avenir-Heavy";

			.label {
				margin-right: 8px;
				font-size: 14px;
				font-weight: bold;
			}
		}
	}
}
