.boldAndBlackText {
	font-weight: bold;
	color: #000000;
	cursor: pointer;
}
.greyAndBlackText {
	font-weight: bold;
	color: grey;
}

.flexRow {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.rightMargin10 {
	margin-right: 10px;
}
