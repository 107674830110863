@import "assets/scss/mixins.scss";

.memberCardContainer {
	background-color: #ffffff;
	height: 250px;
	width: 100%;
	display: flex;
	flex-direction: row;
	text-align: center;
	border-radius: 8px;
	overflow: hidden;

	@media extra-small {
		img {
			max-height: 100px !important;
			max-width: 100px !important;
		}
	}

	&:hover {
		cursor: pointer;
	}

	.memberDetails {
		width: 100%;
		padding: 15px;
		display: flex;
		flex-direction: column;

		.image {
			max-height: 100px;
			max-width: 120px;
			border-radius: 50%;
			align-self: center;
			margin-bottom: 10px;
		}

		.details {
			display: flex;
			flex-direction: column;
			height: 100%;

			@include extra-small {
				gap: 6px;
			}

			.memberName {
				font-size: 16px;
				font-family: "Nunito-Bold";
				font-weight: 700;
				overflow: hidden;
				word-break: break-all;
				text-overflow: ellipsis;
				white-space: pre;
				align-self: center;

				@include extra-small {
					font-size: 14px;
					-webkit-box-orient: vertical;
					display: -webkit-box;
					white-space: break-spaces;
					line-height: 12px;
					-webkit-line-clamp: 2;
				}
			}

			.memberType {
				font-size: 12px;
				font-family: "Nunito-Regular";
				font-weight: 400;
				color: var(--primaryColor);
				max-width: 160px;
				overflow: hidden;
				word-break: break-all;
				text-overflow: ellipsis;
				white-space: pre;
				align-self: center;
				-webkit-line-clamp: 2;

				@include extra-small {
					-webkit-box-orient: vertical;
					display: -webkit-box;
					white-space: break-spaces;
					line-height: 12px;
				}
			}

			.location {
				font-size: 12px;
				font-family: "Nunito-Regular";
				font-weight: 400;
				color: #4d5a81;
				display: flex;
				justify-content: center;
				overflow: hidden;
				word-break: break-all;
				text-overflow: ellipsis;
				white-space: pre;
				align-self: center;

				svg {
					path {
						stroke: var(--primaryColor);
					}
				}
			}
		}
	}
}

.memberSelectedCardContainer {
	background-color: var(--primaryColor);
	height: 250px;
	width: 100%;
	display: flex;
	flex-direction: row;
	text-align: center;
	border-radius: 8px;
	overflow: hidden;

	&:hover {
		cursor: pointer;
	}

	.memberDetails {
		width: 100%;
		display: flex;
		flex-direction: column;

		.image {
			padding: 15px;
			max-height: 120px;
			max-width: 120px;
			border-radius: 50%;
			align-self: center;
			margin-bottom: 10px;

			@include extra-small {
				padding: 0px;
				margin-top: 8px;
			}
		}

		.details {
			display: flex;
			flex-direction: column;
			height: 100%;

			@include extra-small {
				gap: 8px;
				padding: 4px;
			}

			.memberName {
				font-size: 16px;
				font-family: "Nunito-Bold";
				font-weight: 700;
				max-height: 40px;
				max-width: 160px;
				overflow: hidden;
				-webkit-line-clamp: 2;
				word-break: break-all;
				text-overflow: ellipsis;
				white-space: pre;
				align-self: center;
				color: #fff;

				@include extra-small {
					font-size: 12px;
					-webkit-box-orient: vertical;
					display: -webkit-box;
					white-space: break-spaces;
					line-height: 12px;
				}
			}

			.memberType {
				font-size: 14px;
				font-family: "Nunito-Regular";
				font-weight: 400;
				color: var(--announcementHeaderBackground);
				max-width: 160px;
				overflow: hidden;
				word-break: break-all;
				text-overflow: ellipsis;
				white-space: pre;
				align-self: center;

				@include extra-small {
					-webkit-box-orient: vertical;
					display: -webkit-box;
					white-space: break-spaces;
					line-height: 12px;
				}
			}

			.location {
				font-size: 12px;
				font-family: "Nunito-Regular";
				font-weight: 400;
				color: var(--announcementHeaderBackground);
				display: flex;
				justify-content: center;
				overflow: hidden;
				word-break: break-all;
				text-overflow: ellipsis;
				white-space: pre;
				align-self: center;

				svg {
					path {
						stroke: var(--announcementHeaderBackground);
					}
				}
			}
		}
	}
}
