@import '/src/assets/scss/mixins.scss';

.form {
	padding: 20px 10px;
	width: 100%;
	background-color: var(--white);
	border-radius: 12px;

	label {
		color: var(--announcementHeaderBackground) !important;
	}

	.formTitle {
		font-size: 16px;
		font-weight: bold;
		color: var(--announcementHeaderBackground);
	}

	:global(textarea.ant-input) {
		height: auto !important;
	}

	.radioButtonsBox {
		.radioButtons {
			font-family: "Avenir Heavy", sans-serif;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.radioButton {
				display: flex;
				justify-content: center;
				flex: 1;
				margin: 0px 4px;
				line-height: 30px;
				border: 1px solid var(--primaryColor);
				border-radius: 5px;
				color: var(--primaryColor) !important;
				font-weight: bold;
			}

			.radioButton:first-child {
				margin-left: 0px;
			}

			.radioButton:last-child {
				margin-right: 0px;
			}
		}

		:global(.ant-radio-button-wrapper:not(:first-child))::before {
			display: none;
		}

		:global(.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
			background: var(--primaryColor);
			color: var(--white) !important;
			border: none;
		}
	}

	.currencyWrapper {

		@include sm {
			min-width: 20%
		}

		.currency {

			//background-color: red;
			// display: flex;
			:global(.ant-select-selector) {
				border: 0px;
				border-bottom: 1px solid #d9d9d9;
			}
		}
	}

	.minToMaxPriceLabel {
		display: flex;
		display: flex;
		padding-top: 5px;
		flex-direction: row;
		justify-content: center;
		color: #00000054;
	}

	.priceInfoLabel {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 12px;

		.cardIcon {
			font-size: 24px;
			margin-right: 12px;
		}

		.text {
			font-size: 16px;
		}
	}

	.priceInput {
		//background-color: red;
		border: none;
	}

	textarea {
		resize: none;
	}

	:global(.ant-form-item) {
		margin-bottom: 10px;
	}

	:global(.ant-col .ant-form-item-label) {
		padding: 0px;
	}

	.submitButton {
		display: flex;
		flex-direction: row;
		margin-top: 15px;
		justify-content: center;

		@include sm {
			:global(.ant-form-item) {
				width: 100%
			}

			;
		}

		button {
			background: var(--primaryColor);
			border: 1px solid;
			border-radius: 20px;
			min-width: 200px;
			font-weight: bold;

			@include sm {
				width: 100%;
				border-radius: 16px;

			}
		}
	}


	.tags {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
	}
}

.divPositionRelativeWrapper {
	>div {
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
		border-radius: 8px !important;
		z-index: 1 !important;
		background-color: white !important;
		min-width: 100px !important;
		top: 40px !important;
		left: 45px !important;
		position: absolute !important;
		margin-top: 0 !important;

		@include extra-small {
			top: 40px !important;
			left: 0 !important;
			width: 100% !important;
		}
	}

	.listItem {
		width: 300px;
		color: var(--announcementHeaderBackground) !important;
		padding: 4px 20px 4px !important;

		&.listItemFocused {
			background: var(--primaryColor) !important;
			color: #ffffff !important;
		}

		@include extra-small {
			width: 100%;
		}
	}

	ul {
		max-height: 290px;
		overflow: auto;

		li {
			div {
				border-radius: 4px;
				height: 45px;
				display: flex;
				gap: 15px;
				align-items: center;

				span {
					font-family: "Avenir Heavy", sans-serif;
					font-size: 14px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: break-all;
				}
			}
		}
	}
}

.detailsInput {
	padding: 5px;
	min-height: 200px;

	div {
		>textarea {
			border-radius: 8px;
			border: 1px solid #d9d9d9 !important;
		}
	}

	div:first-of-type {
		font-size: 14px;
		min-height: 63px;
		font-weight: normal;
		background-color: rgb(255, 255, 255);

		div:first-of-type {
			overflow: hidden;
			position: relative;
			overflow-wrap: break-word;
			box-sizing: border-box;
			text-align: start;
			white-space: pre-wrap;
			border: none !important;
			border-radius: 8px;
		}
	}

	.listItem {
		border: 1px solid var(--announcementHeaderBackground);
		color: var(--announcementHeaderBackground) !important;
		padding: 4px 20px 4px !important;

		&.listItemFocused {
			background: var(--primaryColor) !important;
			color: #ffffff !important;
		}
	}

	textarea {
		border: none !important;
		line-height: inherit;
		height: 100%;
		padding: 5px;
		background-color: #fff;

		//border-radius: 2px;
		&:hover,
		&:focus,
		&:active {
			border: 1px solid var(--primaryColor) !important;
			border-right-width: 1px !important;
			-webkit-box-shadow: 0 0 0 2px var(--primaryColor) !important;
			box-shadow: var(--primaryColor) !important;

			outline: 0;
		}
	}

	::placeholder {
		color: #cacaca !important;
	}
}

.divPositionRelativeWrapper {
	>div {
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
		border-radius: 8px !important;
		z-index: 1 !important;
		background-color: white !important;
		min-width: 100px !important;
		top: 40px !important;
		left: 45px !important;
		position: absolute !important;
		margin-top: 0 !important;

		@include extra-small {
			top: 40px !important;
			left: 0 !important;
			width: 100% !important;
		}
	}

	.listItem {
		width: 300px;
		color: var(--announcementHeaderBackground) !important;
		padding: 4px 20px 4px !important;

		&.listItemFocused {
			background: var(--primaryColor) !important;
			color: #ffffff !important;
		}

		@include extra-small {
			width: 100%;
		}
	}

	ul {
		max-height: 290px;
		overflow: auto;

		li {
			div {
				border-radius: 4px;
				height: 45px;
				display: flex;
				gap: 15px;
				align-items: center;

				span {
					font-family: "Avenir Heavy", sans-serif;
					font-size: 14px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: break-all;
				}
			}
		}
	}
}