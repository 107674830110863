$screen-sm-max: 576px;

// Small devices
@mixin sm {
	@media (max-width: #{$screen-sm-max}) {
		@content;
	}
}

//Extra small devices
@mixin extra-small {
	@media (max-width: 600px) {
		@content;
	}
}
// Small devices
@mixin small {
	@media (min-width: 600px) {
		@content;
	}
}
// Medium devices
@mixin medium {
	@media (min-width: 768px) {
		@content;
	}
}
// Large devices
@mixin large {
	@media (min-width: 1200px) {
		@content;
	}
}

@function hexToRGB($hex) {
	@return red($hex), green($hex), blue($hex);
}

@mixin for-phone-only {
	@media (max-width: 599px) {
		@content;
	}
}
@mixin for-tablet-portrait-up {
	@media (min-width: 600px) {
		@content;
	}
}
@mixin for-tablet-portait-only {
	@media (min-width: 600px) and (max-width: 899px) {
		@content;
	}
}
@mixin for-tablet-landscape-up {
	@media (min-width: 900px) {
		@content;
	}
}
@mixin for-tablet-landscape-only {
	@media (min-width: 900px) and (max-width: 1199px) {
		@content;
	}
}
@mixin for-desktop-up {
	@media (min-width: 1200px) {
		@content;
	}
}
@mixin for-desktop-only {
	@media (min-width: 1200px) and (max-width: 1799px) {
		@content;
	}
}
@mixin for-big-desktop-up {
	@media (min-width: 1800px) {
		@content;
	}
}
