.mainWrapper {
	display: flex;
	flex-flow: row wrap;
	gap: 24px;
}
.subWrapper {
	display: flex;
	flex-flow: column;
	width: 70%;
}

.leftSideTop {
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #ffffff;
	margin-bottom: 30px;
	border-radius: 10px;
	padding: 50px;
	font-size: 18px;
	color: #000000;
	@media (max-width: 600px) {
		padding: 10px;
	}
	.logo {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-bottom: 20px;
	}
	.content {
		margin: 10px 0px 20px;
		// font-family: Nunito;
		font-size: 18px;
		line-height: 24px;
	}
}
.leftSide {
	background: #ffffff;
	border-radius: 10px;
	padding: 50px;
	@media (max-width: 600px) {
		padding: 10px;
	}
	.content {
		.title {
			font-size: 25px;
			text-align: center;
			color: #000000;
			font-weight: bold;
		}
		.subTitle {
			font-size: 20px;
			text-align: center;
			color: #000000;
			font-weight: bold;
		}

		.row {
			margin-bottom: 5px;
			.bold {
				font-weight: bold;
				font-size: 18px;
				color: #000000;
			}
			.normal {
				font-size: 18px;
				color: #000000;
			}
			&:last-child {
				margin-bottom: 30px;
			}
		}
	}
}
.rightSide {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #ffffff;
	border-radius: 10px;
	padding: 40px 25px 40px 25px;
	.title {
		font-size: 25px;
		font-weight: bold;
		color: #000000;
		margin-bottom: 20px;
	}
	.link {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		// margin-bottom: 15px;
		> div {
			cursor: pointer;
			display: inline-block;
			min-width: 15px;
			> svg {
				max-width: 40px;
				max-height: 40px;
				min-width: 40px;
				min-height: 40px;
				color: var(--headerBackground);
			}
		}
		a {
			color: #000000;
			margin-left: 16px;
			font-weight: bold;
		}
		&.mail {
			//   margin-bottom: 30px;
		}
	}
}
@media (max-width: 1380px) {
	.mainWrapper {
		display: flex;
		flex-flow: row wrap;
		gap: 24px;
	}
	.subWrapper {
		display: flex;
		flex-flow: column;
		width: 100%;
	}
	.linkedInContainer {
		max-width: 100% !important;
		flex: 1 !important;
	}
	.rightSide {
		padding:0px;
		.title {
			display: none;
			
		}
		.link {
			text-align: center;
			div {
				font-size: 42px;
			}
			a {
				display: none;
			}
		}
	}
}
