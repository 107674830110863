.fileBox {
	padding: 20px;
	display: flex;
	align-items: center;
	background-color: #f7f6f3;
	border-radius: 10px;
	margin-top: 10px;
	cursor: pointer;
	.imageHolder {
		width: 64px;
		height: 64px;
		min-width: 64px;
		min-height: 64px;
		overflow: hidden;
		margin-right: 15px;
		display: flex;
		img {
			width: 100%;
		}
	}

	.fileInfo {
		flex-grow: 1;
		p {
			margin: 0;
			font-size: 18px;
			line-height: 27px;
			font-weight: 700;
			color: #131313;
		}

		span {
			font-size: 14px;
			line-height: 21px;
			font-weight: 700;
			color: rgba(19, 19, 19, 0.7);
		}
	}

	.date {
		p {
			margin: 0;
			font-size: 26px;
			line-height: 30px;
			font-weight: 700;
			color: #131313;
			text-align: right;
		}

		span {
			font-size: 14px;
			line-height: 21px;
			font-weight: 700;
			color: rgba(19, 19, 19, 0.7);
			text-align: right;
			display: block;
			text-transform: uppercase;
		}
	}

	&.active {
		background-color: var(--primaryColor);
		.fileInfo,
		.date {
			p {
				color: #fff;
			}
			span {
				color: rgba(299, 299, 299, 0.7);
			}
		}
	}
}
