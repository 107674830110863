@import "assets/scss/mixins.scss";

.communityBox {
	padding: 32px 24px;
	display: flex;
	// align-items: center;
	background: var(--white);
	border-radius: 8px;
	justify-content: space-between;
	position: relative;

	@include sm {
		flex-direction: column;
		gap: 8px;
		margin-top: 0px;
		border-radius: 8px;
		padding: 20px 10px;
	}

	.flexOneBox {
		display: flex;
		flex-direction: row;
		// align-items: center;
		justify-content: space-between;

		@include sm {
			width: 100% !important;
		}

		.imageTextWrapper {
			display: flex;
			// align-items: center;
		}

		.smallBlackImg {
			width: 17px;
			height: 14px;
			color: #000000;
		}
	}

	.imageHolder {
		width: 72px;
		height: 72px;
		min-width: 72px;
		min-height: 72px;
		// border-radius: 100%;
		border-radius: 8px;
		overflow: hidden;
		margin-right: 15px;

		img {
			width: 100%;
		}

		.textAvatar {
			display: flex;
			justify-content: center;
			align-items: center;
			font-family: "Lato", sans-serif;
			font-style: normal;
			font-weight: 700;
			font-size: 40px;
			height: 100%;
			width: 100%;
			color: #fff;
			background-color: var(
				--selectedCommunityToJoinPrimaryColor,
				var(--primaryColor)
			);
			border-color: var(
				--selectedCommunityToJoinPrimaryColor,
				var(--primaryColor)
			);
			border: none;
		}
	}

	.communityInfo {
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		.userIcon {
			display: flex;
			align-items: center;

			@include sm {
				margin-top: 5px;
			}
		}

		p {
			font-family: "Avenir-Heavy";
			margin: 0;
			font-size: 14px;
			line-height: 16px;
			font-family: "Nunito-Bold";
			color: #131313;
			max-width: 200px;

			@include sm {
				font-size: 14px;
				line-height: 15px;
				max-width: 100%;
			}
		}

		.badge {
			color: red;
			margin-top: 12px;
			display: flex;
			align-items: center;

			:global(.ant-badge-status-dot) {
				height: 10px;
				width: 10px;
			}

			@include sm {
				margin-top: 5px;

				span {
					font-size: 12px;
				}
			}
		}

		.count {
			font-size: 12px;
			line-height: 16px;
			font-family: Nunito-Medium;
			margin-left: 8px;
			color: #9ca1ba;
		}

		.detail {
			margin-top: 14px;
			font-family: Nunito-Regular;
			font-style: normal;
			font-size: 14px;
			line-height: 20px;

			@include sm {
				margin-top: 5px;
			}
		}

		.charityTagWrapper {
			margin-top: 12px;
			display: flex;
			align-items: center;

			@include sm {
				margin-top: 5px;
			}

			.charityText {
				margin-left: 6px;

				@include sm {
					font-size: 14px;
				}
			}

			.ant-tag {
				height: 20px !important;
			}
		}

		span {
			font-size: 14px;
			line-height: 20px;

			font-family: "Nunito-Regular";
			color: rgba(19, 19, 19, 0.7);

			@include sm {
				font-size: 10px;
			}
		}
	}

	.buttonContainer {
		white-space: nowrap;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		gap: 10px;
		position: absolute;
		right: 24px;

		@include sm {
			// width: 100%;
			right: 0px;

			position: relative;
			bottom: 0px;
		}

		.leaveButton {
			max-width: 55px;
			padding: 0px;
			border-color: #4d5a81;

			.leaveIcon {
				fill: #4d5a81;
			}

			&:hover {
				border-color: #4d5a81 !important;
			}
		}

		button {
			box-sizing: border-box;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 10px 24px;
			gap: 4px;
			width: 100%;
			width: 160px;
			height: 40px;
			// color: rgba(var(--selectedCommunityToJoinHeaderBackgroundRgb,
			// 			var(--primaryColorRgb)),
			// 		1);
			// border-color: rgba(var(--selectedCommunityToJoinHeaderBackgroundRgb,
			// 			var(--primaryColorRgb)),
			// 		1);
			border-radius: 8px;
			flex: none;
			order: 0;
			align-self: stretch;
			flex-grow: 1;

			// &:hover {
			// 	transform: scale(1.1);
			// }

			@include sm {
				min-width: unset;
				width: 100px;
				height: 35px;
				font-size: 10px;
			}
		}

		.approvedButton {
			width: 96px;
		}

		.joinCancelButtons {
			width: 100%;

			.joinedButton {
				button {
					font-size: 14px;
					color: #9ca1ba;
					border: 1px solid #dde1e4;

					@include sm {
						width: 100%;
					}
				}
			}

			.cancelButton {
				display: none;

				button {
					color: white;
					background-color: #d82140 !important;

					&:hover,
					&:active,
					&:focus {
						color: white;
						background-color: #d82140;
					}

					@include sm {
						width: 100%;
					}
				}
			}

			&:hover {
				.joinedButton {
					display: none;
				}

				.cancelButton {
					display: block;

					button {
						color: white;
						background-color: #d82140;
						border: none;
					}
				}
			}
		}
	}

	&.active {
		background-color: #1fbed7;

		.memberInfo {
			span,
			p {
				color: rgba(299, 299, 299, 0.7);
			}
		}
	}
}

.communityBox:last-child {
	border: none;
}

// .leaveButton {
// 	max-width: 65px;

// 	// border: 1px solid rgba(var(--selectedCommunityToJoinHeaderBackgroundRgb,
// 	// 			var(--primaryColorRgb)),
// 	// 		1);
// 	// border-radius: 8px;
// }
