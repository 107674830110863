@import "assets/scss/mixins.scss";

.tagFiltersContainer {
	// margin-bottom: 20px;
	position: relative;
	min-height: 70px;

	@include extra-small {
		min-height: 60px;
	}
}

.settingPanel {
	width: 300px;
	height: 104px;
	border-radius: 8px;
	background-color: white;
	position: absolute;
	right: 55px;
	top: 170px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	@include extra-small {
		top: 125px;
		right: 45px;
	}
	.directoryOptInDiv {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.directoryOptIn {
			font-family: Nunito-Regular;
			font-size: 14px;
			font-weight: 400;
			line-height: 14px;
			letter-spacing: 0em;
		}
		.switchDiv {
			display: flex;
			gap: 10px;
			align-items: center;
		}
		.switchState {
			font-family: Nunito-Regular;
			font-size: 14px;
			font-weight: 400;
			line-height: 1px;
			letter-spacing: 0em;
		}
	}
	.settings {
		font-family: Avenir-Heavy;
		font-size: 16px;
		font-weight: 500;
		line-height: 16px;
		letter-spacing: 0em;
		text-align: left;
	}
	.closeSettingPanel {
		position: absolute;
		right: 10px;
		top: 10px;
		cursor: pointer;
	}
}
