.container {
	.flexRow {
		display: flex;
		flex-direction: row;
		flex: 1;
		align-items: center;
	}
	.avatar {
		width: 50px;
		height: 50px;
		img {
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}
	.inputTextWrapper {
		margin: 0px 10px;
		display: flex;
		flex-direction: row;
		flex: 1;
		.inputElement {
			border-radius: 28px;
			box-shadow: none;
			&:hover {
				border-color: var(--headerBackground);
				outline: unset;
			}
			&:focus {
				border-color: var(--headerBackground);
				outline: unset;
			}
		}
	}
}
