@import "assets/scss/mixins.scss";

.container {
	display: flex;
	flex-direction: column;
	flex: 1;

	.boldText {
		font-family: "Avenir-Heavy", sans-serif;
		color: #03142d;
		font-size: 18px;
		width: fit-content;

		@include for-phone-only {
			font-size: 16px;
		}
	}

	.whiteAndRoundedBackgroundWrapper {
		background: #ffffff;
		border-radius: 20px;
		margin: 24px 0px;
		padding: 28px;
	}
}
