.customRadioButton {

	:global(.ant-radio-wrapper.ant-radio-wrapper-checked) {
		background-color: rgba(var(--selectedCommunityToJoinPrimaryColorRgb,
					var(--primaryColorRgb)),
				$alpha: 0.3);
		;
		border-color: var(--selectedCommunityToJoinPrimaryColor,
				var(--primaryColor));

	}

	:global(.ant-radio) {
		top: 0px;
	}

	:global(.ant-radio:hover .ant-radio-inner) {
		border-color: var(--selectedCommunityToJoinPrimaryColor,
				var(--primaryColor));
		;
	}

	:global(.ant-radio-wrapper) {
		padding: 0px 12px !important;
		margin-right: 0px;
		border: 1px solid;
		width: fit-content;
		border-radius: 8px;
		border-color: #DDE1E4;
		font-size: 14px;
		font-weight: 400;
		font-family: 'Nunito-Regular';
		color: #4D5A81;
		align-items: center;
		height: 32px;

		span.ant-radio+* {
			padding-right: 0;
		}
	}


	:global(.ant-radio-checked .ant-radio-inner) {
		border-color: transparent !important;
		background: var(--selectedCommunityToJoinPrimaryColor,
				var(--primaryColor));

		.ant-radio-checked::after {
			border: none;

		}
	}

	;

	:global(.ant-radio-checked .ant-radio-inner::after) {
		transform: scale(0.4) !important;
		background-color: white;

		.radioBox {
			background-color: rgba(var(--selectedCommunityToJoinPrimaryColorRgb,
						var(--primaryColorRgb)),
					$alpha: 0.3);
		}

	}
}