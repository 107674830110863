@import "assets/scss/mixins.scss";

.emptyInvitesContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 16px;
	height: calc(100vh - 64px);

	@include sm {
		padding-left: 20px;
		padding-right: 20px;
		width: 100%;
	}

	.titleContainer {
		padding-top: 8px;
		text-align: center;
	}

	.title {
		font-family: "Avenir-Medium";
		font-size: 24px;
		font-weight: 500;
		line-height: 32px;
		color: #292f45;
	}

	.description {
		font-family: 'Nunito-Regular';
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		color: #4D5A81;
		margin: 0;
		max-width: 552px;
		width: 100%;
	}

	.buttonContainer {
		display: flex;
		justify-content: center;
		gap: 16px;
		width: 100%;

		button {
			width: 172px;
		}
	}
}