@import "assets/scss/mixins.scss";

.container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	.roundLogo {
		position: relative;
		width: 250px;
		height: 100px;

		:global(.cls-1) {
			fill: #009cb9;
		}

		:global(.cls-2) {
			fill: #009cb9;
		}

		@include sm {
			height: 100px;
			width: 200px;
		}
	}

	.communityLogo {
		height: 150px;
		width: 150px;
		object-fit: contain;
		border-radius: 50%;

		@include sm {
			height: 100px;
			width: 100px;
		}
	}

	.authenticationLayout {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex-grow: 1;
		margin: 20px 0;
		@include sm {
			align-items: unset;
			justify-content: unset;
			width: 100%;
			padding: 0px 20px;
		}
		> div > form {
			display: flex;
			flex-direction: column;

			> div {
				margin-top: 5px;
			}
			a {
				font-weight: bold;
			}
		}
	}
}
