@import "assets/scss/index.scss";

.feauturedEvents {
	border-radius: 8px;
	font-size: 24px;
	display: flex;
	flex: 1;
	flex-direction: column;
	height: 100%;

	.topSection {
		padding: 23px 24px;
		border-radius: 8px;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		@include extra-small {
			padding: 11px 10px;
		}

		h3 {
			margin: 0;
			font-family: "Avenir-Medium";
			color: #000;
			font-size: 24px;
			line-height: 100%;
			flex-grow: 1;

			@media (max-width: 600px) {
				font-size: 16px;
			}
		}
	}

	.buttonContainer {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		width: 100%;
		flex-wrap: wrap;
		background-color: transparent;
		color: var(--primaryColor);
		align-content: center;
		margin: 13px 0;

		.button {
			line-height: 30px;
			padding-left: 10px !important;

			&:global(.ant-btn) {
				font-weight: 700;
				font-size: 14px;

				border: none;
				color: var(--primaryColor);

				border-radius: 20px;
				padding: 0 18px;
				line-height: 30px;

				text-align: center;

				&:hover,
				&:focus {
					background-color: transparent !important;
					color: var(--primaryColor) !important;
				}
			}

			&.activeButton {
				&:global(.ant-btn) {
				}
			}
		}

		.button:last-child {
			margin-right: 0px !important;
		}

		.disabledButton {
			background: #e3ecf1;
		}
	}

	.eventInfo {
		display: flex;

		.eventImg {
			max-width: 300px;

			img {
				width: 100%;
			}
		}

		.eventTitleInfo {
			padding-left: 32px !important;

			h2 {
				margin: 20px 0 0;

				font-size: 18px;
				line-height: 20px;
				font-weight: 700;
			}

			p {
				font-size: 12px;
				line-height: 20px;
				font-weight: normal;
				margin: 0;
				word-break: break-all;
			}

			span {
				font-size: 12px;
				line-height: 20px;
				font-weight: normal;

				img {
					display: inline-block;
					margin-top: -2px;
				}
			}
		}
	}

	.eventContent {
		padding: 32px 0 30px;

		p {
			margin: 0;

			line-height: 20px;
			font-weight: normal;
		}
	}

	.mapInfoBox {
		padding: 30px;
		background: #f7f6f3;
		border-radius: 8px;

		h5 {
			margin: 0;
			font-size: 20px;
			line-height: 25px;
			font-weight: 700;
			color: #272727;
			padding: 0 50px;
		}

		p {
			margin: 0;
			font-size: 16px;
			line-height: 25px;
			font-weight: normal;
			color: #272727;
			padding: 0 50px;
			position: relative;

			img {
				position: absolute;
				top: 5px;
				left: 33px;
			}
		}

		iframe {
			border: 0;
			width: 100%;
			height: 373px;
			display: block;
			margin-top: 25px;
		}
	}
}
