@import "assets/scss/mixins.scss";

.groupBox:last-child {
	margin-bottom: 48px;
}

.groupBox {
	border-radius: 8px;
	max-width: 1024px;
	margin: 0 auto;

	.groupContainer {
		display: flex;
		overflow: hidden;
		flex-direction: row;
		align-items: flex-start;
		padding: 32px 24px;
		gap: 12px;
		background: #ffffff;
		box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
		border-radius: 8px;
		margin-bottom: 10px;
		min-height: 20px;

		@include sm {
			padding: 24px 16px;
		}

		.avatar {
			font-family: "Avenir Heavy", sans-serif;
			width: 150px;
			height: 150px;
			background-color: var(--headerBackground);
			border-radius: 8px;
			overflow: hidden;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			font-weight: bold;
			font-size: 48px;

			@include sm {
				width: 50px;
				height: 50px;
				font-size: 25px;
			}
		}

		.groupInfo {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 0px;
			gap: 12px;
			width: calc(100% - 150px);
			// width: 420px;
			flex: none;
			order: 1;
			flex-grow: 1;
			position: relative;

			@include sm {
				gap: 0px;
			}

			.content {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				padding: 0px;
				gap: 16px;
				width: 100;
				flex: none;
				order: 0;
				align-self: stretch;
				flex-grow: 0;
			}

			.titleDetails {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding: 0px;
				gap: 8px;
				flex: none;
				order: 0;
				flex-grow: 1;

				@include sm {
					gap: 0px;
				}

				.title {
					font-style: normal;
					font-weight: 700;
					font-size: 14px;
					line-height: 20px;
					color: #292f45;
					font-family: "Avenir Heavy", sans-serif;
				}

				.titleWrap {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					padding: 0px;
					flex: none;
					order: 0;
					align-self: stretch;
					flex-grow: 0;
				}

				.subtitle {
					display: flex;
					flex-direction: row;
					align-items: center;
					padding: 2px 0px;
					gap: 4px;
					font-style: normal;
					font-weight: 400;
					font-size: 12px;
					line-height: 16px;
					color: #9ca1ba;
					flex: none;
					order: 1;
					flex-grow: 1;
				}

				.groupStatus {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					padding: 0px;
					gap: 4px;
					border-radius: 36px;
				}
			}

			.description {
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
				color: #4d5a81;
				flex: none;
				order: 1;
				align-self: stretch;
				flex-grow: 0;
				overflow: hidden;

				@include sm {
					font-size: 12px;
				}
			}

			.tagsContainer {
				padding-right: 45px;
				overflow: hidden;
				text-overflow: ellipsis;

				small {
					color: var(--primaryColor);
					font-size: 12px;
					line-height: 12px;
					font-weight: normal;
				}

				span {
					font-size: 14px;
					line-height: 21px;
					font-weight: normal;
					color: rgba(0, 0, 0, 0.7);
				}

				margin-bottom: 5px;

				.tag {
					cursor: pointer;

					&:not(:first-child) {
						margin-left: 5px;
					}

					font-family: "Nunito-Regular";
					font-style: normal;
					font-weight: 00;
					font-size: 14px;
					line-height: 20px;
				}
			}

			.buttonsSection {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				padding: 0px;

				@include sm {
					gap: 15px;
					width: unset;
					margin-top: 5px;
				}

				.rightButtons {
					position: absolute;
					flex-grow: 1;
					top: 0px;
					width: 100%;
					display: flex;
					justify-content: flex-end;

					@include sm {
						right: 0px;
						position: relative;

					}

					.requestStatus {
						margin-left: 10px;
						font-weight: bold;
						text-transform: uppercase;
					}

					button {
						box-sizing: border-box;
						display: flex;
						flex-direction: row;
						justify-content: center;
						align-items: center;
						padding: 10px 24px;
						gap: 4px;
						width: 100%;
						// width: 160px;
						height: 40px;

						color: rgba(var(--selectedCommunityToJoinHeaderBackgroundRgb,
									var(--primaryColorRgb)),
								1);
						border-color: rgba(var(--selectedCommunityToJoinHeaderBackgroundRgb,
									var(--primaryColorRgb)),
								1);
						border-radius: 8px;

						&:hover,
						&:focus,
						&:active {
							color: #ffffff,

						}

						flex: none;
						order: 0;
						align-self: stretch;
						flex-grow: 1;

						@include sm {
							min-width: unset;
							width: 100% !important;
							height: 35px;
							font-size: 10px;
						}
					}

					.joinButton {
						display: flex;
						max-width: 160px;
						min-width: 160px;
						justify-content: space-between;
						// margin-right: 15px;
						gap: 5px;

						@include sm {
							padding: 3px;
							max-width: 100% !important;
							min-width: 100% !important;
						}


						:global(.ant-btn-group.ant-dropdown-button) {
							display: flex;
							flex: 1;

							button {
								border-radius: 20px;
							}
						}



						button {
							// color: #fff;
							min-width: unset;
							max-width: 200px;

							.requestSvg {
								path {
									stroke: rgba(var(--selectedCommunityToJoinHeaderBackgroundRgb,
												var(--primaryColorRgb)),
											1)
								}

								font-size: 14px
							}

							&:hover,
							&:focus,
							&:active {
								.requestSvg {
									path {
										stroke: #ffffff
									}

								}
							}


							@include sm {
								// max-width: 50% !important;
								min-width: 100% !important
							}

							border-radius: 8px;
							// background-color: var(--green);
							margin-left: unset;

							&:hover,
							&:focus,
							&:active {
								transform: scale(1.05);

								@include sm {
									transform: scale(1.02);

								}
							}

							&:global(#enter-dropdown-right-button) {
								border-top-left-radius: unset;
								border-bottom-left-radius: unset;
								padding: 0px 10px;
								display: flex;
								flex-direction: row;
								justify-content: center;
								align-items: center;
								width: unset;
							}

							&:global(#enter-dropdown-left-button) {
								border-top-right-radius: unset;
								border-bottom-right-radius: unset;
							}
						}

						@include sm {
							display: flex !important;
							max-width: 100px;

							button:first-child {
								max-width: 30px;
							}

							button:last-child {
								max-width: 65px;
								padding: 0;
								font-weight: bold;
								font-size: 14px;
							}
						}
					}

					.requestToJoin {
						@extend .joinButton;
						justify-content: center;

						@include sm {
							button {
								max-width: 100% !important;
							}
						}
					}

					.enterButton {
						display: flex;
						max-width: 160px;
						min-width: 160px;
						justify-content: space-between;
						// margin-right: 15px;
						gap: 5px;

						@include sm {
							padding: 3px;
							max-width: 100% !important;
							min-width: 100% !important;
						}

						:global(.ant-btn-group.ant-dropdown-button) {
							display: flex;
							flex: 1;

							button {
								border-radius: 20px;
							}
						}

						button {
							// color: #fff;
							min-width: unset;
							max-width: 105px;

							@include sm {
								max-width: 49% !important;
							}

							border-radius: 8px;
							// background-color: var(--green);
							margin-left: unset;

							&:hover,
							&:focus,
							&:active {
								transform: scale(1.05);

								@include sm {
									transform: scale(1.02);

								}
							}

							&:global(#enter-dropdown-right-button) {
								border-top-left-radius: unset;
								border-bottom-left-radius: unset;
								padding: 0px 10px;
								display: flex;
								flex-direction: row;
								justify-content: center;
								align-items: center;
								width: unset;
							}

							&:global(#enter-dropdown-left-button) {
								border-top-right-radius: unset;
								border-bottom-right-radius: unset;
							}
						}

						button:first-child {
							max-width: 50px;
							padding: 0;
						}

						@include sm {
							max-width: 100px;

							button:first-child {
								max-width: 30px;
							}

							button:last-child {
								max-width: 65px;
								padding: 0;
								font-weight: bold;
								font-size: 14px;
							}
						}
					}

					.cancelButton {
						width: 150px;

						@include sm {
							width: 100%;
							justify-content: center;
							padding: 3px;
						}

						button {
							padding: 0;
							gap: 5px;
							background-color: #d82140;
							color: #ffffff;

							@include sm {
								width: 100%;
								font-weight: bold;
								font-size: 14px;
							}

							&:hover,
							&:focus,
							&:active {
								transform: scale(1.05);

								@include sm {
									transform: scale(1.02);

								}

								border-radius: 8px;
							}
						}
					}
				}
			}
		}
	}
}

.leaveButton {
	width: 90px;
	font-size: 16px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	font-family: "Avenir Heavy", sans-serif;
}