@import '../../../../assets/scss/mixins.scss';
.flexRowSpaceBetween {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 10px;
}
.paymentDetail {
	padding-left: 15px;
	padding-right: 30px;
	@include sm {
		padding:0px
	}
}

// .mainColorBtn {
// 	background: var(--primaryColor) !important;
// 	border: 1px solid !important;
// 	border-radius: 20px !important;
// 	font-weight: bold !important;
// 	&:hover {
// 		background-color: rgba(
// 			$color: var(--primaryColorRgb),
// 			$alpha: 0.7
// 		) !important;
// 	}
// }
.divBtnAndText {
	display: flex;
	flex-direction: row;
	cursor: pointer;
	align-items: center;
	font-size: 16px;
	@include sm {
		margin-bottom: 10px;
		.addPayment {
			display: none;
		}
		
	}
	&:hover {
		// padding: 0px 4px;
		background-color: #fafafa;
		border-radius: 8px;
	}
	:global(#btn-text) {
		align-self: center;
		margin-left: 6px;
	}
}
