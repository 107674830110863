.searchBar {
	background-color: #fff;
	padding: 9px 30px;
	border-radius: 8px;
	margin-bottom: 20px;

	:global {
		.ant-form-item {
			margin-bottom: 0;
		}

		.ant-input {
			font-weight: normal;
			font-size: 16px;
			color: #131313;
			border: 2px solid #eaeaea;
			border-radius: 8px;
			padding: 13px 20px;
			line-height: 24px;
			height: 50px;
			&::placeholder {
				color: #131313;
				opacity: 1;
			}

			&:-ms-input-placeholder {
				color: #131313;
			}

			&::-ms-input-placeholder {
				color: #131313;
			}
		}

		.ant-btn {
			font-weight: 700;
			font-size: 16px;
			border: none;
			color: #fff;
			background-color: var(--primaryColor);
			border-radius: 8px;
			padding: 13px 20px;
			line-height: 24px;
			height: 50px;
			text-align: center;
			width: 100%;

			&:hover,
			&:focus,
			&:active {
				background-color: #1d9abe;
				color: #fff;
			}
		}
	}
}
