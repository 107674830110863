@import "assets/scss/mixins.scss";

.checkBoxGroup {
	:global(.ant-checkbox) {
		display: none;
	}
	:global(.ant-checkbox + span) {
		display: flex;
		border: 1px solid var(--primaryColor) !important;
		border-radius: 25px !important;
		padding: 4px 15px !important;
		font-size: 13px !important;
		font-weight: 900;
		color: var(--primaryColor) !important;
		line-height: 20px !important;
		margin: 3px 0px !important;
	}
	:global(.ant-checkbox.ant-checkbox-checked + span) {
		background: var(--primaryColor) !important;
		color: #ffffff !important;
	}
	&.accents {
		font-family: "Avenir Light", sans-serif;
	}
}
.checkBoxGroupGhost {
	:global(.ant-checkbox) {
		display: none;
	}
	:global(.ant-checkbox + span) {
		display: flex;
		font-size: 14px !important;
		font-weight: 300;
		color: #444444 !important;
		line-height: 14px !important;
		margin: 3px 0px !important;
	}
}
.tagTitle {
	color: var(--announcementHeaderBackground);
	margin-bottom: 5px;
	line-height: 40px;
	font-family: "Avenir-Heavy";
	&.required {
		&::before {
			display: inline-block;
			margin-right: 4px;
			color: #ff4d4f;
			font-size: 14px;
			line-height: 1;
			content: "*";
		}
	}
}
.headerTags {
	display: flex !important;
	flex: 1 !important;
	flex-direction: row !important;
	> div:last-child {
		margin-bottom: 5px !important;
	}
}
.allNoneContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-family: "Avenir Heavy", sans-serif;
	@include for-desktop-up {
		margin-left: 30px;
	}

	div {
		max-height: 20px !important;
		min-height: 20px !important;
		margin-bottom: 0 !important;
	}
}
