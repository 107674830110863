@import "assets/scss/index.scss";

.container {
	background: rgba(
		$color:
			var(
				--selectedCommunityToJoinPrimaryColorRgb,
				var(--primaryColorRgb)
			),
		$alpha: 0.15
	);
	border-radius: 8px;
	margin: 20px 0px;
	.uploadSection {
		display: flex;
		flex-direction: row;
		padding: 18px 20px 16px;
		align-items: center;
		.avatarUploader {
			display: flex;
			&:global(.ant-upload-picture-card-wrapper) {
				width: unset;
			}
			> div {
				border-radius: 50%;
				border-style: solid;
				> span {
					width: 104px;
					height: 104px;
					padding: 0;
					@include sm {
						height: 70px;
						width: 70px;
					}
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						border-radius: 50%;
						@include sm {
							height: 25px;
						}
					}
				}
			}
		}
		.boldCenteredText {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			flex: 1 1;
			font-size: 18px;
			font-weight: bold;
			padding: 18px;
			@include sm {
				font-size: 13px;
			}
			.asteriskGreyText {
				color: rgba(0, 0, 0, 0.6);
				font-style: italic;
				position: relative;
				font-weight: 200;
				&::before {
					content: "*";
					position: absolute;
					top: 2px;
					font-size: 16px;
					left: -8px;
				}
			}
		}
		.uploadIconDiv {
			position: relative;
			display: flex;
		}
		.profilePictureLabel {
			div:first-of-type {
				font-size: 14px;
				font-weight: 600;
			}
			span:first-of-type {
				font-size: 12px;
				color: #c4c4c4;
				font-style: italic;
			}
		}
	}
}
@include sm {

	:global(.ant-upload.ant-upload-select-picture-card) {
		height: 70px !important;
		width: 70px !important;
	}
}