@import '../../../../assets/scss/mixins.scss';
.normalText {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.container {
	display: flex;
	flex-direction: column;
	padding: 30px 15px 30px 15px;
	flex-grow: 1;
	align-items: flex-start;
	justify-content: flex-end;
	position: relative !important;
@include sm {
	padding: 10px 15px 10px 15px;
	
}
	> div:first-child {
		width: 100%;

		> div:first-child {
		}
	}
	.ScrollbarsCustom_Content {
		box-sizing: border-box;
		min-height: 100%;
		min-width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding:0.05px 12px 0.05px 22px;
		overflow: hidden;
	}
}
