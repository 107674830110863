@import "../../../../assets/scss/mixins.scss";

.container {
	width: 100%;
	display: flex;
	margin-right: 20px;

	@include extra-small {
		width: 100%;
		place-content: end;
		margin-right: 0px;
	}

	.searchIcon {
		display: none;



		@include extra-small {
			display: block;


			fill: var(--primaryColor);

		}
	}

	.searchInput {
		// border-radius: 20px !important;
		width: 280px;
		max-width: 800px !important;
		transition: all 0.3s;
		display: flex;

		@include extra-small {
			display: none;
			width: 100% !important;
		}

		svg {
			fill: var(--primaryColor);
		}

		:global(.ant-input-affix-wrapper) {
			border-radius: 8px;
			border: 1px solid #F3F3F3;
			background: var(--white, #FFF);
			padding: 10px 12px;
		}

		:global(.ant-input) {


			&::placeholder {
				font-family: 'Nunito-Regular';
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
				color: var(--primaryColor);
			}
		}



		@include extra-small {
			width: 100% !important;
		}
	}

	.searchInputMobile {
		// border-radius: 20px !important;
		width: 100%;
		max-width: 800px !important;
		transition: all 0.3s;
		display: flex;



		svg {
			fill: var(--primaryColor);
		}

		:global(.ant-input-affix-wrapper) {
			border-radius: 8px;
			border: 1px solid #F3F3F3;
			background: var(--white, #FFF);
			padding: 10px 12px;
		}

		:global(.ant-input) {


			&::placeholder {
				font-family: 'Nunito-Regular';
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
				color: var(--primaryColor);
			}
		}



		@include extra-small {
			min-width: 100% !important;
			width: 100%;
		}
	}
}