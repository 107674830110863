@import "assets/scss/index.scss";

.container {
	display: none !important;
	flex: 1;
	max-width: 100%;
	height: 100%;

	:global(.rbc-date-cell:last-child) {
		button {
			font-family: "Avenir Light", sans-serif;
			color: black;
		}
	}

	:global(.rbc-date-cell:nth-last-child(2)) {
		button {
			font-family: "Avenir Light", sans-serif;
			color: black;
		}
	}

	@include extra-small {
		:global(.rbc-date-cell:last-child) {
			button {
				font-family: "Avenir Light", sans-serif;
				color: black;
			}
		}

		:global(.rbc-date-cell:nth-last-child(2)) {
			button {
				font-family: "Avenir Light", sans-serif;
				color: black;
			}
		}
	}

	:global(.rbc-date-cell) {
		padding: 14px 19px 0px 0px;

		@include extra-small {
			padding: 16px 0px !important;
			text-align: center;
			height: 0px;
			color: black;
		}
	}

	@media only screen and (min-width: 998px) {
		display: flex !important;
		padding-bottom: 0px;
	}

	@media (max-width: 600px) {
		:global(.styles_buttonsContainer__jHNuD) {
			gap: 10px;
		}

		:global(.styles_horizontalyButtonsContainer__CrBqh) {
			gap: 10px;
		}

		:global(.styles_btn__bTDBH) {
			border-radius: 4px;
			border-right: 1px solid #00000040 !important;
			min-width: 60px;
			text-align: center;
		}

		:global(.rbc-row-content) {
			z-index: 0;
		}
	}

	:global(.rbc-month-view .styles_cellContainer__DMJSk) {
		margin: 0px;
	}

	:global(.styles_container__PN\+uU) {
		background-color: #fff;
		border-radius: 8px;
	}

	// TODO: Clean this file. ant-picker is not used anymore
	:global(.ant-picker-cell-inner.ant-picker-calendar-date) {
		background: #f7f6f3 !important;
		border-radius: 5px !important;
		margin: 2px 3px !important;
		height: 75px !important;
		padding: 0px !important;
		display: flex !important;
		flex-direction: column !important;

		.weekDay {
			height: 100%;
		}
	}

	:global(.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected) {
		:global(.ant-picker-cell-inner.ant-picker-calendar-date) {
			background: var(--announcementHeaderBackground) !important;
			z-index: 2;
		}
	}

	:global(.ant-picker-calendar-date-content) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: unset !important;
		flex: 1 !important;
	}

	:global(.ant-picker-calendar-date-value) {
		display: flex;
		padding-left: 10px;
		font-weight: 700;
	}

	tr > td:nth-last-child(-n + 2) {
		:global(.ant-picker-cell-inner.ant-picker-calendar-date) {
			background: #f6e9e9 !important;
			padding: unset !important;
		}
	}

	display: flex;

	.eventCount {
		font-size: 9px !important;
		color: var(--primaryColor);
		font-weight: 900;
		display: flex;
		justify-content: center;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		padding-top: 30px;
	}

	.calendar {
		&.inacticeCalendar {
			:global(.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected) {
				:global(.ant-picker-cell-inner.ant-picker-calendar-date) {
					background: #f7f6f3 !important;
					z-index: 2;
				}
			}
		}
	}
}

.mobileContainer {
	flex: 1;
	max-width: 100%;
	padding-bottom: 30px;
	display: none;
	height: 100%;

	:global(.rbc-date-cell:last-child) {
		button {
			font-family: "Avenir Light", sans-serif;
			color: black;
		}
	}

	:global(.rbc-date-cell:nth-last-child(2)) {
		button {
			font-family: "Avenir Light", sans-serif;
			color: black;
		}
	}

	@include extra-small {
		:global(.rbc-date-cell:last-child) {
			button {
				font-family: "Avenir Light", sans-serif;
				color: black;
			}
		}

		:global(.rbc-date-cell:nth-last-child(2)) {
			button {
				font-family: "Avenir Light", sans-serif;
				color: black;
			}
		}
	}

	:global(.rbc-date-cell) {
		padding: 14px 19px 0px 0px;

		@include extra-small {
			padding: 16px 0px !important;
			text-align: center;
			height: 0px;
			color: black;
		}
	}

	@media only screen and (max-width: 998px) {
		padding-bottom: 0px;
		display: flex !important;

		:global(.styles_buttonsContainer__jHNuD) {
			gap: 10px;
		}

		:global(.styles_horizontalyButtonsContainer__CrBqh) {
			gap: 10px;
		}

		:global(.styles_btn__bTDBH) {
			border-radius: 4px;
			border-right: 1px solid #00000040 !important;
			min-width: 60px;
			text-align: center;
		}

		:global(.rbc-row-content) {
			z-index: 0;
		}
	}

	:global(.rbc-month-view .styles_cellContainer__DMJSk) {
		margin: 0px;
	}

	:global(.rbc-month-row) {
		&:last-child {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
		}

		&:nth-child(2) {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}
	}

	:global(.styles_container__PN\+uU) {
		background-color: #fff;
		border-radius: 8px;
	}

	// TODO: Clean this file. ant-picker is not used anymore
	:global(.ant-picker-cell-inner.ant-picker-calendar-date) {
		background: #f7f6f3 !important;
		border-radius: 5px !important;
		margin: 2px 3px !important;
		height: 75px !important;
		padding: 0px !important;
		display: flex !important;
		flex-direction: column !important;

		.weekDay {
			height: 100%;
		}
	}

	:global(.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected) {
		:global(.ant-picker-cell-inner.ant-picker-calendar-date) {
			background: var(--announcementHeaderBackground) !important;
			z-index: 2;
		}
	}

	:global(.ant-picker-calendar-date-content) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: unset !important;
		flex: 1 !important;
	}

	:global(.ant-picker-calendar-date-value) {
		display: flex;
		padding-left: 10px;
		font-weight: 700;
	}

	tr > td:nth-last-child(-n + 2) {
		:global(.ant-picker-cell-inner.ant-picker-calendar-date) {
			background: #f6e9e9 !important;
			padding: unset !important;
		}
	}

	.eventCount {
		font-size: 9px !important;
		color: var(--primaryColor);
		font-weight: 900;
		display: flex;
		justify-content: center;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		padding-top: 30px;
	}

	.calendar {
		&.inacticeCalendar {
			:global(.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected) {
				:global(.ant-picker-cell-inner.ant-picker-calendar-date) {
					background: #f7f6f3 !important;
					z-index: 2;
				}
			}
		}
	}
}

:global(.ant-picker-calendar-full
		.ant-picker-panel
		.ant-picker-calendar-date-today) {
	border-color: var(--announcementHeaderBackground) !important;

	:global(.ant-picker-calendar-date-value) {
		color: var(--announcementHeaderBackground) !important;
	}
}

:global(.rbc-button-link) {
	font-family: "Avenir Light", sans-serif;
}

:global(.rbc-header + .rbc-header) {
	display: flex;
	height: 47px;
	border: none;
	align-items: center;
	color: var(--primaryColor);
	font-size: 16px;
	font-family: "Avenir-Medium";
	font-weight: 500;

	@include extra-small {
		font-size: 14px;
	}
}

:global(.rbc-header) {
	padding-left: 15px;
	display: flex;
	height: 47px;
	border: none;
	align-items: center;
	color: var(--primaryColor);
	font-size: 16px;
	font-family: "Avenir-Medium";
	font-weight: 500;

	@include extra-small {
		font-size: 14px;
	}
}

:global(.rbc-month-header) {
	padding: 8px;
}

:global(.rbc-month-row) {
	&:last-child {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	&:nth-child(2) {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}
}

:global(.rbc-row) {
	&:last-child {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	&:nth-child(2) {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}
}

:global(.rbc-row-bg) {
	gap: 4px;

	@include extra-small {
		gap: 2px;
	}
}
