@import 'assets/scss/mixins.scss';
.menuList {
	color: var(--white);
	background: var(--headerBackground);
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-right: 20px;
	z-index: 1;
	font-family: "Avenir Heavy", sans-serif;
	.item {
		margin: 7px 10px;
		padding: 0px 10px;
		font-weight: bold;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		&.itemActive {
			margin-bottom: 0px;
		}
		border-top: 1px #ffffff5c solid;
		padding-top: 12px;
		.info {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.name {
				display: flex;
				flex-direction: column;
				justify-content: center;
				&:hover {
					text-decoration: underline;
				}
			}
			.avatar {
				width: 40px;
				height: 40px;
				border-radius: 100%;
				overflow: hidden;
				flex-shrink: 0;

				img {
					max-width: 100%;
				}
			}
		}
		.activeCommunity {
			color: #b7b6b6;
			font-style: italic;
			font-size: 9px;
			text-align: center;
		}
	}
	.joinMore {
		font-weight: 700;
		font-size: 14px;
		border: none;
		color: #fff;
		// background-color: #1FBED7;
		width: 80%;
		background-color: #000;
		border-radius: 25px;
		padding: 13px 20px;
		
		line-height: 24px;
		height: 50px;
		text-align: center;
		margin-top: 20px;
		margin-left: auto;
		margin-right: auto;
		@include sm {
			margin-left: 15px;
		margin-right: 0px;
		margin-bottom: 15px;
		width: 100%;
		}
	}
}
