@import "assets/scss/mixins.scss";

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
	width: 100%;

	.titleContainer {
		font-family: "Avenir Heavy", sans-serif;
		font-weight: bold;
		color: #ffffff;
		cursor: pointer;
		width: 100%;
		padding: 10px;
		justify-content: center;
		display: flex;
		flex-direction: row;

		.icon {
			position: absolute;
			left: 15px;
			top: 15px;
			color: #ffffff;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			svg {
				fill: #ffffff;

				path {
					stroke: #ffffff;
				}
			}
		}

		.flexColumnContainer {
			display: flex;
			flex-direction: column;
			flex: 1;

			.filterInput {
				height: 40px;
				font-family: "Nunito-Regular";
			}

			div:first-child {
				align-self: "flex-start";
				margin-left: 0;
				margin-right: "auto";
				text-decoration: "none";
			}

			div:nth-child(2) {
				z-index: 2;
				margin: 16px 10px 10px;

				input {
					border-radius: 20px;
				}
			}
		}
	}
}
