@import "assets/scss/mixins.scss";

.sideBar {
	width: 300px !important;
	max-width: 300px !important;
	overflow: visible !important;
	flex: 0 0 300px !important;
	background: var(--headerBackground) !important;

	@include extra-small {
		max-width: 100% !important;
		flex: 0 0 100% !important;
		width: 100% !important;
		height: 100%;
		position: absolute;
		z-index: 4;
	}

	&.groupSiderBackground {
		background: var(--announcementHeaderBackground) !important;
	}

	&.collapsedSideBar {
		flex: 0 0 0px !important;

		@include extra-small {
			flex: 0 0 0 !important;
			width: 0px !important;
		}
	}

	&.absoluteSideBar {
		width: 85px !important;
		max-width: 85px !important;
		height: 100%;
		z-index: 4 !important;
		flex: 0 0 85px;
		min-width: 85px !important;

		@include sm {
			width: 100% !important;
			position: absolute;
			max-width: 100% !important;
			flex: 0 0 100% !important;
			min-width: 0px !important;
		}

		:global(.ant-menu.ant-menu-dark.ant-menu-inline-collapsed.styles_menuContainer__2PUH9.ant-menu-root.ant-menu-vertical) {
			li {
				padding: 0 30px !important;
			}
		}

		:global(.ant-menu-inline-collapsed) {
			width: unset !important;
		}
	}

	.imageWrapper {
		padding: 16px 15px 0px 15px;

		.imageContainer {
			width: 55px;
			height: 55px;
			background: #f3f3f3;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 8px;
		}
	}

	.groupInfoContainer {
		padding: 16px 15px 0px 15px;

		.infoContainer {
			background: #ffffff;
			padding: 9px 19px 11px 12px;
			display: flex;
			flex-direction: row;
			height: 55px;
			min-width: 270px;
			border-radius: 8px;
			gap: 10px;

			.detailsContaienr {
				display: flex;
				flex-direction: column;

				.title {
					color: #000;
					font-family: "Nunito-Medium";
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 18px;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					max-width: 200px;
				}

				.membersDetails {
					display: flex;
					gap: 9px;

					.groupStatusOpen {
						display: flex;
						gap: 5px;
						align-items: center;

						svg {
							height: 15px;
							width: 15px;

							path {
								stroke: var(--primaryColor);
							}
						}

						span {
							color: #f8408c;
							font-family: "Nunito-Regular";
							font-size: 12px;
							font-style: normal;
							font-weight: 400;
							line-height: 20px;
						}
					}

					.groupStatusClosed {
						display: flex;
						gap: 5px;
						align-items: center;

						svg {
							height: 15px;
							width: 15px;

							path {
								stroke: var(--primaryColor);
							}
						}

						span {
							color: #f8408c;
							font-family: "Nunito-Regular";
							font-size: 12px;
							font-style: normal;
							font-weight: 400;
						}
					}

					.memberNumberContainer {
						display: flex;
						gap: 5px;
						align-items: center;

						svg {
							height: 20px;
							width: 20px;

							circle {
								stroke: var(--primaryColor);
							}

							path {
								stroke: var(--primaryColor);
							}
						}

						.groupMembersNumber {
							color: var(--primaryColor);
							font-family: "Nunito-Regular";
							font-size: 12px;
							font-style: normal;
							font-weight: 400;
							line-height: 16px;
						}
					}
				}
			}
		}
	}

	.siderCustomMenuContainer {
		display: flex;
		flex-direction: column;
		flex: 1;
		font-family: "Avenir Light", sans-serif;
		overflow: auto;

		@include extra-small {
			padding-top: 99px;
		}
	}

	.groupSiderCustomMenuBackground {
		padding-top: 66px;

		@include extra-small {
			padding-top: 35px !important;
		}
	}
}

.activeUserLayout {
	align-items: center;
	position: relative;
	padding: 32px;

	@include extra-small {
		padding: 20px;
		overflow-x: hidden !important;
	}
}

// ! GLOBAL STYLE FOR APP MODALS.
:global {
	#btn-cancel-delete-account {
		border: unset;
		color: red !important;
		font-family: Nunito-Bold;
		font-size: 13px;
		height: 40px;
		box-shadow: unset;
	}

	#btn-ok-leave-group {
		background-color: var(--headerBackground) !important;
		border: unset;
		border-radius: 5px;
		color: white;
		height: 40px;
		font-family: Nunito-Bold;
		font-size: 13px;
		padding: 0 20px;
		transition-duration: 0.1s;

		&:hover {
			background-color: rgba(var(--headerBackgroundRgb), 0.9) !important;
			transition-timing-function: cubic-bezier(0, 0, 1, 1);
		}
	}

	.ant-modal-content {
		height: 100% !important;
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
		flex-direction: column !important;
		width: 95% !important;
		margin: auto;
		border-radius: 8px !important;
		padding: 10px 0 !important;
	}

	.ant-modal-header {
		width: 100%;

		> div {
			font-family: Avenir-Heavy;
			font-size: 18px;

			@include sm {
				font-size: 16px;
			}
		}

		border-bottom-color: lightgray;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	.ant-modal-footer {
		width: 100%;
		padding-bottom: 0;
	}

	.modalContainer {
		width: 600px !important;
		height: auto;

		@include sm {
			width: 400px !important;
		}

		@include extra-small {
			width: 375px !important;
		}
	}

	.closeModal {
		cursor: pointer;
		position: absolute;
		right: 18px;
		top: 20px;
		background-color: var(--headerBackground);
		border-radius: 50px;
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			background-color: rgba(var(--headerBackgroundRgb), 0.9) !important;
			transition-timing-function: cubic-bezier(0, 0, 1, 1);
		}
	}
}

// ! GLOBAL STYLE FOR APP MODALS.
