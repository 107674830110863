@import "assets/scss/index.scss";
@import "../styles.module.scss";

.container {
	margin-bottom: 24px;

	.rowFlexStart {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		p {
			font-family: 'Nunito-Regular';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			margin: 0px;
			color: #292F45;
		}

		.greySmallBox {
			background: rgba(0, 0, 0, 0.6);
			color: #ffffff;
			border: 1px solid #333333;
			box-sizing: border-box;
			border-radius: 4px;
			height: 24px;
			margin-left: 12px;
			padding: 0px 8px;
			font-weight: 400;
			font-size: 14px;
			text-transform: uppercase;
			line-height: 24px;
		}
	}

	.smallText {
		margin-bottom: 8px;

		&.errorText {
			color: red;
			font-size: 12px;
		}

		&.greyText {
			color: grey;
			font-size: 12px;
		}
	}

	:global(.ant-tag-processing) {
		color: var(--selectedCommunityToJoinPrimaryColor,
				var(--primaryColor)) !important;
		background: rgba(var(--selectedCommunityToJoinPrimaryColorRgb,
					var(--primaryColorRgb)),
				$alpha: 0.3);
	}

}