@import "../../assets/scss/mixins.scss";

.AppBar {
	width: 100%;
	background-color: #fff;
	padding: 20px 30px 20px 24px;
	display: flex;
	align-items: center;
	gap: 15px;
	position: relative;
	z-index: 2;
	justify-content: space-between;
	width: 100%;
	// box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
	min-height: 80px;
	gap: 15px;

	@include extra-small {
		padding: 25px 20px;
	}

	.menuUnfoldIconContainer {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		svg {
			fill: var(--primaryColor);

			path {
				stroke: var(--primaryColor);
			}
		}
	}

	.appContainer {
		display: flex;
		justify-content: space-between;
		width: 100%;

		@include extra-small {
			justify-content: end;
			gap: 22px;
		}

		.selectAndInputContainer {
			display: flex;
			flex-direction: row;
			position: relative;
			flex: 1;
			max-width: 800px;
			height: 40px;

			@include extra-small {
				flex: auto;
				align-items: center;
				max-width: 80%;
			}
		}

		.leftSide {
			display: flex;
			position: relative;

			> div.groupsSwitch {
				cursor: pointer;
				z-index: 2;
				background: #fff;
				border: 2px solid transparent;
				padding: 2px 20px;
				border-radius: 24px;
				outline: none;
				height: 32px;
				display: flex;
				overflow: hidden;
				flex-direction: column;
				-webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
				box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
				// -webkit-transition: all 1.3s ease-out;
				// -moz-transition: all 1.3s ease-out;
				// -o-transition: all 1.3s ease-out;
				// transition: all 1.3s ease-out;

				&.expanded {
					border-bottom-left-radius: 0px;
					border-bottom-right-radius: 0px;
				}

				ul {
					position: absolute;
					background-color: #ffffff;
					margin: 0 -22px;
					padding: 0;
					list-style-type: none;
					top: 32px;
					width: 100%;
					border-bottom-left-radius: 24px;
					border-bottom-right-radius: 24px;
					box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);

					li {
						padding: 0 22px;
						font-weight: 300;
						font-size: 24px;
						margin: 4px 0px;

						&:hover {
							font-weight: 700;
							background-color: rgba(0, 0, 0, 0.1);
						}
					}

					:last-child {
						overflow: hidden;
						border-bottom-left-radius: 24px;
						border-bottom-right-radius: 24px;
						margin-bottom: 0px;
					}
				}

				.anchor {
					display: flex;
					display: -webkit-box;
					flex-direction: row;
					font-size: 14px;
					font-weight: 100;
					justify-content: space-between;

					> div:first-child {
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
					}

					svg {
						width: 24px;
						margin-left: 10px;
					}

					> div {
						outline: none;
					}

					&.accents {
						font-family: "Avenir Heavy", sans-serif;
					}
				}
			}

			.backdrop {
				position: fixed;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: transparent;
				z-index: 2;
			}
		}

		.message {
			margin-bottom: 0;
			color: #fff;
			text-align: center;
			font-family: "Nunito-Medium";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px;

			@include extra-small {
				display: none;
			}
		}

		.rightSide {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-top: 2px;

			@include extra-small {
				margin: 0px;
			}

			.messagesBtn {
				position: relative;
				background: var(--primaryColor);
				border-radius: 8px;
				height: 40px;
				line-height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				padding: 10px 24px;
				display: flex;
				align-items: center;
				font-size: 14px;
				font-weight: 700;
				gap: 4px;

				@include extra-small {
					height: 40px;
					min-width: 40px;
					max-width: 40px;
					padding: 0px;
				}

				&:active {
					background-color: var(--secondaryColor);
				}
			}

			.notificationIcon {
				margin-left: 24px;
				position: relative;
			}

			sup {
				width: 24px;
				height: 24px;
				//   line-height: 20px;
				text-align: center;
				background-color: var(--primaryColor);
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				top: -12px;
				left: -12px;
				position: absolute;
				border-radius: 100%;
				color: #fff;

				font-size: 12px;
				font-weight: 900;
				border: 2px solid #ffffff;
			}

			.userIcon {
				margin-left: 17px;
				display: flex;
				align-items: center;
				gap: 10px;

				@include extra-small {
					margin-left: 16px;
				}

				p {
					color: #000;
					font-family: "Nunito-Regular";
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 16px;

					@include extra-small {
						display: none;
					}
				}

				@include extra-small {
					svg {
						display: none;
					}
				}
			}

			font-family: "Nunito-Medium";

			font-weight: 500;
		}
	}
}

.notificationsMenu {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 4px 12px;

	> div:first-child {
		margin-right: 4px;
		color: #191919;
	}
}

.btnDanger {
	border-top: 1px solid #cdcdcd;
	color: red !important;
}

.modalContent {
	padding: 20px;
	font-family: "Avenir Light", sans-serif;
}

:global(.ant-modal-title) {
	display: flex;
	flex-direction: row;
	justify-content: center;
	font-family: "Avenir Light", sans-serif;
}

:global(#btn-cancel-delete-account.ant-btn) {
	&:hover,
	&:focus {
		color: white !important;
		border-color: var(--primaryColor) !important;
	}
}

.modalContainer {
	:global(.ant-modal-header) {
		border-top-left-radius: 8px !important;
		border-top-right-radius: 8px !important;
	}

	:global(.ant-modal-title) {
		font-weight: bold;
	}

	:global(.ant-modal-content) {
		border-radius: 8px !important;
	}

	:global(.ant-modal-footer) {
		padding-right: 26px;
	}

	:global(.ant-btn) {
		border-radius: 6px;
	}
}

.modalContent {
	padding: 20px;
	font-family: "Avenir Light", sans-serif;
	text-align: center;
}

.userName {
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100px;
	white-space: nowrap;
}
